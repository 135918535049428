import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type I94EArrivalDepartureNumberSchemaType = Partial<I131FormValues> & {
  applicantInfo: {
    i94Number: string;
  };
};

const I94EArrivalDepartureNumberSchema = createPartialSchema<I94EArrivalDepartureNumberSchemaType>({
  applicantInfo: Yup.object({
    i94Number: Yup.string().default(''),
  }),
});

export default I94EArrivalDepartureNumberSchema;
