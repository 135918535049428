import { checkDate } from '@src/utils/pureFunctions';
import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type TheirDateOfBirthSchemaType = Partial<I131FormValues> & {
  infoAboutThem: {
    dateOfBirth: string;
  };
};

const TheirDateOfBirthSchema = createPartialSchema<TheirDateOfBirthSchemaType>({
  infoAboutThem: Yup.object({
    dateOfBirth: Yup.string()
      .default('')
      .test('validateDate', 'Invalid date format', (value: string) => checkDate(value, false)),
  }),
});

export default TheirDateOfBirthSchema;
