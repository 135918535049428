import { themeConstants } from '../constants/themeConstants';
import { styleConstants } from '../constants/styleConstants';

export const footerStyles = {
  container: {
    width: '100%',
  } as const,

  returnToTop: {
    bgcolor: themeConstants.colors.grey[100],
    py: 2,
  } as const,

  returnToTopLink: {
    color: themeConstants.colors.blue[600],
    '&:hover': {
      textDecoration: 'none',
    },
  } as const,

  middleSection: {
    bgcolor: themeConstants.colors.grey[200],
    py: 3,
  } as const,

  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  } as const,

  socialContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: { xs: 'center', md: 'flex-end' },
    mt: { xs: 2, md: 0 },
  } as const,

  socialIconsWrapper: {
    mb: 2,
    '& .MuiIconButton-root': {
      color: themeConstants.colors.blue[600],
    },
  } as const,

  contactLink: {
    color: themeConstants.colors.blue[600],
    fontSize: '0.875rem',
    '&:hover': {
      textDecoration: 'none',
    },
  } as const,

  bottomSection: {
    bgcolor: themeConstants.colors.blue[600],
    color: themeConstants.colors.white,
    py: 4,
  } as const,

  linksGrid: {
    justifyContent: { md: 'flex-start' },
    maxWidth: { md: '60%' },
  } as const,

  footerLink: {
    display: 'block',
    color: 'inherit',
    textDecoration: 'underline',
    fontSize: themeConstants.typography.body.fontSize,
    lineHeight: 1.4,
    mb: 1,
    '&:hover': {
      textDecoration: 'none',
    },
  } as const,

  copyrightContainer: {
    mt: 4,
    pt: 3,
    borderTop: 1,
    borderColor: 'rgba(255, 255, 255, 0.1)',
  } as const,

  copyright: {
    fontSize: '0.75rem',
    textAlign: 'center',
  } as const,

  // Logo specific styles
  logo: {
    width: {
      xs: styleConstants.layout.header.logo.mobile.width,
      md: styleConstants.layout.header.logo.desktop.width,
    },
    height: {
      xs: styleConstants.layout.header.logo.mobile.height,
      md: styleConstants.layout.header.logo.desktop.height,
    },
    mr: 2,
  } as const,

  logoButton: {
    p: 0,
    minWidth: 'auto',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  } as const,

  logoImage: {
    width: {
      xs: 150, // mobile size
      md: 208, // desktop size
    },
    height: {
      xs: 44, // mobile size
      md: 61, // desktop size
    },
  } as const,

  logoWrapper: {
    width: {
      xs: styleConstants.layout.header.logo.mobile.width,
      md: styleConstants.layout.header.logo.desktop.width,
    },
    height: {
      xs: styleConstants.layout.header.logo.mobile.height,
      md: styleConstants.layout.header.logo.desktop.height,
    },
    mr: 2,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  } as const,
} as const;
