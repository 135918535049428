import { useEffect, useState } from 'react';
import axios from 'axios';
import { prefixUrl } from './draftUrl.constant';
import { BaseFormValues, FormConfig } from 'src/types/form.types';

export default function useLoadDraft<T, FValues extends BaseFormValues>(
  formType: string,
  formConfig: FormConfig<FValues> | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  idRef: any
): T {
  const [result, setResult] = useState<T>({} as T);

  useEffect(() => {
    if (!idRef.current) {
      axios
        .get(`${prefixUrl}/draft-service/drafts/${formType}/current?status=editable`, {
          headers: {},
          withCredentials: true,
        })
        .then((response) => {
          setResult(response.data);
        })
        .catch((e) => {
          console.log('Failed to retrieve draft: ', e.errors);
        });
    }
    // don't need idRef to be in here as it is global ref
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formType]);

  const dataEntry = Object.entries(result as object).find((i) => i[0] === 'formData');
  const formData = dataEntry ? dataEntry[1] : {};
  return { ...result, formData: formConfig?.j2f(formData) } as T;
}
