import * as React from 'react';
import ContactInformation from '../../SharedSubSections/ContactInformation/ContactInformation';

const PreparerContactInformation: React.FC = () => {
  return (
    <ContactInformation
      question="Preparer's Contact Information"
      daytimePhoneLabel="Preparer's Daytime Telephone Number"
      mobilePhoneLabel="Preparer's Mobile Telephone Number (if any)"
      emailLabel="Preparer's Email Address (if any)"
      daytimePhonePath="preparerContactAndCertification.daytimePhone"
      mobilePhonePath="preparerContactAndCertification.mobilePhone"
      emailPath="preparerContactAndCertification.email"
    />
  );
};

export default PreparerContactInformation;
