import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type AdvancedParoleTripsSchemaType = Partial<I131FormValues> & {
  proposedTravel: {
    numberOfTrips: string;
    expectedLength: string;
  };
};

const AdvancedParoleTripsSchema = createPartialSchema<AdvancedParoleTripsSchemaType>({
  proposedTravel: Yup.object({
    numberOfTrips: Yup.string().default('').oneOf(['', 'oneTrip', 'moreThanOneTrip']),
    expectedLength: Yup.string().default(''),
  }),
});

export default AdvancedParoleTripsSchema;
