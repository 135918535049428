import { ReactElement } from 'react';
import { Field, FieldProps } from 'formik';
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Box,
  useTheme,
  Theme,
} from '@mui/material';
import { radioStyles } from '../../styles';

interface CheckboxOption {
  value: string;
  label: string;
}

interface BaseCheckboxProps {
  name: string;
  question: string;
  instructions?: string;
  options: CheckboxOption[];
  required?: boolean;
}

interface CheckboxItemProps {
  option: CheckboxOption;
  checked: boolean;
  onChange: () => void;
  theme: Theme;
}

const CheckboxItem = ({ option, checked, onChange, theme }: Readonly<CheckboxItemProps>): ReactElement => (
  <FormControlLabel
    key={option.value}
    control={
      <Checkbox
        checked={checked}
        onChange={onChange}
        sx={{
          '&.MuiCheckbox-root': {
            color: '#757575',
            padding: {
              xs: '4px',
              sm: '6px',
              md: '8px',
            },
          },
          '&.Mui-checked': {
            color: 'primary.main',
          },
          '& .MuiSvgIcon-root': {
            fontSize: {
              xs: 20,
              sm: 24,
              md: 28,
              lg: 32,
            },
          },
        }}
      />
    }
    label={option.label}
    sx={{
      marginLeft: 0,
      marginY: 0.5,
      width: '100%',
      '& .MuiFormControlLabel-label': {
        fontSize: {
          xs: theme.typography.h5.fontSize,
          sm: theme.typography.h4.fontSize,
          md: theme.typography.h4.fontSize,
        },
        color: theme.palette.text.primary,
        lineHeight: {
          xs: 1.2,
          sm: 1.3,
          md: 1.4,
        },
      },
    }}
  />
);

const FormContent = ({
  field,
  meta,
  form,
  name,
  options,
  question,
  instructions,
  required,
}: Readonly<FieldProps & BaseCheckboxProps>): ReactElement => {
  const theme = useTheme();
  const errorId = `${name}-error`;
  const hasError = meta.touched && !!meta.error;

  const handleChange = (optionValue: string): void => {
    const currentValue = field.value || [];
    const newValue = currentValue.includes(optionValue)
      ? currentValue.filter((item: string) => item !== optionValue)
      : [...currentValue, optionValue];
    form.setFieldValue(name, newValue);
  };

  return (
    <FormControl
      component="fieldset"
      error={hasError}
      sx={radioStyles.formControl}
      aria-describedby={hasError ? errorId : undefined}
    >
      <Box sx={radioStyles.questionContainer}>
        <FormLabel
          component="legend"
          required={required}
          sx={radioStyles.getQuestionLabelStyles(theme)}
        >
          {question}
        </FormLabel>

        {instructions && (
          <FormLabel
            component="legend"
            sx={radioStyles.getInstructionsLabelStyles(theme)}
          >
            {instructions}
          </FormLabel>
        )}
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          px: {
            xs: 2,
            sm: 2,
          },
        }}
      >
        <FormGroup sx={{ width: '100%' }}>
          {options.map((option) => (
            <CheckboxItem
              key={option.value}
              option={option}
              checked={field.value?.includes(option.value)}
              onChange={(): void => handleChange(option.value)}
              theme={theme}
            />
          ))}
        </FormGroup>
      </Box>

      {hasError && (
        <FormHelperText
          id={errorId}
          error
          sx={radioStyles.getErrorStyles(theme)}
        >
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

function BaseCheckboxGroup(props: Readonly<BaseCheckboxProps>): ReactElement {
  return (
    <Field name={props.name}>
      {(fieldProps: FieldProps): ReactElement => (
        <FormContent
          {...fieldProps}
          {...props}
        />
      )}
    </Field>
  );
}

export default BaseCheckboxGroup;
