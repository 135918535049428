import { ReactElement } from 'react';
import { useFormikContext } from 'formik';
import { Box } from '@mui/material';
import { RadioOption } from '@src/types/pages.types';
import CustomRadio from '@components/CustomRadio';
import RequiredFieldsMessage from '@components/RequiredFieldsMessage/RequiredFieldsMessage';
import { I131Schema } from '@src/utils/I131.schema';
import { TextFieldWithQuestion } from '@src/components';

export function OutsideInitialParoleInfo(): ReactElement {
  const { values, setFieldValue } = useFormikContext<I131Schema>();

  const outsideUsParoleOptions: RadioOption[] = [
    { value: 'A', label: 'Filipino World War II Veterans Parole (FWVP) Program, Form I-130 receipt number:' },
    { value: 'B', label: 'Immigrant Military Members and Veterans Initiative (IMMVI)' },
    { value: 'C', label: 'Intergovernmental Parole Referral' },
    { value: 'D', label: 'Family Reunification Task Force (FRTF) Process;' },
    { value: 'E', label: 'Other: (List specific parole program or process)' },
  ];

  const serviceMemberOptions: RadioOption[] = [
    { value: '1', label: 'A current or former service member' },
    {
      value: '2',
      label:
        'A current spouse, child, or unmarried son or daughter (or their child under 21 years of age) of a current or former service member',
    },
    { value: '3', label: 'Current legal guardian or surrogate of a current or former service member' },
  ];

  const clearOutsideUsFields = (program: string): void => {
    switch (program) {
      case 'A':
      case 'D':
        setFieldValue('outsideUsInitParoleDocument.forPerson', '');
        setFieldValue('outsideUsInitParoleDocument.govExecBranch', '');
        setFieldValue('outsideUsInitParoleDocument.repEmailAddress', '');
        setFieldValue('outsideUsInitParoleDocument.otherProgram', '');
        break;
      case 'B':
        setFieldValue('outsideUsInitParoleDocument.receiptNumber', '');
        setFieldValue('outsideUsInitParoleDocument.govExecBranch', '');
        setFieldValue('outsideUsInitParoleDocument.repEmailAddress', '');
        setFieldValue('outsideUsInitParoleDocument.otherProgram', '');
        break;
      case 'C':
        setFieldValue('outsideUsInitParoleDocument.receiptNumber', '');
        setFieldValue('outsideUsInitParoleDocument.forPerson', '');
        setFieldValue('outsideUsInitParoleDocument.otherProgram', '');
        break;
      case 'E':
        setFieldValue('outsideUsInitParoleDocument.receiptNumber', '');
        setFieldValue('outsideUsInitParoleDocument.forPerson', '');
        setFieldValue('outsideUsInitParoleDocument.govExecBranch', '');
        setFieldValue('outsideUsInitParoleDocument.repEmailAddress', '');
        break;
    }
  };

  const handleProgramChange = (value: string): void => {
    clearOutsideUsFields(value);
  };

  return (
    <Box sx={{ py: 4 }}>
      <RequiredFieldsMessage />
      <Box sx={{ width: '100%', maxWidth: '800px' }}>
        <CustomRadio
          fieldName="outsideUsInitParoleDocument.program"
          question="Initial Parole Document"
          instructions="I am applying for a parole document under INA section 212(d)(5)(A) on my own behalf and I am outside the United States, or I am applying on behalf of someone else who is outside the United States, for the first time (initial application) under one of the following specific parole programs or processes:"
          options={outsideUsParoleOptions}
          direction="column"
          required
          onChange={handleProgramChange}
        />

        {['A', 'D'].includes(values.outsideUsInitParoleDocument?.program) && (
          <TextFieldWithQuestion
            name={'outsideUsInitParoleDocument.receiptNumber'}
            question={
              values.outsideUsInitParoleDocument.program === 'A'
                ? 'What is the Form I-130 receipt number?'
                : 'What is the task force registration number?'
            }
          />
        )}

        {values.outsideUsInitParoleDocument?.program === 'B' && (
          <Box sx={{ mt: 2 }}>
            <CustomRadio
              fieldName="outsideUsInitParoleDocument.forPerson"
              question="Select one of the following:"
              options={serviceMemberOptions}
              direction="column"
              required
            />
          </Box>
        )}

        {values.outsideUsInitParoleDocument?.program === 'C' && (
          <Box>
            <TextFieldWithQuestion
              name={'outsideUsInitParoleDocument.govExecBranch'}
              question={'What is the U.S. Federal Executive Branch Government Agency?'}
            />
            <TextFieldWithQuestion
              name={'outsideUsInitParoleDocument.repEmailAddress'}
              question={"What is the U.S. Federal Government Agency Representative's official email address?"}
            />
          </Box>
        )}

        {values.outsideUsInitParoleDocument?.program === 'E' && (
          <TextFieldWithQuestion
            name={'outsideUsInitParoleDocument.otherProgram'}
            question="Provide an explanation for your answer."
          />
        )}
      </Box>
    </Box>
  );
}

export default OutsideInitialParoleInfo;
