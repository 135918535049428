import { ReactElement } from 'react';
import BooleanRadioWithExplanationBox from '@components/BooleanRadioWithExplanationBox/BooleanRadioWithExplanationBox';

export const RECEIVE_BENEFITS = {
  RECEIVE_BENEFITS: {
    fieldKey: 'refugeeTravelDocumentInfo.receiveBenefits',
    question:
      'Since you were accorded refugee/asylee status, have you ever applied for and/or received any benefit from such country?',
  },
  RECEIVE_BENEFITS_EXPLANATION: {
    fieldKey: 'refugeeTravelDocumentInfo.receiveBenefitsExplanation',
    summaryKey: 'Explanation: ',
  },
} as const;

const ReceiveBenefitsQuestion = (): ReactElement => {
  return (
    <BooleanRadioWithExplanationBox
      question={RECEIVE_BENEFITS.RECEIVE_BENEFITS.question}
      fieldName={RECEIVE_BENEFITS.RECEIVE_BENEFITS.fieldKey}
    />
  );
};

export default ReceiveBenefitsQuestion;
