import { useEffect, useState } from 'react';
import axios from 'axios';
import { prefixUrl } from './draftUrl.constant';
import { BaseFormValues, FormConfig } from '../../types/form.types';

export default function useCreateDraft<T, FValues extends BaseFormValues>(
  formData: T | null,
  formConfig: FormConfig<FValues> | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  idRef: any
): number {
  const [result, setResult] = useState<number>(0);

  useEffect(() => {
    if (!idRef.current && !!formConfig?.formId) {
      const jsonData = formConfig.f2j(formData);
      axios
        .post(
          `${prefixUrl}/draft-service/drafts`,
          { formData: jsonData, formType: formConfig.formId },
          { headers: {}, withCredentials: true }
        )
        .then((response) => {
          setResult(response.data.id);
        })
        .catch((e) => {
          console.log('Failed to create draft: ', e.errors);
        });
    }
    // don't need idRef to be in here as it is global ref
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formConfig, formData, setResult]);
  return result;
}
