import { booleanReqSchema } from '@src/utils/I131.schema';
import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type ApplyingForSomeoneElseSchemaType = Partial<I131FormValues> & {
  applicantInfo: {
    applyingForSomeoneElse: string;
  };
};

const ApplyingForSomeoneElseSchema = createPartialSchema<ApplyingForSomeoneElseSchemaType>({
  applicantInfo: Yup.object({
    applyingForSomeoneElse: booleanReqSchema,
  }),
});

export default ApplyingForSomeoneElseSchema;
