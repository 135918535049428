const { REACT_APP_OAUTH_CLIENT_ID, REACT_APP_OAUTH_LOGIN_URL } = process.env;

export const KEYCLOAK_CONFIG = {
  clientId: REACT_APP_OAUTH_CLIENT_ID,
  url: REACT_APP_OAUTH_LOGIN_URL,
  realm: 'master',
};

export const OIDC_CONFIG = {
  authority: `${KEYCLOAK_CONFIG.url}/realms/${KEYCLOAK_CONFIG.realm}`,
  client_id: KEYCLOAK_CONFIG.clientId,
  redirect_uri: window.location.origin,
  post_logout_redirect_uri: window.location.origin,
  onSigninCallback: (): void => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
} as const;

export default OIDC_CONFIG;
