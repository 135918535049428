import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import { BooleanSchema, booleanSchema } from '@src/utils/I131.schema';
import * as Yup from 'yup';

type PlanToTravelBackSchemaType = Partial<I131FormValues> & {
  refugeeTravelDocumentInfo: {
    planToTravelBack: BooleanSchema;
    planToTravelBackExplanation: string;
  };
};

const planToTravelBackSchema = createPartialSchema<PlanToTravelBackSchemaType>({
  refugeeTravelDocumentInfo: Yup.object({
    planToTravelBack: booleanSchema,
    planToTravelBackExplanation: Yup.string().default(''),
  }),
});

export default planToTravelBackSchema;
