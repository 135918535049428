import { ReactElement } from 'react';
import FullName from '../../SharedSubSections/FullName/FullName';

export const THEIR_NAME = {
  THEIR_NAME: {
    fieldKey: 'infoAboutThem.fullName',
    question: 'What is their current full legal name?',
  },
  GIVEN_NAME: {
    fieldKey: 'infoAboutThem.fullName.givenName',
    summaryKey: 'Given name (First Name)',
  },
  MIDDLE_NAME: {
    fieldKey: 'infoAboutThem.fullName.middleName',
    summaryKey: 'Middle name (if applicable)',
  },
  FAMILY_NAME: {
    fieldKey: 'infoAboutThem.fullName.familyName',
    summaryKey: 'Family name (Last Name)',
  },
};

const TheirName = (): ReactElement => {
  return (
    <FullName
      question={THEIR_NAME.THEIR_NAME.question}
      givenNamePath={THEIR_NAME.GIVEN_NAME.fieldKey}
      middleNamePath={THEIR_NAME.MIDDLE_NAME.fieldKey}
      familyNamePath={THEIR_NAME.FAMILY_NAME.fieldKey}
    />
  );
};

export default TheirName;
