import React from 'react';
import { Box, Container, Typography, Link, Grid, IconButton, Button } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import uscisInsignia from '@src/assets/USCIS_Insignia_wm_fc.png';
import { footerStyles } from '../../styles/components/footerStyles';
import { useNavigate } from 'react-router-dom';

interface FooterLink {
  text: string;
  url: string;
}

const footerColumns: FooterLink[][] = [
  [
    { text: 'About USCIS', url: 'https://www.uscis.gov/about-us' },
    {
      text: 'Accessibility',
      url: 'https://www.uscis.gov/website-policies/accessibility',
    },
    {
      text: 'Budget and Performance',
      url: 'https://www.uscis.gov/about-us/budget-planning-and-performance',
    },
    {
      text: 'DHS Components',
      url: 'https://www.uscis.gov/website-policies/dhs-component-websites',
    },
  ],
  [
    {
      text: 'Freedom of Information Act',
      url: 'https://www.uscis.gov/records/request-records-through-the-freedom-of-information-act-or-privacy-act',
    },
    {
      text: 'No FEAR Act Data',
      url: 'https://www.uscis.gov/about-us/equal-employment-opportunity/equal-employment-opportunity-data-posted-pursuant-to-the-no-fear-act',
    },
    {
      text: 'Privacy and Legal Disclaimers',
      url: 'https://www.uscis.gov/website-policies/privacy-and-legal-disclaimers',
    },
    { text: 'Site Map', url: 'https://www.uscis.gov/sitemap' },
  ],
  [
    {
      text: 'Office of the Inspector General',
      url: 'https://www.oig.dhs.gov/',
    },
    { text: 'The White House', url: 'https://www.whitehouse.gov/' },
    { text: 'USA.gov', url: 'https://www.usa.gov/' },
  ],
];

const socialLinks = [
  {
    icon: FacebookIcon,
    label: 'Facebook',
    url: 'https://www.facebook.com/uscis',
  },
  { icon: XIcon, label: 'Twitter', url: 'https://x.com/uscis' },
  {
    icon: YouTubeIcon,
    label: 'YouTube',
    url: 'https://www.youtube.com/uscis',
  },
  {
    icon: InstagramIcon,
    label: 'Instagram',
    url: 'https://www.instagram.com/uscis/',
  },
  {
    icon: LinkedInIcon,
    label: 'LinkedIn',
    url: 'https://www.linkedin.com/company/uscis',
  },
  {
    icon: MailOutlineIcon,
    label: 'Subscriber',
    url: 'https://public.govdelivery.com/accounts/USDHSCIS/subscriber/new',
  },
];

const Footer: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigateHome = (): void => {
    navigate('/');
  };

  const scrollToTop = (): void => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Box
      component="footer"
      sx={footerStyles.container}
      role="contentinfo"
    >
      {/* Row 1: Return to top */}
      <Box
        sx={footerStyles.returnToTop}
        component="section"
        aria-label="Return to top section"
      >
        <Container maxWidth="lg">
          <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Link
              component="button"
              onClick={scrollToTop}
              sx={footerStyles.returnToTopLink}
              aria-label="Return to top of page"
              role="button"
              onKeyDown={(e): void => {
                if (e.key === 'Enter' || e.key === ' ') {
                  scrollToTop();
                }
              }}
            >
              Return to top
            </Link>
          </Box>
        </Container>
      </Box>

      {/* Logo and Social Media section */}
      <Box
        sx={footerStyles.middleSection}
        component="section"
        aria-label="Organization information and social media links"
      >
        <Container maxWidth="lg">
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid
              item
              xs={12}
              md={6}
            >
              <Box sx={footerStyles.logoContainer}>
                <Button
                  onClick={handleNavigateHome}
                  sx={footerStyles.logoButton}
                  aria-label="Return to home page"
                >
                  <Box
                    component="img"
                    src={uscisInsignia}
                    alt="USCIS Insignia"
                    sx={footerStyles.logoImage}
                  />
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              <Box sx={footerStyles.socialContainer}>
                <Box
                  sx={footerStyles.socialIconsWrapper}
                  role="navigation"
                  aria-label="Social media links"
                >
                  {socialLinks.map(({ icon: Icon, label, url }) => (
                    <IconButton
                      key={label}
                      aria-label={`Visit USCIS on ${label}`}
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon />
                    </IconButton>
                  ))}
                </Box>
                <Link
                  href="https://www.uscis.gov/about-us/contact-us"
                  sx={footerStyles.contactLink}
                  aria-label="Contact USCIS"
                >
                  Contact Us
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Footer links section */}
      <Box
        sx={footerStyles.bottomSection}
        component="section"
        aria-label="Footer links and copyright"
      >
        <Container maxWidth="lg">
          <Grid
            container
            spacing={3}
            sx={footerStyles.linksGrid}
            role="navigation"
            aria-label="Footer links"
          >
            {footerColumns.map((column, columnIndex) => (
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                key={columnIndex}
              >
                {column.map((link) => (
                  <Link
                    key={link.text}
                    href={link.url}
                    sx={footerStyles.footerLink}
                    underline="always"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={`${link.text} - Opens in a new window`}
                  >
                    {link.text}
                  </Link>
                ))}
              </Grid>
            ))}
          </Grid>
          <Box sx={footerStyles.copyrightContainer}>
            <Typography
              sx={footerStyles.copyright}
              role="contentinfo"
            >
              © {new Date().getFullYear()} U.S. Citizenship and Immigration Services
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
