const baseColors = {
  white: '#FFFFFF',
  black: '#000000',
  transparent: 'transparent',
} as const;

export const themeConstants = {
  colors: {
    ...baseColors,
    blue: {
      100: '#E6F5FD',
      200: '#E0F1FF',
      300: '#A6D7FF',
      400: '#E6F5FD',
      500: '#005C8A', // Adjusted for better contrast on white
      600: '#004D75', // Darkened for better contrast
      700: '#003366',
    },
    gold: {
      100: '#FFF9E5',
      500: '#945C00', // Darkened for better contrast on light backgrounds
    },
    green: {
      100: '#DEFADE',
      500: '#005500', // Darkened for better contrast
    },
    grey: {
      100: '#F0F0F0', // Corrected banner background
      200: '#EBEBEB',
      300: '#CCCCCC',
      400: '#595959', // Darkened for better contrast
      500: '#333333', // Darkened for better text contrast
    },
    red: {
      100: '#FFEDED',
      500: '#B22222', // Adjusted for better contrast
    },

    common: {
      white: baseColors.white,
      black: baseColors.black,
    },

    // Updated semantic colors
    error: {
      main: '#B22222', // Adjusted red
      light: '#FFEDED',
    },
    success: {
      main: '#005500', // Adjusted green
      light: '#DEFADE',
    },
    warning: {
      main: '#945C00', // Adjusted gold
      light: '#FFF9E5',
    },
  },

  semantic: {
    text: {
      primary: '#1A1A1A',
      secondary: '#595959',
      disabled: '#767676',
      link: '#004D75',
      inverse: '#FFFFFF',
    },
    background: {
      primary: '#FFFFFF',
      secondary: '#F0F0F0',
      tertiary: '#EBEBEB',
    },
    interactive: {
      focus: '#004D75',
      hover: '#003D5C',
      disabled: '#767676',
    },
  },

  typography: {
    h1: {
      fontSize: '2.5rem', // 40px
      lineHeight: '3.75rem', // 60px
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.75rem', // 28px
      lineHeight: '2.625rem', // 42px
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.5rem', // 24px
      lineHeight: '2.25rem', // 36px
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.25rem', // 20px
      lineHeight: '1.875rem', // 30px
      fontWeight: 600,
    },
    h5: {
      fontSize: '1rem', // 16px
      lineHeight: '1.5rem', // 24px
      fontWeight: 600,
    },
    body1: {
      fontSize: '1rem', // 16px
      lineHeight: '1.5rem', // 24px
      fontWeight: 400,
      color: '#1A1A1A', // Adding this for contrast
    },
    body: {
      fontSize: '1rem', // 16px
      lineHeight: '1.5rem', // 24px
      fontWeight: 400,
    },
    placeholder: {
      fontSize: '1rem', // 16px
      lineHeight: '1.5rem', // 24px
      fontWeight: 400,
    },
    caption: {
      fontSize: '0.875rem', // 14px
      lineHeight: '1.313rem', // 21px
      fontWeight: 400,
    },
    verticalNav: {
      fontSize: '1rem', // 16px
      lineHeight: '1.5rem', // 24px
      fontWeight: 500,
    },
    secondaryBody: {
      fontSize: '1rem', // 16px
      lineHeight: '1.313rem', // 21px
      fontWeight: 400,
    },
  },

  effects: {
    shadows: {
      primary: '[GET_FROM_FIGMA]',
      onAction: '[GET_FROM_FIGMA]',
    },
  },
};

export type ThemeConstants = typeof themeConstants;
export const { colors, semantic, typography, effects } = themeConstants;

export default themeConstants;
