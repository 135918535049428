import * as Yup from 'yup';

import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import addressSchema, {
  type addressSchemaType,
} from '@src/containers/FormSubSections/SharedSubSections/Address/Address.schema';

type MailingAddressSchemaType = Partial<I131FormValues> & {
  applicantInfo: {
    currentMailingAddress: addressSchemaType;
  };
};

const MailingAddressSchema = createPartialSchema<MailingAddressSchemaType>({
  applicantInfo: Yup.object({
    currentMailingAddress: addressSchema,
  }),
});

export default MailingAddressSchema;
