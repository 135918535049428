import * as React from 'react';
import { COUNTRY } from '@src/constants/countryConstants';
import FlexibleDropdown from '@src/components/FlexibleDropdown';

export const THEIR_COUNTRY_OF_BIRTH = {
  BIRTH_COUNTRY: {
    fieldKey: 'infoAboutThem.countryOfBirth',
    question: 'What is their country of birth?',
  },
};

const TheirCountryOfBirth: React.FC = () => {
  return (
    <FlexibleDropdown
      leftName={THEIR_COUNTRY_OF_BIRTH.BIRTH_COUNTRY.fieldKey}
      question={THEIR_COUNTRY_OF_BIRTH.BIRTH_COUNTRY.question}
      instructions="Provide the name of the country of their birth. Use the current names of the country of their birth."
      leftOptions={COUNTRY}
    />
  );
};

export default TheirCountryOfBirth;
