import { validateANumberOrSsn } from '@src/utils/pureFunctions';
import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type AnumberSchemaType = Partial<I131FormValues> & {
  applicantInfo: {
    alienRegistrationNumber: string;
  };
};

const AnumberSchema = createPartialSchema<AnumberSchemaType>({
  applicantInfo: Yup.object({
    alienRegistrationNumber: Yup.string().default('').test(validateANumberOrSsn),
  }),
});

export default AnumberSchema;
