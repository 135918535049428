import * as Yup from 'yup';
import { booleanSchema } from '@src/utils/I131.schema';
import { createPartialSchema, I131FormValues } from '@src/types/form.types';

type HasInterpreterSchemaType = Partial<I131FormValues> & {
  interpreterContactAndCertification: {
    hasInterpreter: string;
  };
};

const HasInterpreterSchema = createPartialSchema<HasInterpreterSchemaType>({
  interpreterContactAndCertification: Yup.object({
    hasInterpreter: booleanSchema,
  }),
});

export default HasInterpreterSchema;
