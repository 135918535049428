import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type EMedicalIDSchemaType = Partial<I131FormValues> & {
  applicantInfo: {
    eMedicalUSParoleeID: string;
  };
};

const EMedicalIDSchema = createPartialSchema<EMedicalIDSchemaType>({
  applicantInfo: Yup.object({
    eMedicalUSParoleeID: Yup.string().default(''),
  }),
});

export default EMedicalIDSchema;
