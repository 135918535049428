import * as React from 'react';
import { TextFieldWithQuestion } from '@components';

const SocialSecurityNumber: React.FC = () => {
  return (
    <TextFieldWithQuestion
      question="What is your U.S. Social Security number (SSN)? (if any)"
      helperText="Provide a 9-digit number Social Security number."
      name={'applicantInfo.socialSecurityNumber'}
    />
  );
};

export default SocialSecurityNumber;
