import * as Yup from 'yup';

import { booleanSchema } from '@src/utils/I131.schema';
import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import addressSchema, {
  type addressSchemaType,
} from '@src/containers/FormSubSections/SharedSubSections/Address/Address.schema';

type TheirPhysicalAddressSchemaType = Partial<I131FormValues> & {
  infoAboutThem: {
    currentPhysicalAddress: addressSchemaType;
    isPhysicalSameAsMailing: string;
  };
};

const TheirPhysicalAddressSchema = createPartialSchema<TheirPhysicalAddressSchemaType>({
  infoAboutThem: Yup.object({
    currentPhysicalAddress: addressSchema,
    isPhysicalSameAsMailing: booleanSchema,
  }),
});

export default TheirPhysicalAddressSchema;
