import * as React from 'react';
import { CustomRadio } from '@components';
import { RadioOption } from '@src/types/pages.types';

const genderOptions: RadioOption[] = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'anotherGenderIdentity', label: 'Another gender identity' },
];

const Gender: React.FC = () => {
  return (
    <CustomRadio
      fieldName="applicantInfo.gender"
      question="What is your gender?"
      options={genderOptions}
      required
    />
  );
};

export default Gender;
