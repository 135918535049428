import { useMemo } from 'react';
import { FormikProps } from 'formik';
import { NavigationCheck, PreviousCheck, SubSection, FormConfig, BaseFormValues } from '@src/types/form.types';
import { FormNavigationResult } from '@src/types/hooks.types';

export const useFormNavigation = <T extends BaseFormValues>(
  selectedSection: string,
  selectedSubSection: string,
  formik: FormikProps<T>,
  checkNextSubSection: (section: string, subSection: string, values: T) => NavigationCheck,
  checkPreviousSubSection: (section: string, subSection: string, values: T) => PreviousCheck,
  createVisibleSubSectionsMap: (values: T) => Record<string, SubSection<T>[]>,
  handleNext: (formik: FormikProps<T>) => Promise<void>,
  handleBack: (formik: FormikProps<T>) => Promise<void>,
  formConfig: FormConfig<T>
): FormNavigationResult<T> => {
  const { values, setFieldValue } = formik;

  const visibleSubSectionsMap: Record<string, SubSection<T>[]> = useMemo(() => {
    return createVisibleSubSectionsMap(values);
  }, [createVisibleSubSectionsMap, values]);

  const { nextCheck } = useMemo(() => {
    if (!formConfig) {
      return {
        nextCheck: { hasNext: false },
        hasNextSection: false,
        hasNextInCurrentSection: false,
      };
    }

    const currentSectionIndex = formConfig.sections.findIndex((s) => s.id === selectedSection);
    const currentSectionSubSections = visibleSubSectionsMap[selectedSection] || [];
    const currentSubSectionId = selectedSubSection.replace(`${selectedSection}-`, '');
    const currentSubSectionIndex = currentSectionSubSections.findIndex((ss) => ss.id === currentSubSectionId);

    // Check for next subsection in current section
    const hasNextInCurrentSection = currentSubSectionIndex < currentSectionSubSections.length - 1;

    // Check for next visible section with visible subsections
    const nextVisibleSection = formConfig.sections.slice(currentSectionIndex + 1).find((section) => {
      const isSectionHidden = typeof section.hidden === 'function' ? section.hidden(values) : !!section.hidden;
      return !isSectionHidden && visibleSubSectionsMap[section.id]?.length > 0;
    });

    const hasNextSection = Boolean(nextVisibleSection);
    const hasNext = hasNextInCurrentSection || hasNextSection;

    // FOR DEBUGGING PURPOSES. LEAVE IN.
    // console.log('Navigation check:', {
    //   currentSection: selectedSection,
    //   currentSectionIndex,
    //   nextSectionId: nextVisibleSection?.id,
    //   currentSubSection: currentSubSectionId,
    //   currentSubSectionIndex,
    //   hasNextInCurrentSection,
    //   hasNextSection,
    //   hasNext,
    //   totalSubSections: currentSectionSubSections.length,
    // });

    return {
      nextCheck: {
        hasNext,
        nextSection: !hasNextInCurrentSection && hasNextSection ? nextVisibleSection?.id : undefined,
      },
      hasNextSection,
      hasNextInCurrentSection,
    };
  }, [selectedSection, selectedSubSection, visibleSubSectionsMap, formConfig, values]);

  const { isLastSection, isLastSubSection } = useMemo(() => {
    if (!formConfig) {
      return {
        isLastSection: false,
        isLastSubSection: false,
      };
    }

    const currentSectionIndex = formConfig.sections.findIndex((s) => s.id === selectedSection);
    const currentSectionSubSections = visibleSubSectionsMap[selectedSection] || [];
    const currentSubSectionId = selectedSubSection.replace(`${selectedSection}-`, '');
    const currentSubSectionIndex = currentSectionSubSections.findIndex((ss) => ss.id === currentSubSectionId);

    // Find next visible section with visible subsections
    const nextVisibleSection = formConfig.sections.slice(currentSectionIndex + 1).find((section) => {
      const isSectionHidden = typeof section.hidden === 'function' ? section.hidden(values) : !!section.hidden;
      return !isSectionHidden && visibleSubSectionsMap[section.id]?.length > 0;
    });

    // You're on the last section if there are no visible sections after this one
    const isLastVisibleSection = !nextVisibleSection;

    // You're on the last subsection if you're on the last visible subsection
    // and there are no more visible sections
    const isLastVisibleSubSection = currentSubSectionIndex === currentSectionSubSections.length - 1;

    return {
      isLastSection: isLastVisibleSection,
      isLastSubSection: isLastVisibleSubSection && isLastVisibleSection,
    };
  }, [selectedSection, selectedSubSection, visibleSubSectionsMap, formConfig, values]);

  const previousCheck = useMemo((): PreviousCheck => {
    if (!formConfig) return { hasPrevious: false };

    const currentSectionIndex = formConfig.sections.findIndex((s) => s.id === selectedSection);
    const currentSectionSubSections = visibleSubSectionsMap[selectedSection] || [];
    const currentSubSectionId = selectedSubSection.replace(`${selectedSection}-`, '');
    const currentSubSectionIndex = currentSectionSubSections.findIndex((ss) => ss.id === currentSubSectionId);

    // Check if there's a previous subsection in current section
    const hasPreviousInCurrentSection = currentSubSectionIndex > 0;

    // Check if there's a previous section with visible subsections
    const hasPreviousSection =
      currentSectionIndex > 0 &&
      formConfig.sections
        .slice(0, currentSectionIndex)
        .reverse()
        .some((prevSection) => {
          const prevSectionSubSections = visibleSubSectionsMap[prevSection.id] || [];
          return prevSectionSubSections.length > 0;
        });

    return {
      hasPrevious: hasPreviousInCurrentSection || hasPreviousSection,
      previousSection:
        !hasPreviousInCurrentSection && hasPreviousSection
          ? formConfig.sections[currentSectionIndex - 1].id
          : undefined,
    };
  }, [selectedSection, selectedSubSection, visibleSubSectionsMap, formConfig]);

  return {
    nextCheck,
    previousCheck,
    visibleSubSectionsMap,
    isLastSection,
    isLastSubSection,
    handleNextClick: async (): Promise<void> => {
      if (!nextCheck.hasNext) return;
      return handleNext(formik);
    },
    handleBackClick: async (): Promise<void> => {
      if (!previousCheck.hasPrevious) return;
      if (previousCheck.shouldUpdateApplicationType && previousCheck.newApplicationType) {
        await setFieldValue('applicationType', previousCheck.newApplicationType);
      }
      return handleBack(formik);
    },
  };
};
