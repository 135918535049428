import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type InterpreterNameSchemaType = Partial<I131FormValues> & {
  interpreterContactAndCertification: {
    fullName: {
      familyName: string;
      givenName: string;
    };
    businessName: string;
  };
};

const InterpreterNameSchema = createPartialSchema<InterpreterNameSchemaType>({
  interpreterContactAndCertification: Yup.object({
    fullName: Yup.object({
      familyName: Yup.string().default(''),
      givenName: Yup.string().default(''),
    }),
    businessName: Yup.string().default(''),
  }),
});

export default InterpreterNameSchema;
