import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type ReentryPermitInformationSchemaType = Partial<I131FormValues> & {
  totalTimeOutsideUs: string;
};

const ReentryPermitInformationSchema = createPartialSchema<ReentryPermitInformationSchemaType>({
  totalTimeOutsideUs: Yup.string()
    .default('')
    .oneOf([
      '',
      'lessThan6Months',
      'sixMonthsToOneYear',
      'oneToTwoYears',
      'twoToThreeYears',
      'threeToFourYears',
      'moreThanFourYears',
    ]),
});

export default ReentryPermitInformationSchema;
