import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import { emailValidation } from '@src/utils/pureFunctions';
import * as Yup from 'yup';

type ApplicantContactInformationSchemaType = Partial<I131FormValues> & {
  applicantContactAndCertification: {
    daytimePhone: string;
    mobilePhone: string;
    email: string;
  };
};

const ApplicantContactInformationSchema = createPartialSchema<ApplicantContactInformationSchemaType>({
  applicantContactAndCertification: Yup.object({
    daytimePhone: Yup.string()
      .default('')
      .test(
        'phoneNumberValidation',
        'Invalid phone format',
        (value) => !value || value.replace(/[- ()]/g, '').match(/^\d{10}$/) !== null
      ),
    mobilePhone: Yup.string()
      .default('')
      .test(
        'phoneNumberValidation',
        'Invalid phone format',
        (value) => !value || value.replace(/[- ()]/g, '').match(/^\d{10}$/) !== null
      ),
    email: Yup.string()
      .default('')
      .test('emailValidation', 'Invalid email', (value) => !value || emailValidation(value)),
  }),
});

export default ApplicantContactInformationSchema;
