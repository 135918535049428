import { ReactElement } from 'react';
import BooleanRadioWithExplanationBox from '@components/BooleanRadioWithExplanationBox/BooleanRadioWithExplanationBox';

export const PLAN_TO_TRAVEL_BACK = {
  PLAN_TO_TRAVEL_BACK: {
    fieldKey: 'refugeeTravelDocumentInfo.planToTravelBack',
    question: 'Do you plan to travel to the country you are a refugee or asylee from?',
  },
  PLAN_TO_TRAVEL_BACK_EXPLANATION: {
    fieldKey: 'refugeeTravelDocumentInfo.planToTravelBackExplanation',
    summaryKey: 'Explanation: ',
  },
} as const;

const PlanToTravelBack = (): ReactElement => {
  return (
    <BooleanRadioWithExplanationBox
      question={PLAN_TO_TRAVEL_BACK.PLAN_TO_TRAVEL_BACK.question}
      fieldName={PLAN_TO_TRAVEL_BACK.PLAN_TO_TRAVEL_BACK.fieldKey}
    />
  );
};

export default PlanToTravelBack;
