import * as React from 'react';
import { COUNTRY } from '@src/constants/countryConstants';
import FlexibleDropdown from '@src/components/FlexibleDropdown';

export const COUNTRY_OF_BIRTH = {
  BIRTH_COUNTRY: {
    fieldKey: 'applicantInfo.countryOfBirth',
    question: 'What is your country of birth?',
  },
};

const CountryOfBirth: React.FC = () => {
  return (
    <FlexibleDropdown
      leftName={COUNTRY_OF_BIRTH.BIRTH_COUNTRY.fieldKey}
      question={COUNTRY_OF_BIRTH.BIRTH_COUNTRY.question}
      instructions="Provide the name of the country of your birth. Use the current names of the country of your birth."
      leftOptions={COUNTRY}
    />
  );
};

export default CountryOfBirth;
