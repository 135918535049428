import { ReactElement } from 'react';
import { useFormikContext } from 'formik';
import { Box } from '@mui/material';
import CustomRadio from '@components/CustomRadio';
import { RadioOption } from '@src/types/pages.types';
import RequiredFieldsMessage from '@components/RequiredFieldsMessage/RequiredFieldsMessage';
import { TextFieldWithQuestion } from '@src/components';
import { I131Schema } from '@src/utils/I131.schema';

function InUsAdvanceParoleInfo(): ReactElement {
  const { values, setFieldValue } = useFormikContext<I131Schema>();

  const advanceParoleOptions: RadioOption[] = [
    { value: 'A', label: 'A pending Form I-485, Application to Register Permanent Residence or Adjust Status' },
    { value: 'B', label: 'A pending Form I-589, Application for Asylum and for Withholding of Removal' },
    { value: 'C', label: 'A pending initial Form I-821, Application for Temporary Protected Status' },
    { value: 'D', label: 'Deferred Enforced Departure' },
    { value: 'E', label: 'Approved Form I-821D, Consideration of Deferred Action for Childhood Arrivals' },
    {
      value: 'F',
      label:
        'An approved Form I-914, Application for T Nonimmigrant Status, or Form I-914, Supplement A, Application for Family Member of T-1 Recipient',
    },
    {
      value: 'G',
      label:
        'An approved Form I-918, Petition for U Nonimmigrant Status, or Form I-918, Supplement A, Petition for Qualifying Family Member of U-1 Recipient',
    },
    { value: 'H', label: 'Being a current parolee under INA section 212(d)(5), under class of admission' },
    { value: 'I', label: 'An approved Form I-817, Application for Family Unity Benefits' },
    {
      value: 'J',
      label:
        'A pending Form I-687, Application for Status as a Temporary Resident Under Section 245A of the Immigration and Nationality Act',
    },
    { value: 'K', label: 'An approved V Nonimmigrant Status' },
    { value: 'L', label: 'CNMI long-term residence' },
    { value: 'M', label: 'Other (provide explanation)' },
  ];

  const handleProgramChange = (): void => {
    setFieldValue('inUsAdvanceParoleDocument.receiptNumber', '');
  };

  let receiptNumberQuestionCopy = 'What is the receipt number?';

  if (values.inUsAdvanceParoleDocument?.program === 'H') {
    receiptNumberQuestionCopy = 'What is the class of admission?';
  } else if (values.inUsAdvanceParoleDocument?.program === 'M') {
    receiptNumberQuestionCopy = 'Please provide an explanation';
  }

  return (
    <Box sx={{ py: 4 }}>
      <RequiredFieldsMessage />
      <Box sx={{ mt: 2, ml: 4, mb: 4 }}>
        <CustomRadio
          fieldName="inUsAdvanceParoleDocument.program"
          question="Advance Parole Document"
          instructions="I am located inside the United States, and I am applying for an Advance Parole Document to allow me to seek parole into the United States under INA section 212(d)(5)(A) upon my return from abroad based on:"
          options={advanceParoleOptions}
          direction="column"
          required
          onChange={handleProgramChange}
        />

        {values.inUsAdvanceParoleDocument?.program && values.inUsAdvanceParoleDocument?.program !== 'D' && (
          <TextFieldWithQuestion
            name="inUsAdvanceParoleDocument.receiptNumber"
            question={receiptNumberQuestionCopy}
            required
          />
        )}
      </Box>
    </Box>
  );
}

export default InUsAdvanceParoleInfo;
