import * as React from 'react';
import { CustomRadio } from '@components';

const ApplyingForSomeoneElse: React.FC = (): JSX.Element => {
  return (
    <CustomRadio
      question="Are you applying on behalf of someone else?"
      fieldName="applicantInfo.applyingForSomeoneElse"
    />
  );
};

export default ApplyingForSomeoneElse;
