import * as Yup from 'yup';

import { validatePhoneNumber } from '@src/utils/pureFunctions';
import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import addressSchema, {
  type addressSchemaType,
} from '@src/containers/FormSubSections/SharedSubSections/Address/Address.schema';

type TheirMailingAddressSchemaType = Partial<I131FormValues> & {
  infoAboutThem: {
    currentMailingAddress: addressSchemaType;
    daytimePhone: string;
  };
};

const TheirMailingAddressSchema = createPartialSchema<TheirMailingAddressSchemaType>({
  infoAboutThem: Yup.object({
    currentMailingAddress: addressSchema,
    daytimePhone: Yup.string().default('').test('phoneNumberValidation', 'Invalid phone format', validatePhoneNumber),
  }),
});

export default TheirMailingAddressSchema;
