import * as React from 'react';
import { Field, FieldProps } from 'formik';
import { TextField, Typography, Box } from '@mui/material';
import { ReactElement } from 'react';

interface ContactInformationProps {
  question?: string;
  daytimePhoneLabel?: string;
  mobilePhoneLabel?: string;
  emailLabel?: string;
  daytimePhonePath: string;
  mobilePhonePath: string;
  emailPath: string;
}

const ContactInformation: React.FC<ContactInformationProps> = ({
  question = '',
  daytimePhoneLabel = '',
  mobilePhoneLabel = '',
  emailLabel = '',
  daytimePhonePath,
  mobilePhonePath,
  emailPath,
}) => {
  return (
    <Box sx={{ maxWidth: '800px', mx: 'auto', p: 3 }}>
      {question && (
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
        >
          {question}
        </Typography>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <div className="input-field-container">
          <label
            htmlFor="daytime-phone"
            className="input-label"
          >
            <Typography variant="body1">{daytimePhoneLabel}</Typography>
          </label>
          <Field name={daytimePhonePath}>
            {({ field, meta }: FieldProps): ReactElement => (
              <TextField
                id="daytime-phone"
                {...field}
                fullWidth
                error={meta.touched && Boolean(meta.error)}
                helperText={meta.touched && meta.error}
                variant="outlined"
              />
            )}
          </Field>
        </div>

        <div className="input-field-container">
          <label
            htmlFor="mobile-phone"
            className="input-label"
          >
            <Typography variant="body1">{mobilePhoneLabel}</Typography>
          </label>
          <Field name={mobilePhonePath}>
            {({ field, meta }: FieldProps): ReactElement => (
              <TextField
                {...field}
                id="mobile-phone"
                fullWidth
                error={meta.touched && Boolean(meta.error)}
                helperText={meta.touched && meta.error}
                variant="outlined"
              />
            )}
          </Field>
        </div>

        <div className="input-field-container">
          <label
            htmlFor="email"
            className="input-label"
          >
            <Typography variant="body1">{emailLabel}</Typography>
          </label>
          <Field name={emailPath}>
            {({ field, meta }: FieldProps): ReactElement => (
              <TextField
                {...field}
                id="email"
                fullWidth
                error={meta.touched && Boolean(meta.error)}
                helperText={meta.touched && meta.error}
                variant="outlined"
              />
            )}
          </Field>
        </div>
      </Box>
    </Box>
  );
};

export default ContactInformation;
