// src/styles/components/formStyles.ts
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { styleConstants } from '../constants/styleConstants';

export const FormContentWrapper = styled(Box)(() => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: styleConstants.layout.form.section.gap,
  maxWidth: styleConstants.layout.form.maxWidth,
  margin: '0 auto',
  width: '100%',
}));

export const FormWrapper = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: styleConstants.layout.form.section.gap,
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  marginTop: 'auto',
  padding: styleConstants.components.card.padding,
  display: 'flex',
  justifyContent: 'space-between',
  borderTop: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
}));
