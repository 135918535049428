import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type PurposeOfTripSchemaType = Partial<I131FormValues> & {
  proposedTravel: {
    purpose: string;
  };
};

const PurposeOfTripSchema = createPartialSchema<PurposeOfTripSchemaType>({
  proposedTravel: Yup.object({
    purpose: Yup.string().default(''),
  }),
});

export default PurposeOfTripSchema;
