interface String {
  /**
   * Transforms strings to title case via regex.
   *
   * @example Simple
   * ```ts
   * const str = 'thisIsAString';
   * console.log(str.toDisplay()); // This Is A String
   * ```
   * @example With acronyms
   * ```ts
   * const str = 'someIPAddress';
   * console.log(str.toDisplay()); // Some IP Address
   * ```
   */
  toDisplay(): string;
}

String.prototype.toDisplay = function () {
  return this.replace(/(_)+/g, ' ')
    .replace(/([a-z])([A-Z][a-z])/g, '$1 $2')
    .replace(/([A-Z][a-z])([A-Z])/g, '$1 $2')
    .replace(/([a-z])([A-Z]+[a-z])/g, '$1 $2')
    .replace(/([A-Z]+)([A-Z][a-z][a-z])/g, '$1 $2')
    .replace(/([a-z]+)([A-Z0-9]+)/g, '$1 $2')
    .replace(/([A-Z]+)([A-Z][a-rt-z][a-z]*)/g, '$1 $2')
    .replace(/(\d)([A-Z][a-z]+)/g, '$1 $2')
    .replace(/([A-Z]{2,})([0-9]{2,})/g, '$1 $2')
    .replace(/(\d{2,})([A-Z]{2,})/g, '$1 $2')
    .replace(/^./, (str) => str.toUpperCase())
    .trim();
};
