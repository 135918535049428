import { themeConstants } from '../constants/themeConstants';

export const styleConstants = {
  layout: {
    header: {
      height: '64px',
      mobileHeight: '56px',
      banner: {
        height: '32px',
        padding: '0 16px',
      },
      logo: {
        desktop: {
          width: 208,
          height: 61,
        },
        mobile: {
          width: 150,
          height: 44,
        },
      },
    },

    footer: {
      background: themeConstants.colors.grey[200],
      bottomBackground: themeConstants.colors.blue[500],
      padding: {
        top: '24px',
        bottom: '40px',
      },
    },

    form: {
      maxWidth: '800px',
      padding: {
        desktop: '24px',
        mobile: '16px',
      },
      section: {
        gap: '24px',
        padding: '24px',
      },
    },
    gutter: {
      top: 2,
      bottom: 2,
    },
  },

  components: {
    // Form-specific components
    formField: {
      marginBottom: '24px',
      error: {
        color: themeConstants.colors.error.main,
      },
    },

    // Navigation
    tabs: {
      minHeight: '48px',
      indicator: {
        height: '3px',
      },
    },

    // Cards
    card: {
      borderRadius: '8px',
      padding: '24px',
      shadow: themeConstants.effects.shadows.primary,
    },

    // Buttons
    button: {
      borderRadius: '4px',
      padding: {
        vertical: '8px',
        horizontal: '16px',
      },
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
};
