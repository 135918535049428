import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import { BooleanSchema, booleanSchema } from '@src/utils/I131.schema';
import * as Yup from 'yup';

type ReturnToCountrySchemaType = Partial<I131FormValues> & {
  refugeeTravelDocumentInfo: {
    returnToCountry: BooleanSchema;
    returnToCountryExplanation: string;
  };
};

const returnToCountrySchema = createPartialSchema<ReturnToCountrySchemaType>({
  biographicInfo: Yup.object({
    returnToCountry: booleanSchema,
    returnToCountryExplanation: Yup.string().default(''),
  }),
});

export default returnToCountrySchema;
