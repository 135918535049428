import { useCallback } from 'react';

const useScrollToTop = (): (() => void) => {
  const scrollToTop = useCallback(() => {
    // Ensure we scroll the entire document to the very top
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    // As a fallback, also ensure the documentElement and body are scrolled
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    document.body.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return scrollToTop;
};

export default useScrollToTop;
