import { fullNameSchema } from '@src/utils/I131.schema';
import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type OtherNamesSchemaType = Partial<I131FormValues> & {
  applicantInfo: {
    otherNames: {
      givenName: string;
      middleName: string;
      familyName: string;
    }[];
  };
};

const otherNamesSchema = createPartialSchema<OtherNamesSchemaType>({
  applicantInfo: Yup.object({
    otherNames: Yup.array().of(fullNameSchema).default([]).max(3),
  }),
});

export default otherNamesSchema;
