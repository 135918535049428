import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import { emailValidation } from '@src/utils/pureFunctions';
import * as Yup from 'yup';

type PreparerContactInformationSchemaType = Partial<I131FormValues> & {
  preparerContactAndCertification: {
    daytimePhone: string;
    mobilePhone: string;
    email: string;
  };
};

const PreparerContactInformationSchema = createPartialSchema<PreparerContactInformationSchemaType>({
  preparerContactAndCertification: Yup.object({
    daytimePhone: Yup.string()
      .default('')
      .test(
        'phoneNumberValidation',
        'Invalid phone format',
        (value) => !value || /^\d{10}$/.exec(value.replace(/[-() ]/g, '')) !== null
      ),
    mobilePhone: Yup.string()
      .default('')
      .test(
        'phoneNumberValidation',
        'Invalid phone format',
        (value) => !value || /^\d{10}$/.exec(value.replace(/[-() ]/g, '')) !== null
      ),
    email: Yup.string()
      .default('')
      .test('emailValidation', 'Invalid email', (value) => !value || emailValidation(value)),
  }),
});

export default PreparerContactInformationSchema;
