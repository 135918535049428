import * as Yup from 'yup';

import { booleanSchema } from '@src/utils/I131.schema';
import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import addressSchema, {
  type addressSchemaType,
} from '@src/containers/FormSubSections/SharedSubSections/Address/Address.schema';

type PhysicalAddressSchemaType = Partial<I131FormValues> & {
  applicantInfo: {
    currentPhysicalAddress: addressSchemaType;
    isPhysicalSameAsMailing: string;
  };
};

const PhysicalAddressSchema = createPartialSchema<PhysicalAddressSchemaType>({
  applicantInfo: Yup.object({
    currentPhysicalAddress: addressSchema,
    isPhysicalSameAsMailing: booleanSchema,
  }),
});

export default PhysicalAddressSchema;
