import React, { ReactElement } from 'react';
import { useField, FieldInputProps, FieldMetaProps } from 'formik';
import { Typography, FormHelperText, Select, MenuItem, FormControl, Box, Grid } from '@mui/material';
import QuestionWithInstruction from '../QuestionWithInstruction/QuestionWithInstruction';

export type DropdownOption = {
  value: string | number;
  label: string;
};

export type FlexibleDropdownProps = {
  question?: string;
  instructions?: string;
  leftName: string;
  rightName?: string; // Pass this prop if you want a 2nd dropdown to the right of the first one under the same question, Look at height page for example
  leftLabel?: string;
  rightLabel?: string;
  leftOptions: DropdownOption[];
  rightOptions?: DropdownOption[];
  required?: boolean;
  disabled?: boolean;
  testId?: string;
};

export const FlexibleDropdown: React.FC<FlexibleDropdownProps> = ({
  question,
  instructions,
  leftName,
  rightName,
  leftLabel,
  rightLabel,
  leftOptions,
  rightOptions = [],
  required = false,
  disabled = false,
  testId = 'flexible-dropdown',
}): ReactElement => {
  // Always call useField for leftName
  const [leftField, leftMeta] = useField(leftName);

  // Always call useField for rightName (if it exists) or a dummy field
  const [rightField, rightMeta] = useField(rightName ?? 'dummyField');

  const renderDropdown = (
    field: FieldInputProps<string>,
    meta: FieldMetaProps<string>,
    label?: string,
    options: DropdownOption[] = [],
    side: 'left' | 'right' = 'left'
  ): ReactElement => (
    <FormControl
      fullWidth
      error={meta.touched && !!meta.error}
      data-testid={`${testId}-${side}-dropdown`}
    >
      {label && (
        <Typography
          sx={{
            mb: 1,
            color: 'text.secondary',
            fontWeight: 'bold',
            fontSize: {
              xs: 'body2.fontSize',
              sm: 'body1.fontSize',
              md: 'h6.fontSize',
            },
          }}
          data-testid={`${testId}-${side}-label`}
        >
          {label}
        </Typography>
      )}
      <Select
        {...field}
        value={field.value ?? ''}
        disabled={disabled}
        data-testid={`${testId}-${side}-select`}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            data-testid={`${testId}-${side}-option-${option.value}`}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {meta.touched && meta.error && (
        <FormHelperText
          error
          data-testid={`${testId}-${side}-error`}
        >
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );

  return (
    <Box data-testid={testId}>
      {question && (
        <QuestionWithInstruction
          question={question}
          instructions={instructions}
          required={required}
          data-testid={`${testId}-question`}
        />
      )}

      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={rightName ? 6 : 12}
        >
          {renderDropdown(leftField, leftMeta, leftLabel, leftOptions, 'left')}
        </Grid>

        {rightName && (
          <Grid
            item
            xs={6}
          >
            {renderDropdown(rightField, rightMeta, rightLabel, rightOptions, 'right')}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default FlexibleDropdown;
