import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type CountryOriginSelectSchemaType = Partial<I131FormValues> & {
  refugeeTravelDocumentInfo: {
    originCountry: string;
  };
};

const countryOriginSelectSchema = createPartialSchema<CountryOriginSelectSchemaType>({
  refugeeTravelDocumentInfo: Yup.object({
    originCountry: Yup.string().default(''),
  }),
});

export default countryOriginSelectSchema;
