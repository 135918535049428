import { ReactElement } from 'react';
import { BooleanRadioWithExplanationBox } from '@components';

export const ACQUIRED_NEW_NATIONALITY = {
  ACQUIRED_NEW_NATIONALITY: {
    fieldKey: 'refugeeTravelDocumentInfo.acquiredNewNationality',
    question:
      'Since you were accorded refugee/asylee status, have you, by any legal procedure or voluntary act acquired a new nationality?',
  },
  ACQUIRED_NEW_NATIONALITY_EXPLANATION: {
    fieldKey: 'refugeeTravelDocumentInfo.acquiredNewNationalityExplanation',
    summaryKey: 'Explanation: ',
  },
} as const;

const AcquiredNewNationality = (): ReactElement => {
  return (
    <BooleanRadioWithExplanationBox
      question={ACQUIRED_NEW_NATIONALITY.ACQUIRED_NEW_NATIONALITY.question}
      fieldName={ACQUIRED_NEW_NATIONALITY.ACQUIRED_NEW_NATIONALITY.fieldKey}
    />
  );
};

export default AcquiredNewNationality;
