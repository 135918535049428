import React from 'react';
import { Box } from '@mui/material';
import { CustomRadio, RequiredFieldsMessage } from '@components';

function RefugeeQuestion(): React.ReactElement {
  return (
    <Box sx={{ py: 4 }}>
      <RequiredFieldsMessage />
      <Box sx={{ py: 6 }}>
        <CustomRadio
          fieldName="refugeeOrLprRefugee"
          question="Do you hold status as a refugee, were you paroled as a refugee, or are you a lawful permanent resident as a direct result of being a refugee?"
          required
          direction="row"
        />
      </Box>
    </Box>
  );
}

export default RefugeeQuestion;
