import { ReactElement } from 'react';
import BooleanRadioWithExplanationBox from '@components/BooleanRadioWithExplanationBox/BooleanRadioWithExplanationBox';

export const GRANTED_ASYLEE_FOR_OTHER_COUNTRY = {
  GRANTED_ASYLEE_FOR_OTHER_COUNTRY: {
    fieldKey: 'refugeeTravelDocumentInfo.grantedAsyleeForOtherCountry',
    question:
      'Since you were accorded refugee/asylee status, have you ever applied for and/or received any benefit from such country?',
  },
  GRANTED_ASYLEE_FOR_OTHER_COUNTRY_EXPLANATION: {
    fieldKey: 'refugeeTravelDocumentInfo.grantedAsyleeForOtherCountryExplanation',
    summaryKey: 'Explanation: ',
  },
} as const;

const GrantedAsyleeForOtherCountry = (): ReactElement => {
  return (
    <BooleanRadioWithExplanationBox
      question={GRANTED_ASYLEE_FOR_OTHER_COUNTRY.GRANTED_ASYLEE_FOR_OTHER_COUNTRY.question}
      fieldName={GRANTED_ASYLEE_FOR_OTHER_COUNTRY.GRANTED_ASYLEE_FOR_OTHER_COUNTRY.fieldKey}
    />
  );
};

export default GrantedAsyleeForOtherCountry;
