import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import { checkDate } from '@src/utils/pureFunctions';
import * as Yup from 'yup';

type DateOfIntendedDepartureSchemaType = Partial<I131FormValues> & {
  proposedTravel: {
    departureDate: string;
  };
};

const DateOfIntendedDepartureSchema = createPartialSchema<DateOfIntendedDepartureSchemaType>({
  proposedTravel: Yup.object({
    departureDate: Yup.string()
      .default('')
      .test('validateDate', 'Invalid date format', (value) => checkDate(value, true, false)),
  }),
});

export default DateOfIntendedDepartureSchema;
