import { BaseCheckboxGroup } from '@src/components';
import * as React from 'react';

const raceOptions = [
  {
    value: 'americanIndianOrAlaskaNative',
    label: 'American Indian or Alaska Native',
  },
  { value: 'asian', label: 'Asian' },
  { value: 'blackOrAfricanAmerican', label: 'Black or African American' },
  {
    value: 'nativeHawaiianOrOtherPacificIslander',
    label: 'Native Hawaiian or Other Pacific Islander',
  },
  { value: 'white', label: 'White' },
];

const Race: React.FC = () => {
  return (
    <BaseCheckboxGroup
      name="biographicInfo.race"
      question="What is the race of the person who will receive the document?"
      instructions="Select all that apply"
      options={raceOptions}
    />
  );
};

export default Race;
