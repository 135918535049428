import React from 'react';
import { useFormikContext } from 'formik';
import { Box } from '@mui/material';
import { CustomRadio, TextFieldWithQuestion } from '@components';
import { I131Schema } from '@src/utils/I131.schema';

const PreviousReentry: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<I131Schema>();

  const handleChange = (value: string): void => {
    switch (value) {
      case 'No':
        setFieldValue('processingInfo.previousReentryPermit.dateIssued', '');
        setFieldValue('processingInfo.previousReentryPermit.disposition', '');
        break;
    }
  };

  return (
    <>
      <CustomRadio
        question="Have you EVER before been issued a Reentry Permit or Refugee Travel Document?"
        fieldName="processingInfo.previousReentryPermit.issued"
        onChange={handleChange}
      />
      {values.processingInfo.previousReentryPermit.issued === 'Yes' && (
        <Box>
          <TextFieldWithQuestion
            name="processingInfo.previousReentryPermit.dateIssued"
            question="Provide the following information for the last document issued to you:"
            label="Date Issued (mm/dd/yyyy)"
          />
          <TextFieldWithQuestion
            name="processingInfo.previousReentryPermit.disposition"
            label="Disposition (attached, lost, stolen, damaged/destroyed, still in my possession, etc.)"
          />
        </Box>
      )}
    </>
  );
};

export default PreviousReentry;
