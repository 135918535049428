import React from 'react';
import { Box } from '@mui/material';
import { RequiredFieldsMessage, TextFieldWithQuestion } from '@components';

function I94Info(): React.ReactElement {
  return (
    <Box sx={{ py: 4 }}>
      <RequiredFieldsMessage />
      <Box sx={{ py: 6 }}>
        <TextFieldWithQuestion
          question="What is the Admit Until Date/Parole shown on Form I-94?"
          name="i94AdmittedDate"
          placeholder="mm/dd/yyyy"
        />
      </Box>
    </Box>
  );
}

export default I94Info;
