import React, { ReactElement } from 'react';
import { Container, Box } from '@mui/material';
import { RadioOption } from '@src/types/pages.types';
import { CustomRadio, RequiredFieldsMessage } from '@components';

const applicationTypeOptions: RadioOption[] = [
  {
    value: '1',
    label:
      'I am a lawful permanent resident or conditional permanent resident of the United States, and I am applying for a reentry permit.',
  },
  {
    value: '2',
    label: 'I now hold refugee or asylee status in the United States, and I am applying for a Refugee Travel Document.',
  },
  {
    value: '3',
    label:
      'I am a lawful permanent resident as a direct result of refugee or asylee status, and I am applying for a Refugee Travel Document.',
  },
  {
    value: '4',
    label:
      'I am a TPS beneficiary in the United States, and I am applying for a TPS Travel Authorization Document under the Immigration and Nationality Act (INA) section 244(f)(3) to allow me to seek admission under TPS upon my return from abroad.',
  },
  {
    value: '5',
    label:
      'I am located inside the United States, and I am applying for an Advance Parole Document to allow me to seek parole into the United States under INA section 212(d)(5)(A) upon my return from abroad.',
  },
  {
    value: '6',
    label:
      'I am applying for a parole document under INA section 212(d)(5)(A) on my own behalf and I am outside the United States, or I am applying on behalf of someone else who is outside the United States, for the first time (initial application) under one of the following specific parole programs or processes.',
  },
  {
    value: '7',
    label:
      'I am applying for a parole document under INA section 212(d)(5)(A) for myself and I am outside the United States, or I am applying for a parole document under INA section 212(d)(5)(A) on behalf of someone else who is outside the United States for the first time (initial application), but not under a specific parole program or process.',
  },
  {
    value: '8',
    label:
      'I am applying for an initial period of parole in place under INA section 212(d)(5)(A) and I am inside the United States, or I am applying for an initial period of parole in place under INA section 212(d)(5)(A) on behalf of someone else who is inside the United States.',
  },
  {
    value: '9',
    label:
      'I am applying for an initial period of parole in place under INA section 212(d)(5)(A) and I am inside the United States, but not under a specific program or process, or I am applying for an initial period of parole in place under INA section 212(d)(5)(A) for someone else who is inside the United States, but not under a specific program or process.',
  },
  {
    value: '10',
    label:
      'I was initially paroled into the United States or granted parole in place under INA section 212(d)(5)(A) under one of the following programs or processes and I am requesting a new period of parole, or I am applying for a new period of parole on behalf of someone else who was initially paroled into the United States under one of the following programs or processes:',
  },
  {
    value: '11',
    label:
      'I was initially paroled into the United States or granted parole in place under INA section 212(d)(5)(A) and I am requesting a new period of parole, but not under a specific program or process, or I am requesting a new period of parole on behalf of someone else who was initially paroled into the United States or granted parole in place, but not under a specific program or process.',
  },
];

function I131ApplicationType(): ReactElement {
  return (
    <Container
      maxWidth="lg"
      sx={{ py: 4 }}
    >
      <RequiredFieldsMessage />
      <Box sx={{ width: '100%', maxWidth: '800px' }}>
        <CustomRadio
          fieldName="applicationType"
          question="What type of application are you applying for?"
          required
          options={applicationTypeOptions}
          direction="column"
        />
      </Box>
    </Container>
  );
}

export default I131ApplicationType;
