import React from 'react';
import { CustomRadio, TextFieldWithQuestion } from '@src/components';
import Address from '../../SharedSubSections/Address/Address';
import { useFormikContext } from 'formik';
import { I131Schema } from '@src/utils/I131.schema';
import { Box } from '@mui/material';

const docSendToOptions = [
  { label: 'To the U.S. address you entered in the “Information About “You” section', value: 'usAddress' },
  {
    label:
      'To a U.S. Embassy, U.S. Consulate, USCIS international field office, or Department of Homeland Security (DHS) office overseas at:',
    value: 'usEmbassy',
  },
];

const noticeSendToOptions = [
  { label: 'To the U.S. address you entered in the “Information About “You” section', value: 'toP2Q3' },
  { label: 'To a different address (please enter here):', value: 'toP4Q9a' },
];

const DocumentSendTo: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<I131Schema>();

  function clearAddress(): void {
    setFieldValue('processingInfo.noticeSendTo.address.inCareOfName', '');
    setFieldValue('processingInfo.noticeSendTo.address.street', '');
    setFieldValue('processingInfo.noticeSendTo.address.unitNumber', '');
    setFieldValue('processingInfo.noticeSendTo.address.cityOrTown', '');
    setFieldValue('processingInfo.noticeSendTo.address.state', '');
    setFieldValue('processingInfo.noticeSendTo.address.zipCode', '');
    setFieldValue('processingInfo.noticeSendTo.address.province', '');
    setFieldValue('processingInfo.noticeSendTo.address.postalCode', '');
    setFieldValue('processingInfo.noticeSendTo.address.country', '');
    setFieldValue('processingInfo.noticeSendTo.daytimePhone', '');
    setFieldValue('processingInfo.noticeSendTo.emailAddress', '');
  }

  const handleToUsAddressOrEmbassyChange = (value: string): void => {
    if (value === 'usAddress') {
      setFieldValue('processingInfo.documentSendTo.embassyCityOrTown', '');
      setFieldValue('processingInfo.documentSendTo.embassyCountry', '');
      setFieldValue('processingInfo.noticeSendTo.selectDestination', '');
      clearAddress();
    }
  };

  const handleSelectDestinationChange = (value: string): void => {
    if (value === 'toP2Q3') {
      clearAddress();
    }
  };
  return (
    <>
      <CustomRadio
        fieldName={'processingInfo.documentSendTo.toUsAddressOrEmbassy'}
        question="Where do you want your Reentry Permit or Refugee Travel Document sent? (Select one)"
        options={docSendToOptions}
        onChange={handleToUsAddressOrEmbassyChange}
      />
      {values.processingInfo.documentSendTo.toUsAddressOrEmbassy === 'usEmbassy' && (
        <Box>
          <TextFieldWithQuestion
            name={'processingInfo.documentSendTo.embassyCityOrTown'}
            label="City or Town"
          />
          <TextFieldWithQuestion
            name={'processingInfo.documentSendTo.embassyCountry'}
            label="Country"
          />
          <CustomRadio
            fieldName={'processingInfo.noticeSendTo.selectDestination'}
            question="Where should the notification to pick up the travel document be sent?"
            options={noticeSendToOptions}
            onChange={handleSelectDestinationChange}
          />
        </Box>
      )}
      {values.processingInfo.noticeSendTo.selectDestination === 'toP4Q9a' && (
        <Box>
          <Address
            inCareOfNamePath={'processingInfo.noticeSendTo.address.inCareOfName'}
            streetPath={'processingInfo.noticeSendTo.address.street'}
            unitNumberPath={'processingInfo.noticeSendTo.address.unitNumber'}
            cityOrTownPath={'processingInfo.noticeSendTo.address.cityOrTown'}
            statePath={'processingInfo.noticeSendTo.address.state'}
            zipCodePath={'processingInfo.noticeSendTo.address.zipCode'}
            provincePath={'processingInfo.noticeSendTo.address.province'}
            postalCodePath={'processingInfo.noticeSendTo.address.postalCode'}
            countryPath={'processingInfo.noticeSendTo.address.country'}
          />
          <TextFieldWithQuestion
            name={'processingInfo.noticeSendTo.daytimePhone'}
            label="Daytime Phone Number"
          />
          <TextFieldWithQuestion
            name={'processingInfo.noticeSendTo.emailAddress'}
            label="Email Address"
          />
        </Box>
      )}
    </>
  );
};

export default DocumentSendTo;
