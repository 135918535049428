import React, { forwardRef, ReactElement } from 'react';
import { FieldInputProps, FieldMetaProps, useField } from 'formik';
import { TextField, FormHelperText, Box, Typography } from '@mui/material';
import QuestionWithInstruction from '../QuestionWithInstruction';
import { NumericFormatProps, NumericFormat } from 'react-number-format';

type InputBoxProps = {
  question?: string;
  instructions?: string;
  label?: string;
  name: string;
  helperText?: string;
  maxCharacters?: number;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  variant?: 'outlined' | 'filled' | 'standard';
  numericValueOnly?: boolean;
  // New props for second field
  hasSecondField?: boolean;
  secondFieldLabel?: string;
  secondFieldName?: string;
  secondFieldPlaceholder?: string;
  secondFieldRequired?: boolean;
  secondFieldNumericOnly?: boolean;
  secondFieldMaxCharacters?: number;
};

interface CustomNumericFormatProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomNumericFormatProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values): void => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        valueIsNumericString
      />
    );
  }
);

const TextFieldWithQuestion: React.FC<InputBoxProps> = ({
  question,
  instructions,
  label,
  name,
  maxCharacters = 40,
  helperText,
  placeholder,
  required = false,
  disabled = false,
  fullWidth = true,
  variant = 'outlined',
  numericValueOnly = false,
  // New props with defaults
  hasSecondField = false,
  secondFieldLabel = '',
  secondFieldName = '',
  secondFieldPlaceholder = '',
  secondFieldRequired = false,
  secondFieldNumericOnly = false,
  secondFieldMaxCharacters = 40,
}): ReactElement => {
  const [field, meta] = useField(name);
  // Always call useField with a default empty string if no secondFieldName is provided
  const [secondField, secondMeta] = useField(secondFieldName || name);

  const renderTextField = (
    fieldProps: FieldInputProps<string>,
    fieldMeta: FieldMetaProps<string>,
    fieldName: string,
    fieldLabel?: string,
    fieldPlaceholder?: string,
    fieldRequired?: boolean,
    isNumericOnly?: boolean,
    fieldMaxCharacters?: number
  ): ReactElement => (
    <Box sx={{ flex: 1 }}>
      {fieldLabel && (
        <Typography
          sx={{
            mb: 1,
            color: 'text.secondary',
            fontWeight: 'bold',
            fontSize: {
              xs: 'body2.fontSize',
              sm: 'body1.fontSize',
              md: 'h6.fontSize',
            },
          }}
        >
          {fieldLabel}
        </Typography>
      )}
      <TextField
        {...fieldProps}
        id={`textInput-${fieldName}`}
        placeholder={fieldPlaceholder}
        required={fieldRequired}
        disabled={disabled}
        variant={variant}
        fullWidth={fullWidth}
        inputProps={{
          maxLength: fieldMaxCharacters,
          'aria-invalid': fieldMeta.touched && Boolean(fieldMeta.error),
          'aria-label': fieldLabel || question,
        }}
        InputProps={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          inputComponent: isNumericOnly ? (NumericFormatCustom as any) : '',
        }}
        error={fieldMeta.touched && Boolean(fieldMeta.error)}
        helperText={fieldMeta.touched && fieldMeta.error}
        sx={{
          '& .MuiOutlinedInput-root': {
            bgcolor: variant === 'filled' ? 'action.hover' : 'background.paper',
          },
          '& .Mui-disabled': {
            bgcolor: 'action.disabledBackground',
          },
        }}
      />
    </Box>
  );

  return (
    <>
      <Box sx={{ maxWidth: '800px', mx: 'auto', marginTop: '32px' }}>
        {question && (
          <QuestionWithInstruction
            question={question}
            instructions={instructions}
            required={required || (hasSecondField && secondFieldRequired)}
          />
        )}
        <Box sx={{ display: 'flex', gap: 3, mb: 3 }}>
          {renderTextField(field, meta, name, label, placeholder, required, numericValueOnly, maxCharacters)}
          {hasSecondField &&
            secondFieldName &&
            renderTextField(
              secondField,
              secondMeta,
              secondFieldName,
              secondFieldLabel,
              secondFieldPlaceholder,
              secondFieldRequired,
              secondFieldNumericOnly,
              secondFieldMaxCharacters
            )}
        </Box>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </Box>
    </>
  );
};

export default TextFieldWithQuestion;
