import * as Yup from 'yup';
import { createPartialSchema, I131FormValues } from '@src/types/form.types';

// Self/Other Renew Parole Schema Type
export type SelfOtherRenewParoleSchemaType = Partial<I131FormValues> & {
  selfOrOtherRenewParole: {
    program: string;
    forPerson: string;
    forPersonStatus: string;
    otherProgram: string;
  };
};

// Self/Other Renew Parole Schema
const selfOtherRenewParoleSchema = createPartialSchema<SelfOtherRenewParoleSchemaType>({
  selfOrOtherRenewParole: Yup.object({
    program: Yup.string().required('Please select a program'),
    forPerson: Yup.string().when('program', {
      is: 'E',
      then: () => Yup.string().required('Please select a status'),
    }),
    forPersonStatus: Yup.string().when('program', {
      is: 'H',
      then: () => Yup.string().required('Please select a status'),
    }),
    otherProgram: Yup.string().when('program', {
      is: 'I',
      then: () => Yup.string().required('Please list specific program or process'),
    }),
  }),
});

export default selfOtherRenewParoleSchema;
