import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type LengthOfStayInUSSchemaType = Partial<I131FormValues> & {
  initPipReparole: {
    lengthOfStayInUs: string;
  };
};

const LengthOfStayInUSSchema = createPartialSchema<LengthOfStayInUSSchemaType>({
  initPipReparole: Yup.object({
    lengthOfStayInUs: Yup.string().default(''),
  }),
});

export default LengthOfStayInUSSchema;
