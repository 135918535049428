import React from 'react';
import './App.css';
import { AuthProvider } from 'react-oidc-context';
import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import router from './auth/routes/routes';
import { OIDC_CONFIG } from './auth/keycloakConfig';

import './types/string.d.ts';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
      gcTime: 1000 * 60 * 30,
      retry: 1,
    },
  },
});

const App = (): JSX.Element => {
  const isDevelopment = process.env.NODE_ENV === 'development';

  return (
    <AuthProvider {...OIDC_CONFIG}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        {isDevelopment && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </AuthProvider>
  );
};

export default App;
