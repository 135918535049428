import { I131Schema } from './I131.schema';
import {
  EthnicityType,
  EyeColorType,
  GenderType,
  HairColorType,
  I131JsonSchema,
  IMMVIStatusType,
  NumberOfTripsType,
  PipStatusType,
  ReplacementReasonType,
  TotalTimeOutsideUsType,
  UnitType,
} from './I131Json.schema';

export const I131_EMPTY_JSON_DATA: I131JsonSchema = {
  applicationType: {
    reentryPermit: undefined,
    refugeeStatusForRefugeeTravelDocument: undefined,
    LprStatusForRefugeeTravelDocument: undefined,
    TpsBeneInUsForAdmissionBackToUs: undefined,
    TpsBeneInUsForAdmissionReceipt: '',
    inUsAdvanceParoleDocument: {
      pendingI485: undefined,
      pendingI485Receipt: undefined,
      pendingI589: undefined,
      pendingI589Receipt: undefined,
      pendingI821: undefined,
      pendingI821Receipt: undefined,
      deferredEnforcedDeparture: undefined,
      approvedI821d: undefined,
      approvedI821dReceipt: undefined,
      approvedI914: undefined,
      approvedI914Receipt: undefined,
      approvedI918: undefined,
      approvedI918Receipt: undefined,
      paroleeUnderIna212: undefined,
      paroleeUnderIna212CoA: undefined,
      approvedI817: undefined,
      approvedI817Receipt: undefined,
      pendingI687: undefined,
      pendingI687Receipt: undefined,
      approvedVStatus: undefined,
      approvedVStatusReceipt: undefined,
      CNMI: undefined,
      CNMIReceipt: undefined,
      other: undefined,
      otherExplanation: undefined,
    },
    outsideUsInitParoleDocument: {
      FWVP: undefined,
      FWVPReceipt: undefined,
      IMMVI: undefined,
      IMMVIStatus: '' as IMMVIStatusType,
      intergovParoleReferral: undefined,
      intergovParoleReferralAgencyBranch: '',
      intergovParoleReferralAgencyRepEmail: '',
      FRTF: undefined,
      FRTFReceipt: undefined,
      other: undefined,
      otherExplanation: '',
    },
    selfOutsideUsParoleOrOtherOutsideUsInitParole: undefined,
    selfOrOtherInUsInitParole: {
      pip: undefined,
      pipStatus: '' as PipStatusType,
      FRTF: undefined,
      FRTFReceipt: '',
      other: undefined,
      otherExplanation: '',
    },
    selfOrOtherInUsInitParoleNotSpecific: undefined,
    selfOrOtherRenewParole: {
      familyReunion: undefined,
      afghanParole: undefined,
      ukrainianParole: undefined,
      FWVP: undefined,
      IMMVI: undefined,
      IMMVIStatus: '' as IMMVIStatusType,
      CAM: undefined,
      FRTF: undefined,
      pip: undefined,
      pipStatus: '' as PipStatusType,
      other: undefined,
      otherExplanation: '',
    },
    selfOrOtherRenewParoleNotSpecific: undefined,
    i94AdmittedDate: '',
    refugeeOrLprRefugee: true,
  },
  applicantInfo: {
    fullName: {
      familyName: '',
      givenName: '',
      middleName: '',
    },
    otherNames: [],
    currentMailingAddress: {
      inCareOfName: '',
      street: '',
      unitType: '' as UnitType,
      unitNumber: '',
      cityOrTown: '',
      state: '',
      zipCode: '',
      province: '',
      postalCode: '',
      country: '',
    },
    currentPhysicalAddress: {
      inCareOfName: '',
      street: '',
      unitType: '' as UnitType,
      unitNumber: '',
      cityOrTown: '',
      state: '',
      zipCode: '',
      province: '',
      postalCode: '',
      country: '',
    },
    alienRegistrationNumber: '',
    countryOfBirth: '',
    countryOfCitizenship: '',
    gender: '' as GenderType,
    dateOfBirth: '',
    socialSecurityNumber: '',
    uscisOnlineAccountNumber: '',
    classOfAdmission: '',
    i94Number: '',
    expirationDate: '',
    eMedicalUSParoleeID: '',
  },
  infoAboutThem: {
    fullName: {
      familyName: '',
      givenName: '',
      middleName: '',
    },
    otherNames: [],
    dateOfBirth: '',
    countryOfBirth: '',
    countryOfCitizenship: '',
    daytimePhone: '',
    emailAddress: '',
    alienRegistrationNumber: '',
    currentMailingAddress: {
      inCareOfName: '',
      street: '',
      unitType: '' as UnitType,
      unitNumber: '',
      cityOrTown: '',
      state: '',
      zipCode: '',
      province: '',
      postalCode: '',
      country: '',
    },
    currentPhysicalAddress: {
      inCareOfName: '',
      street: '',
      unitType: '' as UnitType,
      unitNumber: '',
      cityOrTown: '',
      state: '',
      zipCode: '',
      province: '',
      postalCode: '',
      country: '',
    },
    classOfAdmission: '',
    i94Number: '',
  },
  biographicInfo: {
    ethnicity: '' as EthnicityType,
    race: [],
    height: {
      feet: undefined,
      inches: undefined,
    },
    weight: undefined,
    eyeColor: '' as EyeColorType,
    hairColor: '' as HairColorType,
  },
  processingInfo: {
    previousProceedings: undefined,
    previousReentryPermit: {
      issued: undefined,
      dateIssued: '',
      disposition: '',
    },
    previousAdvanceParole: {
      issued: undefined,
      dateIssued: '',
      disposition: '',
    },
    requestReplacement: undefined,
    reasonForReplacement: '' as ReplacementReasonType,
    infoCorrection: [],
    infoCorrectionExplanation: '',
    replacementReceiptNumber: '',
    documentSendTo: {
      toUsAddress: undefined,
      toUsEmbassy: undefined,
      embassyCityOrTown: '',
      embassyCountry: '',
    },
    noticeSendTo: {
      toP2Q3: undefined,
      toP4Q9a: undefined,
    },
    noticeSendToAddress: {
      address: {
        inCareOfName: '',
        street: '',
        unitType: '' as UnitType,
        unitNumber: '',
        cityOrTown: '',
        state: '',
        zipCode: '',
        province: '',
        postalCode: '',
        country: '',
      },
      daytimePhone: '',
      emailAddress: '',
    },
  },
  totalTimeOutsideUs: '' as TotalTimeOutsideUsType,
  refugeeTravelDocumentInfo: {
    originCountry: '',
    planToTravelBack: undefined,
    returnToCountry: undefined,
    appliedForPassport: undefined,
    receiveBenefits: undefined,
    reacquiredNationality: undefined,
    acquiredNewNationality: undefined,
    grantedAsyleeForOtherCountry: undefined,
    fileForRefugeeBeforeLeavingUs: undefined,
    currentlyOutsideUs: undefined,
    currentLocation: '',
    otherCountries: '',
  },
  proposedTravel: {
    departureDate: '',
    purpose: '',
    countriesIntendedToVisit: '',
    numberOfTrips: '' as NumberOfTripsType,
    expectedLength: undefined,
  },
  initPipReparole: {
    qualification: '',
    lengthOfStayInUs: '',
    dateOfIntendedArrival: '',
    locationToNotify: {
      cityOrTown: '',
      country: '',
    },
  },
  eadForNewOrReparole: true,
  applicantContactAndCertification: {
    daytimePhone: '',
    mobilePhone: '',
    email: '',
    signature: '',
    dateOfSignature: '',
  },
  interpreterContactAndCertification: {
    fullName: {
      familyName: '',
      givenName: '',
    },
    businessName: '',
    daytimePhone: '',
    mobilePhone: '',
    email: '',
    language: '',
    signature: '',
    dateOfSignature: '',
  },
  preparerContactAndCertification: {
    fullName: {
      familyName: '',
      givenName: '',
    },
    businessName: '',
    daytimePhone: '',
    mobilePhone: '',
    email: '',
    signature: '',
    dateOfSignature: '',
  },
  additionalInformation: [],
  evidences: [],
};

export const I131_SAMPLE_JSON_DATA: I131JsonSchema = {
  applicationType: {
    reentryPermit: undefined,
    refugeeStatusForRefugeeTravelDocument: true,
    LprStatusForRefugeeTravelDocument: undefined,
    TpsBeneInUsForAdmissionBackToUs: undefined,
    TpsBeneInUsForAdmissionReceipt: '',
    inUsAdvanceParoleDocument: {
      pendingI485: undefined,
      pendingI485Receipt: undefined,
      pendingI589: undefined,
      pendingI589Receipt: undefined,
      pendingI821: undefined,
      pendingI821Receipt: undefined,
      deferredEnforcedDeparture: undefined,
      approvedI821d: undefined,
      approvedI821dReceipt: undefined,
      approvedI914: undefined,
      approvedI914Receipt: undefined,
      approvedI918: undefined,
      approvedI918Receipt: undefined,
      paroleeUnderIna212: undefined,
      paroleeUnderIna212CoA: undefined,
      approvedI817: undefined,
      approvedI817Receipt: undefined,
      pendingI687: undefined,
      pendingI687Receipt: undefined,
      approvedVStatus: undefined,
      approvedVStatusReceipt: undefined,
      CNMI: undefined,
      CNMIReceipt: undefined,
      other: undefined,
      otherExplanation: undefined,
    },
    outsideUsInitParoleDocument: {
      FWVP: undefined,
      FWVPReceipt: undefined,
      IMMVI: undefined,
      IMMVIStatus: '' as IMMVIStatusType,
      intergovParoleReferral: undefined,
      intergovParoleReferralAgencyBranch: '',
      intergovParoleReferralAgencyRepEmail: '',
      FRTF: undefined,
      FRTFReceipt: undefined,
      other: undefined,
      otherExplanation: '',
    },
    selfOutsideUsParoleOrOtherOutsideUsInitParole: undefined,
    selfOrOtherInUsInitParole: {
      pip: undefined,
      pipStatus: '' as PipStatusType,
      FRTF: undefined,
      FRTFReceipt: '',
      other: undefined,
      otherExplanation: '',
    },
    selfOrOtherInUsInitParoleNotSpecific: undefined,
    selfOrOtherRenewParole: {
      familyReunion: undefined,
      afghanParole: undefined,
      ukrainianParole: undefined,
      FWVP: undefined,
      IMMVI: undefined,
      IMMVIStatus: '' as IMMVIStatusType,
      CAM: undefined,
      FRTF: undefined,
      pip: undefined,
      pipStatus: '' as PipStatusType,
      other: undefined,
      otherExplanation: '',
    },
    selfOrOtherRenewParoleNotSpecific: undefined,
    i94AdmittedDate: '',
    refugeeOrLprRefugee: true,
  },
  applicantInfo: {
    fullName: {
      familyName: 'Last N',
      givenName: 'First N',
      middleName: 'M',
    },
    otherNames: [
      {
        familyName: 'OLast1',
        givenName: 'OFirst1',
        middleName: 'OM1',
      },
      {
        familyName: 'OLast2',
        givenName: 'OFirst2',
        middleName: 'OM2',
      },
      {
        familyName: 'OLast3',
        givenName: 'OFirst3',
        middleName: 'OM3',
      },
    ],
    currentMailingAddress: {
      inCareOfName: 'App ICON',
      street: 'ST1',
      unitType: '' as UnitType,
      unitNumber: '',
      cityOrTown: 'CT1',
      state: 'AL',
      zipCode: '43123',
      province: '',
      postalCode: '',
      country: 'USA',
    },
    currentPhysicalAddress: {
      inCareOfName: 'App ICON',
      street: 'ST1',
      unitType: '' as UnitType,
      unitNumber: '',
      cityOrTown: 'CT2',
      state: 'AK',
      zipCode: '43123',
      province: '',
      postalCode: '',
      country: 'USA',
    },
    alienRegistrationNumber: '123123123',
    countryOfBirth: 'CoB',
    countryOfCitizenship: 'CoC',
    gender: 'anotherGenderIdentity',
    dateOfBirth: '1990-12-25',
    socialSecurityNumber: '111223333',
    uscisOnlineAccountNumber: '123456789012',
    classOfAdmission: 'CoA',
    i94Number: 'IA-123412345',
    expirationDate: '2026-05-21',
    eMedicalUSParoleeID: 'P2Q15-e medical ID',
  },
  infoAboutThem: {
    fullName: {
      familyName: 'TLast',
      givenName: 'TFirst',
      middleName: 'TM',
    },
    otherNames: [
      {
        familyName: 'OTLast1',
        givenName: 'OTFirst1',
        middleName: 'OTM1',
      },
      {
        familyName: 'OTLast2',
        givenName: 'OTFirst2',
        middleName: 'OTM2',
      },
      {
        familyName: 'OTLast3',
        givenName: 'OTFirst3',
        middleName: 'OTM3',
      },
    ],
    dateOfBirth: '2000-04-30',
    countryOfBirth: 'TCoB',
    countryOfCitizenship: 'TCoC',
    daytimePhone: '(123) 123-4567',
    emailAddress: 'P2Q22email@address.com',
    alienRegistrationNumber: '321321321',
    currentMailingAddress: {
      inCareOfName: 'Their In care of name',
      street: 'A Street',
      unitType: 'STE',
      unitNumber: 'Y100',
      cityOrTown: 'A City',
      state: '',
      zipCode: '',
      province: 'A Province',
      postalCode: 'Postal C',
      country: 'A Country',
    },
    currentPhysicalAddress: {
      inCareOfName: 'Their In care of name',
      street: 'A Street',
      unitType: 'STE',
      unitNumber: 'Y101',
      cityOrTown: 'A City',
      state: '',
      zipCode: '',
      province: 'A Province',
      postalCode: 'Postal C',
      country: 'A Country',
    },
    classOfAdmission: 'TCoA',
    i94Number: 'TI94123123',
  },
  biographicInfo: {
    ethnicity: 'notHispanicOrLatino',
    race: [
      'americanIndianOrAlaskaNative',
      'asian',
      'blackOrAfricanAmerican',
      'nativeHawaiianOrOtherPacificIslander',
      'white',
    ],
    height: {
      feet: 5,
      inches: 1,
    },
    weight: 98,
    eyeColor: 'blue',
    hairColor: 'unknown',
  },
  processingInfo: {
    previousProceedings: true,
    previousReentryPermit: {
      issued: true,
      dateIssued: '2001-06-14',
      disposition: "P4Q2C-I don't know",
    },
    previousAdvanceParole: {
      issued: true,
      dateIssued: '2001-06-15',
      disposition: "P4Q3C-I still don't know",
    },
    requestReplacement: false,
    reasonForReplacement: 'stolenOrDamage',
    infoCorrection: [
      'name',
      'aNumber',
      'countryOfBirth',
      'termsAndConditions',
      'dateOfBirth',
      'gender',
      'validityDate',
      'photo',
    ],
    infoCorrectionExplanation: 'P4Q6A-explanation',
    replacementReceiptNumber: 'P4Q6B-receipt',
    documentSendTo: {
      toUsAddress: false,
      toUsEmbassy: true,
      embassyCityOrTown: 'P4Q7B-embassy city',
      embassyCountry: 'P4Q7B-embassy country',
    },
    noticeSendTo: {
      toP2Q3: false,
      toP4Q9a: true,
    },
    noticeSendToAddress: {
      address: {
        inCareOfName: 'Notice In care of name',
        street: 'A Street',
        unitType: 'FLR',
        unitNumber: '2',
        cityOrTown: 'A City',
        state: '',
        zipCode: '',
        province: 'A Province',
        postalCode: 'Postal C',
        country: 'A Country',
      },
      daytimePhone: '(333) 444-5555',
      emailAddress: 'P4Q9c@email.com',
    },
  },
  totalTimeOutsideUs: 'oneToTwoYears',
  refugeeTravelDocumentInfo: {
    originCountry: 'P6Q1-country',
    planToTravelBack: false,
    returnToCountry: true,
    appliedForPassport: false,
    receiveBenefits: true,
    reacquiredNationality: false,
    acquiredNewNationality: true,
    grantedAsyleeForOtherCountry: false,
    fileForRefugeeBeforeLeavingUs: true,
    currentlyOutsideUs: false,
    currentLocation: 'P6Q6B-current location',
    otherCountries: 'P6Q6C-other country',
  },
  proposedTravel: {
    departureDate: '2025-06-13',
    purpose: 'P7Q2-purpose',
    countriesIntendedToVisit: 'Count1, country2, etc.',
    numberOfTrips: 'moreThanOneTrip',
    expectedLength: 50,
  },
  initPipReparole: {
    qualification: 'P8Q1-I am qualified',
    lengthOfStayInUs: '2 months',
    dateOfIntendedArrival: '2025-03-19',
    locationToNotify: {
      cityOrTown: 'B City',
      country: 'B Country',
    },
  },
  eadForNewOrReparole: true,
  applicantContactAndCertification: {
    daytimePhone: '2223336666',
    mobilePhone: '2223336666',
    email: 'P10Q3@email.com',
    signature: 'My signature',
    dateOfSignature: '2025-04-15',
  },
  interpreterContactAndCertification: {
    fullName: {
      familyName: 'IntLast',
      givenName: 'IntFirst',
    },
    businessName: 'Interpreter, LLC',
    daytimePhone: '3334441111',
    mobilePhone: '3334441111',
    email: 'P11Q5@email.com',
    language: 'My language',
    signature: 'Int signature',
    dateOfSignature: '2024-04-22',
  },
  preparerContactAndCertification: {
    fullName: {
      familyName: 'PrepLast',
      givenName: 'PrepFirst',
    },
    businessName: 'Preparer, LLC',
    daytimePhone: '2228882222',
    mobilePhone: '2228882222',
    email: 'P12Q5@email.com',
    signature: 'Prep signature',
    dateOfSignature: '2024-04-23',
  },
  additionalInformation: [
    {
      question: 'returnToCountryExplanation:',
      explanation: 'R2C',
    },
    {
      question: 'receiveBenefitsExplanation:',
      explanation: 'RBE',
    },
    {
      question: 'acquiredNewNationalityExplanation:',
      explanation: 'ANN',
    },
    {
      question: 'fileForRefugeeBeforeLeavingUsExplanation:',
      explanation: 'F4RB4LUS',
    },
  ],
  evidences: [],
};

export const I131_SAMPLE_YUP_DATA: I131Schema = {
  applicationType: '2',
  tpsBeneficiary: { tpsReceiptNumber: '' },
  inUsAdvanceParoleDocument: {
    program: '',
    receiptNumber: '',
  },
  outsideUsInitParoleDocument: {
    program: '',
    forPerson: '',
    receiptNumber: '',
    govExecBranch: '',
    repEmailAddress: '',
    otherProgram: '',
  },
  selfOrOtherInUsInitParole: {
    program: '',
    forPersonStatus: '',
    receiptNumber: '',
    otherProgram: '',
  },
  selfOrOtherRenewParole: {
    program: '',
    forPerson: '',
    forPersonStatus: '',
    otherProgram: '',
  },
  i94AdmittedDate: '',
  refugeeOrLprRefugee: 'Yes',
  applicantInfo: {
    fullName: {
      familyName: 'Last N',
      givenName: 'First N',
      middleName: 'M',
    },
    otherNames: [
      {
        familyName: 'OLast1',
        givenName: 'OFirst1',
        middleName: 'OM1',
      },
      {
        familyName: 'OLast2',
        givenName: 'OFirst2',
        middleName: 'OM2',
      },
      {
        familyName: 'OLast3',
        givenName: 'OFirst3',
        middleName: 'OM3',
      },
    ],
    currentMailingAddress: {
      inCareOfName: 'App ICON',
      street: 'ST1',
      unitType: '',
      unitNumber: '',
      cityOrTown: 'CT1',
      state: 'AL',
      zipCode: '43123',
      province: '',
      postalCode: '',
      country: 'USA',
    },
    isPhysicalSameAsMailing: 'No',
    currentPhysicalAddress: {
      inCareOfName: 'App ICON',
      street: 'ST1',
      unitType: '',
      unitNumber: '',
      cityOrTown: 'CT2',
      state: 'AK',
      zipCode: '43123',
      province: '',
      postalCode: '',
      country: 'USA',
    },
    alienRegistrationNumber: '123123123',
    countryOfBirth: 'CoB',
    countryOfCitizenship: 'CoC',
    gender: 'anotherGenderIdentity',
    dateOfBirth: '12/25/1990',
    socialSecurityNumber: '111-22-3333',
    uscisOnlineAccountNumber: '123456789012',
    classOfAdmission: 'CoA',
    i94Number: 'IA-123412345',
    expirationDate: '05/21/2026',
    eMedicalUSParoleeID: 'P2Q15-e medical ID',
    applyingForSomeoneElse: '',
  },
  infoAboutThem: {
    fullName: {
      familyName: 'TLast',
      givenName: 'TFirst',
      middleName: 'TM',
    },
    otherNames: [
      {
        familyName: 'OTLast1',
        givenName: 'OTFirst1',
        middleName: 'OTM1',
      },
      {
        familyName: 'OTLast2',
        givenName: 'OTFirst2',
        middleName: 'OTM2',
      },
      {
        familyName: 'OTLast3',
        givenName: 'OTFirst3',
        middleName: 'OTM3',
      },
    ],
    dateOfBirth: '04/30/2000',
    countryOfBirth: 'TCoB',
    countryOfCitizenship: 'TCoC',
    daytimePhone: '(123) 123-4567',
    emailAddress: 'P2Q22email@address.com',
    alienRegistrationNumber: '321321321',
    currentMailingAddress: {
      inCareOfName: 'Their In care of name',
      street: 'A Street',
      unitType: 'STE',
      unitNumber: 'Y100',
      cityOrTown: 'A City',
      state: '',
      zipCode: '',
      province: 'A Province',
      postalCode: 'Postal C',
      country: 'A Country',
    },
    isPhysicalSameAsMailing: 'No',
    currentPhysicalAddress: {
      inCareOfName: 'Their In care of name',
      street: 'A Street',
      unitType: 'STE',
      unitNumber: 'Y101',
      cityOrTown: 'A City',
      state: '',
      zipCode: '',
      province: 'A Province',
      postalCode: 'Postal C',
      country: 'A Country',
    },
    classOfAdmission: 'TCoA',
    i94Number: 'TI94123123',
  },
  biographicInfo: {
    ethnicity: 'notHispanicOrLatino',
    race: [
      'americanIndianOrAlaskaNative',
      'asian',
      'blackOrAfricanAmerican',
      'nativeHawaiianOrOtherPacificIslander',
      'white',
    ],
    height: {
      feet: '5',
      inches: '1',
    },
    weight: '98',
    eyeColor: 'Blue',
    hairColor: 'Unknown',
  },
  processingInfo: {
    previousProceedings: 'Yes',
    previousReentryPermit: {
      issued: 'Yes',
      dateIssued: '06/14/2001',
      disposition: "P4Q2C-I don't know",
    },
    previousAdvanceParole: {
      issued: 'Yes',
      dateIssued: '06/15/2001',
      disposition: "P4Q3C-I still don't know",
    },
    requestReplacement: 'No',
    reasonForReplacement: 'stolenOrDamage',
    infoCorrection: [
      'name',
      'aNumber',
      'countryOfBirth',
      'termsAndConditions',
      'dateOfBirth',
      'gender',
      'validityDate',
      'photo',
    ],
    infoCorrectionExplanation: 'P4Q6A-explanation',
    replacementReceiptNumber: 'P4Q6B-receipt',
    documentSendTo: {
      toUsAddressOrEmbassy: 'usEmbassy',
      embassyCityOrTown: 'P4Q7B-embassy city',
      embassyCountry: 'P4Q7B-embassy country',
    },
    noticeSendTo: {
      selectDestination: 'toP4Q9a',
      address: {
        inCareOfName: 'Notice In care of name',
        street: 'A Street',
        unitType: 'FLR',
        unitNumber: '2',
        cityOrTown: 'A City',
        state: '',
        zipCode: '',
        province: 'A Province',
        postalCode: 'Postal C',
        country: 'A Country',
      },
      daytimePhone: '(333) 444-5555',
      emailAddress: 'P4Q9c@email.com',
    },
  },
  totalTimeOutsideUs: 'oneToTwoYears',
  refugeeTravelDocumentInfo: {
    originCountry: 'P6Q1-country',
    planToTravelBack: 'No',
    planToTravelBackExplanation: '',
    returnToCountry: 'Yes',
    returnToCountryExplanation: 'R2C',
    appliedForPassport: 'No',
    appliedForPassportExplanation: '',
    receiveBenefits: 'Yes',
    receiveBenefitsExplanation: 'RBE',
    reacquiredNationality: 'No',
    reacquiredNationalityExplanation: '',
    acquiredNewNationality: 'Yes',
    acquiredNewNationalityExplanation: 'ANN',
    grantedAsyleeForOtherCountry: 'No',
    grantedAsyleeForOtherCountryExplanation: '',
    fileForRefugeeBeforeLeavingUs: 'Yes',
    fileForRefugeeBeforeLeavingUsExplanation: 'F4RB4LUS',
    currentlyOutsideUs: 'No',
    currentLocation: 'P6Q6B-current location',
    otherCountries: 'P6Q6C-other country',
  },
  proposedTravel: {
    departureDate: '06/13/2025',
    purpose: 'P7Q2-purpose',
    countriesIntendedToVisit: 'Count1, country2, etc.',
    numberOfTrips: 'moreThanOneTrip',
    expectedLength: '50',
  },
  initPipReparole: {
    intendedRecipientOutsideOfUS: 'Yes',
    qualification: 'P8Q1-I am qualified',
    lengthOfStayInUs: '2 months',
    dateOfIntendedArrival: '03/19/2025',
    locationToNotify: {
      cityOrTown: 'B City',
      country: 'B Country',
    },
  },
  eadForNewOrReparole: 'Yes',
  applicantContactAndCertification: {
    daytimePhone: '2223336666',
    mobilePhone: '2223336666',
    email: 'P10Q3@email.com',
    signature: 'My signature',
    dateOfSignature: '04/15/2025',
  },
  interpreterContactAndCertification: {
    hasInterpreter: 'Yes',
    fullName: {
      familyName: 'IntLast',
      givenName: 'IntFirst',
    },
    businessName: 'Interpreter, LLC',
    daytimePhone: '3334441111',
    mobilePhone: '3334441111',
    email: 'P11Q5@email.com',
    language: 'My language',
    signature: 'Int signature',
    dateOfSignature: '04/22/2024',
  },
  preparerContactAndCertification: {
    hasPreparer: 'Yes',
    fullName: {
      familyName: 'PrepLast',
      givenName: 'PrepFirst',
    },
    businessName: 'Preparer, LLC',
    daytimePhone: '2228882222',
    mobilePhone: '2228882222',
    email: 'P12Q5@email.com',
    signature: 'Prep signature',
    dateOfSignature: '04/23/2024',
  },
  additionalInformation: [],
  evidences: [],
};
