import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { styleConstants } from '../constants/styleConstants';
import { themeConstants } from '../constants/themeConstants';
import { MessageBubbleProps } from '../types';

const shouldNotForwardProp = (prop: string): boolean => !['isBot'].includes(prop);

export const ChatContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  backgroundColor: theme.palette.background.paper,
}));

export const ChatMessages = styled(Box)(() => ({
  flexGrow: 1,
  overflow: 'auto',
  padding: styleConstants.components.card.padding,
}));

export const ChatInputContainer = styled(Box)(() => ({
  borderTop: `1px solid ${themeConstants.colors.grey[200]}`,
  padding: styleConstants.components.card.padding,
}));

export const MessageBubble = styled(Box, {
  shouldForwardProp: shouldNotForwardProp,
})<MessageBubbleProps>(({ theme, isBot }) => ({
  maxWidth: '80%',
  padding: `${styleConstants.components.button.padding.vertical} ${styleConstants.components.button.padding.horizontal}`,
  backgroundColor: isBot ? themeConstants.colors.grey[100] : themeConstants.colors.blue[500],
  color: isBot ? themeConstants.colors.grey[500] : themeConstants.colors.white,
  borderRadius: styleConstants.components.button.borderRadius,
  marginBottom: theme.spacing(1),
  alignSelf: isBot ? 'flex-start' : 'flex-end',
}));
