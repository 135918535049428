import { checkDate } from '@src/utils/pureFunctions';
import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type DateOfBirthSchemaType = Partial<I131FormValues> & {
  applicantInfo: {
    dateOfBirth: string;
  };
};

const DateOfBirthSchema = createPartialSchema<DateOfBirthSchemaType>({
  applicantInfo: Yup.object({
    dateOfBirth: Yup.string()
      .default('')
      .test('validateDate', 'Invalid date format', (value: string) => checkDate(value, false)),
  }),
});

export default DateOfBirthSchema;
