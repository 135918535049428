import * as Yup from 'yup';
import { createPartialSchema, I131FormValues } from '@src/types/form.types';

// TPS Beneficiary Schema Type
export type TPSBeneficiarySchemaType = Partial<I131FormValues> & {
  tpsBeneficiary: {
    tpsReceiptNumber: string;
  };
};
// TPS Beneficiary Schema
const tpsBeneficiarySchema = createPartialSchema<TPSBeneficiarySchemaType>({
  tpsBeneficiary: Yup.object({
    tpsReceiptNumber: Yup.string().required('Receipt number is required when selecting TPS'),
  }),
});

export default tpsBeneficiarySchema;
