import { I131Schema, AddressSchema, AdditionalInformationSchema } from './I131.schema';
import {
  AddressType,
  EthnicityType,
  EyeColorType,
  GenderType,
  HairColorType,
  I131JsonSchema,
  IMMVIStatusType,
  InfoCorrectionType,
  NumberOfTripsType,
  PipStatusType,
  RaceType,
  ReplacementReasonType,
  TotalTimeOutsideUsType,
  UnitType,
} from './I131Json.schema';
import { ensureString, numberOrUndefined } from './pureFunctions';

export function cleanIncorrectInfo(val: (string | undefined)[]): InfoCorrectionType[] {
  const adjVal = val.filter((v) => !!v);

  return adjVal as InfoCorrectionType[];
}

export function cleanRaces(val: (string | undefined)[]): RaceType[] {
  const adjVal = val.filter((v) => !!v);

  return adjVal as RaceType[];
}

export function cleanString(val: string): string {
  return val.replace(/[-() ]/g, '');
}

export function convertDate(val: string): string {
  if (val.match(/^\d{2}\/\d{2}\/\d{4}$/) === null) return val;
  const strArr = val.split('/');

  return `${strArr[2]}-${strArr[0]}-${strArr[1]}`;
}

export function defaultBoolOrUndefined(val1: string, val2: string, defaultVal: boolean): boolean | undefined {
  return val1 === val2 ? defaultVal : undefined;
}

export function defaultStrOrUndefined(val1: string, val2: string, defaultVal: string): string | undefined {
  return val1 === val2 ? defaultVal : undefined;
}

export function ensureBooleanOrUndefined(
  val: string | boolean | undefined,
  returnUndefined = false
): boolean | undefined {
  if (returnUndefined && (val === undefined || val === '')) return undefined;

  return !!val;
}

export function mapAddress(input: AddressSchema): AddressType {
  const isUs = input.country === 'USA' || input.country === '';

  return {
    inCareOfName: input.inCareOfName,
    street: input.street,
    unitType: input.unitType as UnitType,
    unitNumber: input.unitNumber,
    cityOrTown: input.cityOrTown,
    state: isUs ? input.state : '',
    zipCode: isUs ? input.zipCode : '',
    province: isUs ? '' : input.province,
    postalCode: isUs ? '' : input.postalCode,
    country: input.country,
  };
}

export function mapBoolean(input: string): boolean | undefined {
  switch (input.toUpperCase()) {
    case 'YES':
      return true;
    case 'NO':
      return false;
    default:
      return undefined;
  }
}

export function mapF2JAdditionalInformation(formData: I131Schema): AdditionalInformationSchema[] {
  const result = [] as AdditionalInformationSchema[];

  if (formData.refugeeTravelDocumentInfo.planToTravelBackExplanation)
    result.push({
      question: 'planToTravelBackExplanation:',
      explanation: formData.refugeeTravelDocumentInfo.planToTravelBackExplanation,
    });
  if (formData.refugeeTravelDocumentInfo.returnToCountryExplanation)
    result.push({
      question: 'returnToCountryExplanation:',
      explanation: formData.refugeeTravelDocumentInfo.returnToCountryExplanation,
    });
  if (formData.refugeeTravelDocumentInfo.appliedForPassportExplanation)
    result.push({
      question: 'appliedForPassportExplanation:',
      explanation: formData.refugeeTravelDocumentInfo.appliedForPassportExplanation,
    });
  if (formData.refugeeTravelDocumentInfo.receiveBenefitsExplanation)
    result.push({
      question: 'receiveBenefitsExplanation:',
      explanation: formData.refugeeTravelDocumentInfo.receiveBenefitsExplanation,
    });
  if (formData.refugeeTravelDocumentInfo.reacquiredNationalityExplanation)
    result.push({
      question: 'reacquiredNationalityExplanation:',
      explanation: formData.refugeeTravelDocumentInfo.reacquiredNationalityExplanation,
    });
  if (formData.refugeeTravelDocumentInfo.acquiredNewNationalityExplanation)
    result.push({
      question: 'acquiredNewNationalityExplanation:',
      explanation: formData.refugeeTravelDocumentInfo.acquiredNewNationalityExplanation,
    });
  if (formData.refugeeTravelDocumentInfo.grantedAsyleeForOtherCountryExplanation)
    result.push({
      question: 'grantedAsyleeForOtherCountryExplanation:',
      explanation: formData.refugeeTravelDocumentInfo.grantedAsyleeForOtherCountryExplanation,
    });
  if (formData.refugeeTravelDocumentInfo.fileForRefugeeBeforeLeavingUsExplanation)
    result.push({
      question: 'fileForRefugeeBeforeLeavingUsExplanation:',
      explanation: formData.refugeeTravelDocumentInfo.fileForRefugeeBeforeLeavingUsExplanation,
    });

  return result;
}

export function mapF2JDocSendTo(input: string, selected: string): boolean | undefined {
  if (!selected) return undefined;
  return input === selected;
}

export function mapInUsAdvancedParole(selection: string, receipt: string): { selected: string; receipt: string } {
  switch (selection) {
    case 'A':
      return { selected: 'pendingI485', receipt: receipt };
    case 'B':
      return { selected: 'pendingI589', receipt: receipt };
    case 'C':
      return { selected: 'pendingI821', receipt: receipt };
    case 'D':
      return { selected: 'deferredEnforcedDeparture', receipt: '' };
    case 'E':
      return { selected: 'approvedI821d', receipt: receipt };
    case 'F':
      return { selected: 'approvedI914', receipt: receipt };
    case 'G':
      return { selected: 'approvedI918', receipt: receipt };
    case 'H':
      return { selected: 'paroleeUnderIna212', receipt: receipt };
    case 'I':
      return { selected: 'approvedI817', receipt: receipt };
    case 'J':
      return { selected: 'pendingI687', receipt: receipt };
    case 'K':
      return { selected: 'approvedVStatus', receipt: receipt };
    case 'L':
      return { selected: 'CNMI', receipt: receipt };
    case 'M':
      return { selected: 'other', receipt: receipt };
    default:
      return { selected: '', receipt: '' };
  }
}

export function mapF2JNoticeSendTo(input: string, selected: string): boolean | undefined {
  if (!selected) return undefined;
  return input === selected;
}

export function mapOutsideUsInitParole(selection: string): string {
  switch (selection) {
    case 'A':
      return 'FWVP';
    case 'B':
      return 'IMMVI';
    case 'C':
      return 'intergovParoleReferral';
    case 'D':
      return 'FRTF';
    case 'E':
      return 'other';
    default:
      return '';
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapProposedTravel(input: any): any {
  if (!input) return [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return input.map((item: any) => {
    return {
      ...item,
      departureDate: convertDate(item.departureDate),
      expectedLength: numberOrUndefined(item.expectedLength),
    };
  });
}

// replace formData type with I-131 yup-schema type
function I131FormikToJson(formData: I131Schema): I131JsonSchema {
  const mapInUsAP = mapInUsAdvancedParole(
    formData.inUsAdvanceParoleDocument.program,
    formData.inUsAdvanceParoleDocument.receiptNumber
  );
  const mapOutsideUsIP = mapOutsideUsInitParole(formData.outsideUsInitParoleDocument.program);

  return {
    applicationType: {
      reentryPermit: defaultBoolOrUndefined('1', formData.applicationType, true),
      refugeeStatusForRefugeeTravelDocument: defaultBoolOrUndefined('2', formData.applicationType, true),
      LprStatusForRefugeeTravelDocument: defaultBoolOrUndefined('3', formData.applicationType, true),
      TpsBeneInUsForAdmissionBackToUs: ensureBooleanOrUndefined(formData.tpsBeneficiary.tpsReceiptNumber, true),
      TpsBeneInUsForAdmissionReceipt: ensureString(formData.tpsBeneficiary.tpsReceiptNumber),
      inUsAdvanceParoleDocument: {
        pendingI485: defaultBoolOrUndefined('pendingI485', mapInUsAP.selected, true),
        pendingI485Receipt: defaultStrOrUndefined('pendingI485', mapInUsAP.selected, mapInUsAP.receipt),
        pendingI589: defaultBoolOrUndefined('pendingI589', mapInUsAP.selected, true),
        pendingI589Receipt: defaultStrOrUndefined('pendingI589', mapInUsAP.selected, mapInUsAP.receipt),
        pendingI821: defaultBoolOrUndefined('pendingI821', mapInUsAP.selected, true),
        pendingI821Receipt: defaultStrOrUndefined('pendingI821', mapInUsAP.selected, mapInUsAP.receipt),
        deferredEnforcedDeparture: defaultBoolOrUndefined('deferredEnforcedDeparture', mapInUsAP.selected, true),
        approvedI821d: defaultBoolOrUndefined('approvedI821d', mapInUsAP.selected, true),
        approvedI821dReceipt: defaultStrOrUndefined('approvedI821d', mapInUsAP.selected, mapInUsAP.receipt),
        approvedI914: defaultBoolOrUndefined('approvedI914', mapInUsAP.selected, true),
        approvedI914Receipt: defaultStrOrUndefined('approvedI914', mapInUsAP.selected, mapInUsAP.receipt),
        approvedI918: defaultBoolOrUndefined('approvedI918', mapInUsAP.selected, true),
        approvedI918Receipt: defaultStrOrUndefined('approvedI918', mapInUsAP.selected, mapInUsAP.receipt),
        paroleeUnderIna212: defaultBoolOrUndefined('paroleeUnderIna212', mapInUsAP.selected, true),
        paroleeUnderIna212CoA: defaultStrOrUndefined('paroleeUnderIna212', mapInUsAP.selected, mapInUsAP.receipt),
        approvedI817: defaultBoolOrUndefined('approvedI817', mapInUsAP.selected, true),
        approvedI817Receipt: defaultStrOrUndefined('approvedI817', mapInUsAP.selected, mapInUsAP.receipt),
        pendingI687: defaultBoolOrUndefined('pendingI687', mapInUsAP.selected, true),
        pendingI687Receipt: defaultStrOrUndefined('pendingI687', mapInUsAP.selected, mapInUsAP.receipt),
        approvedVStatus: defaultBoolOrUndefined('approvedVStatus', mapInUsAP.selected, true),
        approvedVStatusReceipt: defaultStrOrUndefined('approvedVStatus', mapInUsAP.selected, mapInUsAP.receipt),
        CNMI: defaultBoolOrUndefined('CNMI', mapInUsAP.selected, true),
        CNMIReceipt: defaultStrOrUndefined('CNMI', mapInUsAP.selected, mapInUsAP.receipt),
        other: defaultBoolOrUndefined('other', mapInUsAP.selected, true),
        otherExplanation: defaultStrOrUndefined('other', mapInUsAP.selected, mapInUsAP.receipt),
      },
      outsideUsInitParoleDocument: {
        FWVP: defaultBoolOrUndefined('FWVP', mapOutsideUsIP, true),
        FWVPReceipt: defaultStrOrUndefined('FWVP', mapOutsideUsIP, formData.outsideUsInitParoleDocument.receiptNumber),
        IMMVI: defaultBoolOrUndefined('IMMVI', mapOutsideUsIP, true),
        IMMVIStatus: formData.outsideUsInitParoleDocument.forPerson as IMMVIStatusType,
        intergovParoleReferral: defaultBoolOrUndefined('intergovParoleReferral', mapOutsideUsIP, true),
        intergovParoleReferralAgencyBranch: formData.outsideUsInitParoleDocument.govExecBranch,
        intergovParoleReferralAgencyRepEmail: formData.outsideUsInitParoleDocument.repEmailAddress,
        FRTF: defaultBoolOrUndefined('FRTF', mapOutsideUsIP, true),
        FRTFReceipt: defaultStrOrUndefined('FRTF', mapOutsideUsIP, formData.outsideUsInitParoleDocument.receiptNumber),
        other: defaultBoolOrUndefined('other', mapOutsideUsIP, true),
        otherExplanation: formData.outsideUsInitParoleDocument.otherProgram,
      },
      selfOutsideUsParoleOrOtherOutsideUsInitParole: defaultBoolOrUndefined('7', formData.applicationType, true),
      selfOrOtherInUsInitParole: {
        pip: defaultBoolOrUndefined('pip', formData.selfOrOtherInUsInitParole.program, true),
        pipStatus: formData.selfOrOtherInUsInitParole.forPersonStatus as PipStatusType,
        FRTF: defaultBoolOrUndefined('FRTF', formData.selfOrOtherInUsInitParole.program, true),
        FRTFReceipt: formData.selfOrOtherInUsInitParole.receiptNumber,
        other: defaultBoolOrUndefined('other', formData.selfOrOtherInUsInitParole.program, true),
        otherExplanation: formData.selfOrOtherInUsInitParole.otherProgram,
      },
      selfOrOtherInUsInitParoleNotSpecific: defaultBoolOrUndefined('9', formData.applicationType, true),
      selfOrOtherRenewParole: {
        familyReunion: defaultBoolOrUndefined('A', formData.selfOrOtherRenewParole.program, true),
        afghanParole: defaultBoolOrUndefined('B', formData.selfOrOtherRenewParole.program, true),
        ukrainianParole: defaultBoolOrUndefined('C', formData.selfOrOtherRenewParole.program, true),
        FWVP: defaultBoolOrUndefined('D', formData.selfOrOtherRenewParole.program, true),
        IMMVI: defaultBoolOrUndefined('E', formData.selfOrOtherRenewParole.program, true),
        IMMVIStatus: formData.selfOrOtherRenewParole.forPerson as IMMVIStatusType,
        CAM: defaultBoolOrUndefined('F', formData.selfOrOtherRenewParole.program, true),
        FRTF: defaultBoolOrUndefined('G', formData.selfOrOtherRenewParole.program, true),
        pip: defaultBoolOrUndefined('H', formData.selfOrOtherRenewParole.program, true),
        pipStatus: formData.selfOrOtherRenewParole.forPersonStatus as PipStatusType,
        other: defaultBoolOrUndefined('I', formData.selfOrOtherRenewParole.program, true),
        otherExplanation: formData.selfOrOtherRenewParole.otherProgram,
      },
      selfOrOtherRenewParoleNotSpecific: defaultBoolOrUndefined('11', formData.applicationType, true),
      i94AdmittedDate: convertDate(formData.i94AdmittedDate),
      refugeeOrLprRefugee: mapBoolean(formData.refugeeOrLprRefugee),
    },
    applicantInfo: {
      fullName: formData.applicantInfo.fullName,
      otherNames: formData.applicantInfo.otherNames,
      currentMailingAddress: mapAddress(formData.applicantInfo.currentMailingAddress),
      currentPhysicalAddress: mapAddress(formData.applicantInfo.currentPhysicalAddress),
      alienRegistrationNumber: cleanString(formData.applicantInfo.alienRegistrationNumber),
      countryOfBirth: formData.applicantInfo.countryOfBirth,
      countryOfCitizenship: formData.applicantInfo.countryOfCitizenship,
      gender: formData.applicantInfo.gender as GenderType,
      dateOfBirth: convertDate(formData.applicantInfo.dateOfBirth),
      socialSecurityNumber: cleanString(formData.applicantInfo.socialSecurityNumber),
      uscisOnlineAccountNumber: cleanString(formData.applicantInfo.uscisOnlineAccountNumber),
      classOfAdmission: formData.applicantInfo.classOfAdmission,
      i94Number: formData.applicantInfo.i94Number,
      expirationDate: convertDate(formData.applicantInfo.expirationDate),
      eMedicalUSParoleeID: formData.applicantInfo.eMedicalUSParoleeID,
    },
    infoAboutThem: {
      fullName: formData.infoAboutThem.fullName,
      otherNames: formData.infoAboutThem.otherNames,
      dateOfBirth: convertDate(formData.infoAboutThem.dateOfBirth),
      countryOfBirth: formData.infoAboutThem.countryOfBirth,
      countryOfCitizenship: formData.infoAboutThem.countryOfCitizenship,
      daytimePhone: formData.infoAboutThem.daytimePhone,
      emailAddress: formData.infoAboutThem.emailAddress,
      alienRegistrationNumber: cleanString(formData.infoAboutThem.alienRegistrationNumber),
      currentMailingAddress: mapAddress(formData.infoAboutThem.currentMailingAddress),
      currentPhysicalAddress: mapAddress(formData.infoAboutThem.currentPhysicalAddress),
      classOfAdmission: formData.infoAboutThem.classOfAdmission,
      i94Number: formData.infoAboutThem.i94Number,
    },
    biographicInfo: {
      ethnicity: formData.biographicInfo.ethnicity as EthnicityType,
      race: cleanRaces(formData.biographicInfo.race),
      height: {
        feet: numberOrUndefined(formData.biographicInfo.height.feet),
        inches: numberOrUndefined(formData.biographicInfo.height.inches),
      },
      weight: numberOrUndefined(formData.biographicInfo.weight),
      eyeColor: formData.biographicInfo.eyeColor.toLowerCase() as EyeColorType,
      hairColor: formData.biographicInfo.hairColor.toLowerCase() as HairColorType,
    },
    processingInfo: {
      previousProceedings: mapBoolean(formData.processingInfo.previousProceedings),
      previousReentryPermit: {
        issued: mapBoolean(formData.processingInfo.previousAdvanceParole.issued),
        dateIssued: convertDate(formData.processingInfo.previousReentryPermit.dateIssued),
        disposition: formData.processingInfo.previousReentryPermit.disposition,
      },
      previousAdvanceParole: {
        issued: mapBoolean(formData.processingInfo.previousAdvanceParole.issued),
        dateIssued: convertDate(formData.processingInfo.previousAdvanceParole.dateIssued),
        disposition: formData.processingInfo.previousAdvanceParole.disposition,
      },
      requestReplacement: mapBoolean(formData.processingInfo.requestReplacement),
      reasonForReplacement: formData.processingInfo.reasonForReplacement as ReplacementReasonType,
      infoCorrection: cleanIncorrectInfo(formData.processingInfo.infoCorrection),
      infoCorrectionExplanation: formData.processingInfo.infoCorrectionExplanation,
      replacementReceiptNumber: formData.processingInfo.replacementReceiptNumber,
      documentSendTo: {
        toUsAddress: mapF2JDocSendTo('usAddress', formData.processingInfo.documentSendTo.toUsAddressOrEmbassy),
        toUsEmbassy: mapF2JDocSendTo('usEmbassy', formData.processingInfo.documentSendTo.toUsAddressOrEmbassy),
        embassyCityOrTown: formData.processingInfo.documentSendTo.embassyCityOrTown,
        embassyCountry: formData.processingInfo.documentSendTo.embassyCountry,
      },
      noticeSendTo: {
        toP2Q3: mapF2JNoticeSendTo('toP2Q3', formData.processingInfo.noticeSendTo.selectDestination),
        toP4Q9a: mapF2JNoticeSendTo('toP4Q9a', formData.processingInfo.noticeSendTo.selectDestination),
      },
      noticeSendToAddress: {
        address: mapAddress(formData.processingInfo.noticeSendTo.address),
        daytimePhone: formData.processingInfo.noticeSendTo.daytimePhone,
        emailAddress: formData.processingInfo.noticeSendTo.emailAddress,
      },
    },
    totalTimeOutsideUs: formData.totalTimeOutsideUs as TotalTimeOutsideUsType,
    refugeeTravelDocumentInfo: {
      originCountry: formData.refugeeTravelDocumentInfo.originCountry,
      planToTravelBack: mapBoolean(formData.refugeeTravelDocumentInfo.planToTravelBack),
      returnToCountry: mapBoolean(formData.refugeeTravelDocumentInfo.returnToCountry),
      appliedForPassport: mapBoolean(formData.refugeeTravelDocumentInfo.appliedForPassport),
      receiveBenefits: mapBoolean(formData.refugeeTravelDocumentInfo.receiveBenefits),
      reacquiredNationality: mapBoolean(formData.refugeeTravelDocumentInfo.reacquiredNationality),
      acquiredNewNationality: mapBoolean(formData.refugeeTravelDocumentInfo.acquiredNewNationality),
      grantedAsyleeForOtherCountry: mapBoolean(formData.refugeeTravelDocumentInfo.grantedAsyleeForOtherCountry),
      fileForRefugeeBeforeLeavingUs: mapBoolean(formData.refugeeTravelDocumentInfo.fileForRefugeeBeforeLeavingUs),
      currentlyOutsideUs: mapBoolean(formData.refugeeTravelDocumentInfo.currentlyOutsideUs),
      currentLocation: formData.refugeeTravelDocumentInfo.currentLocation,
      otherCountries: formData.refugeeTravelDocumentInfo.otherCountries,
    },
    proposedTravel: {
      departureDate: convertDate(formData.proposedTravel.departureDate),
      purpose: formData.proposedTravel.purpose,
      countriesIntendedToVisit: formData.proposedTravel.countriesIntendedToVisit,
      numberOfTrips: formData.proposedTravel.numberOfTrips as NumberOfTripsType,
      expectedLength: numberOrUndefined(formData.proposedTravel.expectedLength),
    },
    initPipReparole: {
      qualification: formData.initPipReparole.qualification,
      lengthOfStayInUs: formData.initPipReparole.lengthOfStayInUs,
      dateOfIntendedArrival: convertDate(formData.initPipReparole.dateOfIntendedArrival),
      locationToNotify: {
        cityOrTown: formData.initPipReparole.locationToNotify.cityOrTown,
        country: formData.initPipReparole.locationToNotify.country,
      },
    },
    eadForNewOrReparole: mapBoolean(formData.eadForNewOrReparole),
    applicantContactAndCertification: {
      daytimePhone: cleanString(formData.applicantContactAndCertification.daytimePhone),
      mobilePhone: cleanString(formData.applicantContactAndCertification.mobilePhone),
      email: formData.applicantContactAndCertification.email,
      signature: formData.applicantContactAndCertification.signature,
      dateOfSignature: convertDate(formData.applicantContactAndCertification.dateOfSignature),
    },
    interpreterContactAndCertification: {
      fullName: {
        familyName: formData.interpreterContactAndCertification.fullName.familyName,
        givenName: formData.interpreterContactAndCertification.fullName.givenName,
      },
      businessName: formData.interpreterContactAndCertification.businessName,
      daytimePhone: cleanString(formData.interpreterContactAndCertification.daytimePhone),
      mobilePhone: cleanString(formData.interpreterContactAndCertification.mobilePhone),
      email: formData.interpreterContactAndCertification.email,
      language: formData.interpreterContactAndCertification.language,
      signature: formData.interpreterContactAndCertification.signature,
      dateOfSignature: convertDate(formData.interpreterContactAndCertification.dateOfSignature),
    },
    preparerContactAndCertification: {
      fullName: {
        familyName: formData.preparerContactAndCertification.fullName.familyName,
        givenName: formData.preparerContactAndCertification.fullName.givenName,
      },
      businessName: formData.preparerContactAndCertification.businessName,
      daytimePhone: cleanString(formData.preparerContactAndCertification.daytimePhone),
      mobilePhone: cleanString(formData.preparerContactAndCertification.mobilePhone),
      email: formData.preparerContactAndCertification.email,
      signature: formData.preparerContactAndCertification.signature,
      dateOfSignature: convertDate(formData.preparerContactAndCertification.dateOfSignature),
    },
    additionalInformation: mapF2JAdditionalInformation(formData),
    evidences: formData.evidences,
  };
}

export default I131FormikToJson;
