import React from 'react';
import { Box, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

const SubmissionConfirmation: React.FC = (): JSX.Element => {
  const [params] = useSearchParams();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      textAlign="center"
    >
      <Typography
        variant="h2"
        sx={{
          fontSize: '2.5rem',
          mb: 4,
          fontWeight: 100,
          color: 'primary.main',
          textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
          letterSpacing: '0.05em',
          textTransform: 'uppercase',
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: '-10px',
            left: '10%',
            width: '80%',
            height: '1px',
            /* prettier-ignore */
            background: 'linear-gradient(90deg, transparent, #1976d2, transparent)',
          },
        }}
      >
        {params.get('receiptNumber') ?? 'No receipt number found.'}
      </Typography>
    </Box>
  );
};

export default SubmissionConfirmation;
