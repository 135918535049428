import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type ClassOfAdmissionSchemaType = Partial<I131FormValues> & {
  applicantInfo: {
    classOfAdmission: string;
  };
};

const ClassOfAdmissionSchema = createPartialSchema<ClassOfAdmissionSchemaType>({
  applicantInfo: Yup.object({
    classOfAdmission: Yup.string().default(''),
  }),
});

export default ClassOfAdmissionSchema;
