import * as React from 'react';
import { useFormikContext } from 'formik';
import { CustomRadio, RequiredFieldsMessage, TextFieldWithQuestion } from '@components';
import { Container } from '@mui/material';

export const INTENDED_RECIPIENT = {
  OUTSIDE_US: {
    fieldKey: 'initPipReparole.intendedRecipientOutsideOfUS',
    question: 'Is the person intended to receive the parole document outside the United States?',
  },
  ARRIVAL_DATE: {
    fieldKey: 'initPipReparole.dateOfIntendedArrival',
    question: 'Date of Intended Arrival to the United States',
    label: 'Date (mm/dd/yyyy)',
  },
  LOCATION: {
    question:
      'Location (City or Town and Country of the U.S. Embassy, U.S. Consulate, or the USCIS international field office that you want us to notify.',
    CITY: {
      fieldKey: 'initPipReparole.locationToNotify.cityOrTown',
      label: 'City or Town',
    },
    COUNTRY: {
      fieldKey: 'initPipReparole.locationToNotify.country',
      label: 'Country',
    },
  },
};

const IntendedRecipient: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<{
    initPipReparole: {
      intendedRecipientOutsideOfUS: string;
      dateOfIntendedArrival: string;
      locationToNotify: {
        cityOrTown: string;
        country: string;
      };
    };
  }>();

  const handleRadioChange = (value: string): void => {
    setFieldValue(INTENDED_RECIPIENT.OUTSIDE_US.fieldKey, value);

    if (value === 'No') {
      // Reset all dependent fields
      setFieldValue(INTENDED_RECIPIENT.ARRIVAL_DATE.fieldKey, '');
      setFieldValue(INTENDED_RECIPIENT.LOCATION.CITY.fieldKey, '');
      setFieldValue(INTENDED_RECIPIENT.LOCATION.COUNTRY.fieldKey, '');
    }
  };

  const showLocationQuestions = values.initPipReparole?.intendedRecipientOutsideOfUS === 'Yes';

  return (
    <>
      <Container
        maxWidth="lg"
        sx={{ py: 4 }}
      >
        <RequiredFieldsMessage />
        <CustomRadio
          question={INTENDED_RECIPIENT.OUTSIDE_US.question}
          fieldName={INTENDED_RECIPIENT.OUTSIDE_US.fieldKey}
          onChange={handleRadioChange}
        />

        {showLocationQuestions && (
          <>
            <TextFieldWithQuestion
              question={INTENDED_RECIPIENT.ARRIVAL_DATE.question}
              label={INTENDED_RECIPIENT.ARRIVAL_DATE.label}
              name={INTENDED_RECIPIENT.ARRIVAL_DATE.fieldKey}
            />

            <TextFieldWithQuestion
              question={INTENDED_RECIPIENT.LOCATION.question}
              label={INTENDED_RECIPIENT.LOCATION.CITY.label}
              name={INTENDED_RECIPIENT.LOCATION.CITY.fieldKey}
              hasSecondField={true}
              secondFieldLabel={INTENDED_RECIPIENT.LOCATION.COUNTRY.label}
              secondFieldName={INTENDED_RECIPIENT.LOCATION.COUNTRY.fieldKey}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default IntendedRecipient;
