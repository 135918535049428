import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type TheirClassOfAdmissionSchemaType = Partial<I131FormValues> & {
  infoAboutThem: {
    classOfAdmission: string;
  };
};

const TheirClassOfAdmissionSchema = createPartialSchema<TheirClassOfAdmissionSchemaType>({
  infoAboutThem: Yup.object({
    classOfAdmission: Yup.string().default(''),
  }),
});

export default TheirClassOfAdmissionSchema;
