import * as Yup from 'yup';
import { createPartialSchema, I131FormValues } from '@src/types/form.types';

export type SelfOtherInUsInitParoleSchemaType = Partial<I131FormValues> & {
  selfOrOtherInUsInitParole: {
    program: string;
    forPersonStatus: string;
    receiptNumber: string;
    otherProgram: string;
  };
};

const SelfOtherInUsInitParoleSchema = createPartialSchema<SelfOtherInUsInitParoleSchemaType>({
  selfOrOtherInUsInitParole: Yup.object({
    program: Yup.string().required('Please select a program'),
    forPersonStatus: Yup.string().when('program', {
      is: 'A',
      then: () => Yup.string().required('Please select a status'),
    }),
    receiptNumber: Yup.string().when('program', {
      is: 'B',
      then: () => Yup.string().required('Task Force Registration Number is required'),
    }),
    otherProgram: Yup.string().when('program', {
      is: 'C',
      then: () => Yup.string().required('Please list specific program or process'),
    }),
  }),
});

export default SelfOtherInUsInitParoleSchema;
