import React from 'react';
import { useFormikContext } from 'formik';
import { Box } from '@mui/material';
import { BaseCheckboxGroup, CustomRadio, ExplanationBox } from '@src/components';
import { I131Schema } from '@src/utils/I131.schema';

const reasonForReplacementOptions = [
  { value: 'notReceive', label: 'My document was issued, but I did not receive it.' },
  { value: 'stolenOrDamage', label: 'I received my document, but then it was lost, stolen, or damaged.' },
  {
    value: 'informationErrorCausedByMe',
    label:
      'I received my document, but it has incorrect information because of an error caused by me or because my information has changed.',
  },
  {
    value: 'informationErrorCausedByUSCIS',
    label:
      'I received my document, but it has incorrect information because of an error not caused by me (such as a U.S. Citizenship and Immigration Services (USCIS) error).',
  },
];

const validInfoCorrectionValue = [
  { label: 'Name', value: 'name' },
  { label: 'Alien Number', value: 'aNumber' },
  { label: 'Country of Birth', value: 'countryOfBirth' },
  { label: 'Terms and Conditions', value: 'termsAndConditions' },
  { label: 'Date of Birth', value: 'dateOfBirth' },
  { label: 'Gender', value: 'gender' },
  { label: 'Validity Date', value: 'validityDate' },
  { label: 'Photo', value: 'photo' },
];

const RequestReplacement: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<I131Schema>();

  const handleRequestReplacementChange = (value: string): void => {
    switch (value) {
      case 'No':
        setFieldValue('processingInfo.reasonForReplacement', '');
        setFieldValue('processingInfo.infoCorrection', '');
        setFieldValue('processingInfo.infoCorrectionExplanation', '');
        break;
    }
  };

  const handleReasonForReplacementChange = (value: string): void => {
    switch (value) {
      case 'notReceive':
      case 'stolenOrDamage':
      case 'informationErrorCausedByMe':
        setFieldValue('processingInfo.infoCorrection', '');
        setFieldValue('processingInfo.infoCorrectionExplanation', '');
        break;
    }
  };

  return (
    <>
      <CustomRadio
        question="Are you requesting a replacement Reentry Permit, Refugee Travel Document, Advance Parole Document, or TPS Travel Authorization Document?"
        fieldName="processingInfo.requestReplacement"
        onChange={handleRequestReplacementChange}
      />
      {values.processingInfo?.requestReplacement === 'Yes' && (
        <CustomRadio
          question="Why are you requesting a replacement?"
          fieldName="processingInfo.reasonForReplacement"
          options={reasonForReplacementOptions}
          onChange={handleReasonForReplacementChange}
        />
      )}
      {values.processingInfo?.reasonForReplacement === 'informationErrorCausedByUSCIS' && (
        <Box>
          <BaseCheckboxGroup
            name="processingInfo.infoCorrection"
            question="Please select the applicable box(es) indicating the information that needs to be corrected and then provide any additional information in the text box that helps USCIS confirm the correction needed."
            options={validInfoCorrectionValue}
          />
          <ExplanationBox
            name="processingInfo.infoCorrectionExplanation"
            question="Provide an explanation of what is incorrect on your current document to support your request for a correction and attach copies of any documents supporting your request."
          />
        </Box>
      )}
    </>
  );
};

export default RequestReplacement;
