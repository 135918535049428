import { createBrowserRouter } from 'react-router-dom';
import { LandingPage, LoginPage, ThemeShowcase, SubmissionConfirmation } from '@src/pages';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import RootLayout from '../../RootLayout';
import { FileAForm } from '@src/containers';
import { Suspense } from 'react';
import FormFactoryLoader from '@src/containers/FormFactory/FormFactoryLoader';
import { QueryClient } from '@tanstack/react-query';
import { clearFormState } from '@src/utils/formStateUtils';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';

const isDevelopment = process.env.NODE_ENV === 'development';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    loader: (): null => {
      const queryClient = new QueryClient();
      clearFormState(queryClient);
      return null;
    },
    children: [
      {
        index: true,
        element: <LandingPage />,
      },
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'file-a-form',
        element: <FileAForm />,
      },
      {
        path: 'form',
        element: (
          <PrivateRoute>
            <Suspense
              fallback={
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minHeight="200px"
                >
                  <CircularProgress />
                </Box>
              }
            >
              <FormFactoryLoader />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: 'submission-confirmation',
        element: <SubmissionConfirmation />,
      },
      ...(isDevelopment
        ? [
            {
              path: 'showcase',
              element: <ThemeShowcase />,
            },
          ]
        : []),
    ],
  },
]);

export default router;
