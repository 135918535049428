import { checkDate } from '@src/utils/pureFunctions';
import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type I94ExpirationDateSchemaType = Partial<I131FormValues> & {
  applicantInfo: {
    expirationDate: string;
  };
};

const I94ExpirationDateSchema = createPartialSchema<I94ExpirationDateSchemaType>({
  applicantInfo: Yup.object({
    expirationDate: Yup.string()
      .default('')
      .test('validateDate', 'Invalid date format', (value: string) => checkDate(value)),
  }),
});

export default I94ExpirationDateSchema;
