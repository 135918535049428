import * as React from 'react';
import { TextFieldWithQuestion } from '@components';

const TheirClassOfAdmission: React.FC = (): JSX.Element => {
  return (
    <TextFieldWithQuestion
      name={'infoAboutThem.classOfAdmission'}
      question="What is their class of admission (COA)? (if any)"
    />
  );
};

export default TheirClassOfAdmission;
