import { ReactElement } from 'react';
import BooleanRadioWithExplanationBox from '@components/BooleanRadioWithExplanationBox/BooleanRadioWithExplanationBox';

export const REACQUIRED_NATIONALITY = {
  REACQUIRED_NATIONALITY: {
    fieldKey: 'refugeeTravelDocumentInfo.reacquiredNationality',
    question:
      'Since you were accorded refugee/asylee status, have you, by any legal procedure or voluntary act reacquired the nationality of the country?',
  },
  REACQUIRED_NATIONALITY_EXPLANATION: {
    fieldKey: 'refugeeTravelDocumentInfo.reacquiredNationalityExplanation',
    summaryKey: 'Explanation: ',
  },
} as const;

const ReacquiredNationality = (): ReactElement => {
  return (
    <BooleanRadioWithExplanationBox
      question={REACQUIRED_NATIONALITY.REACQUIRED_NATIONALITY.question}
      fieldName={REACQUIRED_NATIONALITY.REACQUIRED_NATIONALITY.fieldKey}
    />
  );
};

export default ReacquiredNationality;
