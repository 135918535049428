import * as React from 'react';
import { ReactElement } from 'react';
import FlexibleDropdown from '@src/components/FlexibleDropdown';

const hairColorOptions = [
  { value: 'Bald', label: 'Bald' },
  { value: 'Black', label: 'Black' },
  { value: 'Blond', label: 'Blond' },
  { value: 'Brown', label: 'Brown' },
  { value: 'Gray', label: 'Gray' },
  { value: 'Red', label: 'Red' },
  { value: 'Sandy', label: 'Sandy' },
  { value: 'White', label: 'White' },
  { value: 'Unknown', label: 'Unknown' },
];

const HairColor: React.FC = (): ReactElement => {
  return (
    <FlexibleDropdown
      leftName="biographicInfo.hairColor"
      leftLabel="Hair Color"
      question="What is the hair color of the person who will receive the document?"
      leftOptions={hairColorOptions}
      testId="hair-color-dropdown"
    />
  );
};

export default HairColor;
