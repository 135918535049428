import * as React from 'react';
import { CustomRadio, TextFieldWithQuestion } from '@src/components';

const numberOfTripsOptions = [
  { label: 'One Trip', value: 'oneTrip' },
  { label: 'More than one trip', value: 'moreThanOneTrip' },
];

const AdvancedParoleTrips: React.FC = () => {
  return (
    <>
      <CustomRadio
        fieldName={'proposedTravel.numberOfTrips'}
        question={'How many trips do you intend to use this document?'}
        options={numberOfTripsOptions}
      />
      <TextFieldWithQuestion
        name={'proposedTravel.expectedLength'}
        question="Expected Length of Trip"
        label="Days"
      />
    </>
  );
};

export default AdvancedParoleTrips;
