import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import { BooleanSchema, booleanSchema } from '@src/utils/I131.schema';
import * as Yup from 'yup';

type AcquiredNewNationalitySchemaType = Partial<I131FormValues> & {
  refugeeTravelDocumentInfo: {
    acquiredNewNationality: BooleanSchema;
    acquiredNewNationalityExplanation: string;
  };
};

const acquiredNewNationalitySchema = createPartialSchema<AcquiredNewNationalitySchemaType>({
  refugeeTravelDocumentInfo: Yup.object({
    acquiredNewNationality: booleanSchema,
    acquiredNewNationalityExplanation: Yup.string().default(''),
  }),
});

export default acquiredNewNationalitySchema;
