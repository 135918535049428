import * as React from 'react';
import { ReactElement } from 'react';
import { Field, FieldProps } from 'formik';
import { FormControl, TextField, Typography } from '@mui/material';
import { QuestionWithInstruction } from '@src/components';

const TheirDateOfBirth: React.FC = () => {
  return (
    <Field name="infoAboutThem.dateOfBirth">
      {({ field, meta }: FieldProps): ReactElement => (
        <FormControl
          fullWidth
          error={meta.touched && !!meta.error}
        >
          <QuestionWithInstruction question={'What is their date of birth?'} />
          <TextField
            {...field}
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              max: new Date().toISOString().split('T')[0], // Today's date as minimum
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#E0E0E0',
                },
                '&:hover fieldset': {
                  borderColor: '#BDBDBD',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'primary.main',
                },
              },
              '& .MuiInputBase-input': {
                padding: '12px 16px',
              },
            }}
          />

          {meta.touched && meta.error && (
            <Typography
              color="error"
              variant="caption"
              sx={{ mt: 1 }}
            >
              {meta.error}
            </Typography>
          )}
        </FormControl>
      )}
    </Field>
  );
};

export default TheirDateOfBirth;
