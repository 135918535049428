import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import { BooleanSchema, booleanSchema } from '@src/utils/I131.schema';
import * as Yup from 'yup';

type AppliedForPassportSchemaType = Partial<I131FormValues> & {
  refugeeTravelDocumentInfo: {
    appliedForPassport: BooleanSchema;
    appliedForPassportExplanation: string;
  };
};

const appliedForPassportSchema = createPartialSchema<AppliedForPassportSchemaType>({
  refugeeTravelDocumentInfo: Yup.object({
    appliedForPassport: booleanSchema,
    appliedForPassportExplanation: Yup.string().default(''),
  }),
});

export default appliedForPassportSchema;
