import { ReactElement } from 'react';
import { Field, FieldProps } from 'formik';
import { Box, TextField, Typography } from '@mui/material';

interface HelperNameProps {
  question: string;
  familyNamePath: string;
  givenNamePath: string;
  businessPath: string;
  familyNameLabel: string;
  givenNameLabel: string;
  businessLabel: string;
}

const HelperName = ({
  question,
  familyNamePath,
  givenNamePath,
  businessPath,
  familyNameLabel,
  givenNameLabel,
  businessLabel,
}: HelperNameProps): ReactElement => {
  return (
    <Box sx={{ maxWidth: '800px', mx: 'auto', p: 3 }}>
      <Typography
        variant="h5"
        component="h2"
        gutterBottom
        sx={{
          color: 'rgba(0, 0, 0, 0.87)',
          mb: 3,
        }}
      >
        {question}
      </Typography>

      <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
        {/* Family Name Field */}
        <Box sx={{ flex: 1 }}>
          <div className="input-field-container">
            <label
              htmlFor="family-name"
              className="input-label"
            >
              <Typography
                variant="body1"
                gutterBottom
                sx={{ color: 'rgba(0, 0, 0, 0.87)', mb: 1 }}
              >
                {familyNameLabel}
              </Typography>
            </label>
            <Field name={familyNamePath}>
              {({ field, meta }: FieldProps): ReactElement => (
                <TextField
                  {...field}
                  id="family-name"
                  fullWidth
                  variant="outlined"
                  error={meta.touched && Boolean(meta.error)}
                  helperText={meta.touched && meta.error}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: 'white',
                    },
                  }}
                />
              )}
            </Field>
          </div>
        </Box>

        {/* Given Name Field */}
        <Box sx={{ flex: 1 }}>
          <div className="input-field-container">
            <label
              htmlFor="given-name"
              className="input-label"
            >
              <Typography
                variant="body1"
                gutterBottom
                sx={{ color: 'rgba(0, 0, 0, 0.87)', mb: 1 }}
              >
                {givenNameLabel}
              </Typography>
            </label>
            <Field name={givenNamePath}>
              {({ field, meta }: FieldProps): ReactElement => (
                <TextField
                  {...field}
                  id="given-name"
                  fullWidth
                  variant="outlined"
                  error={meta.touched && Boolean(meta.error)}
                  helperText={meta.touched && meta.error}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: 'white',
                    },
                  }}
                />
              )}
            </Field>
          </div>
        </Box>
      </Box>

      {/* Business Name Field */}
      <Box>
        <div className="input-field-container">
          <label
            htmlFor="business-name"
            className="input-label"
          >
            <Typography
              variant="body1"
              gutterBottom
              sx={{ color: 'rgba(0, 0, 0, 0.87)', mb: 1 }}
            >
              {businessLabel}
            </Typography>
          </label>
          <Field name={businessPath}>
            {({ field, meta }: FieldProps): ReactElement => (
              <TextField
                {...field}
                id="business-name"
                fullWidth
                variant="outlined"
                error={meta.touched && Boolean(meta.error)}
                helperText={meta.touched && meta.error}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                  },
                }}
              />
            )}
          </Field>
        </div>
      </Box>
    </Box>
  );
};

export default HelperName;
