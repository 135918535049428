import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type YourNameSchemaType = Partial<I131FormValues> & {
  applicantInfo: {
    fullName: {
      givenName: string;
      middleName: string;
      familyName: string;
    };
  };
};

const yourNameSchema = createPartialSchema<YourNameSchemaType>({
  applicantInfo: Yup.object({
    fullName: Yup.object({
      givenName: Yup.string().trim().max(40, 'First name cannot exceed 40 characters'),
      middleName: Yup.string().trim().max(40, 'Middle name cannot exceed 40 characters'),
      familyName: Yup.string()
        .trim()
        .required('Family name is required')
        .max(40, 'Family name cannot exceed 40 characters'),
    }),
  }),
});

export default yourNameSchema;
