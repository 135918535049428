import { ReactElement, useEffect } from 'react';
import Address from '../../SharedSubSections/Address/Address';
import { useFormikContext } from 'formik';
import { CustomRadio } from '@components';
import { get } from 'lodash';

const TheirPhysicalAddress = (): ReactElement => {
  const { setFieldValue, values } = useFormikContext();

  const physicalAddressFormGroup = 'infoAboutThem.currentPhysicalAddress';
  const mailingAddressFormGroup = 'infoAboutThem.currentMailingAddress';
  const isPhysicalSameAsMailingPath = 'infoAboutThem.isPhysicalSameAsMailing';

  const isPhysicalAddressSameAsMailingAddress = get(values, isPhysicalSameAsMailingPath) === 'Yes';
  const isPhysicalAddressNotSameAsMailingAddress = get(values, isPhysicalSameAsMailingPath) === 'No';

  const mailingAddressValues = get(values, mailingAddressFormGroup);

  // Sets physical address to the mailing address if isPhysicalAddressSameAsMailingAddress is 'Yes' and clear it if its 'No'
  useEffect(() => {
    if (isPhysicalAddressSameAsMailingAddress) {
      setFieldValue(physicalAddressFormGroup, mailingAddressValues);
    } else {
      setFieldValue('applicantInfo.currentPhysicalAddress', {
        inCareOfName: '',
        street: '',
        unitType: '',
        unitNumber: '',
        cityOrTown: '',
        state: '',
        zipCode: '',
        province: '',
        postalCode: '',
        country: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPhysicalAddressSameAsMailingAddress]);

  return (
    <>
      <CustomRadio
        fieldName={isPhysicalSameAsMailingPath}
        question={'Is their mailing address the same as your physical address?'}
        required
      />
      {isPhysicalAddressNotSameAsMailingAddress && (
        <Address
          question={'What is their current physical address?'}
          inCareOfNamePath={`${physicalAddressFormGroup}.inCareOfName`}
          streetPath={`${physicalAddressFormGroup}.street`}
          unitNumberPath={`${physicalAddressFormGroup}.unitNumber`}
          cityOrTownPath={`${physicalAddressFormGroup}.cityOrTown`}
          statePath={`${physicalAddressFormGroup}.state`}
          zipCodePath={`${physicalAddressFormGroup}.zipCode`}
          provincePath={`${physicalAddressFormGroup}.province`}
          postalCodePath={`${physicalAddressFormGroup}.postalCode`}
          countryPath={`${physicalAddressFormGroup}.country`}
        />
      )}
    </>
  );
};

export default TheirPhysicalAddress;
