import * as React from 'react';
import CertificationSignature from '@src/containers/FormSubSections/SharedSubSections/CertificationSignature/CertificationSignature';

const PreparerCertification: React.FC = () => {
  return (
    <CertificationSignature
      question="Preparer's Certification and Signature"
      instructions="I certify, under penalty of perjury, that I prepared this application for the applicant at their request and with express consent and that all the responses and information contained in and submitted with the application are complete, true, and correct and reflects only information provided by the applicant. The applicant reviewed the responses and information and informed me that they understand the responses and information in or submitted with the application."
      signatureLabel="Preparer's Signature"
      signaturePath="preparerContactAndCertification.signature"
      datePath="preparerContactAndCertification.dateOfSignature"
    />
  );
};

export default PreparerCertification;
