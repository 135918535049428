import { CheckType } from '@src/types/utils.types';

/**
 * Ensure the return value to be either boolean or undefined
 * @param val a value to be converted
 * @param replaceValue matcher for true or false value
 * @returns undefined if value does not exist or not match the replaceValue, or return boolean
 */
export function booleanOrUndefined(
  val: null | undefined | string | boolean,
  replaceValue: { [key: string]: boolean } = { Yes: true, No: false }
): boolean | undefined {
  if (val === undefined || val === null || val === '') return undefined;
  if (typeof val === 'boolean') return val;

  const result = replaceValue[val];
  return result === undefined ? undefined : replaceValue[val];
}

/**
 * Validate a given val date string using given rules.
 * @param val a string to be evaluated
 * @param allowFutureDate flag to allow future date if true (default)
 * @param allowPastDate flag to allow past date if true (default)
 * @returns true if the given val is a valid date under the given conditions
 */
export function checkDate(val: string, allowFutureDate = true, allowPastDate = true): boolean {
  if (!val) return true;

  const aDate = new Date(val);
  if (isNaN(aDate.getFullYear())) return false;
  if (allowFutureDate && allowPastDate) return true;

  const today = new Date(Date.now());
  today.setHours(0, 0, 0, 0);

  if (!allowFutureDate && !allowPastDate) {
    return (
      aDate.getDate() === today.getDate() &&
      aDate.getMonth() === today.getMonth() &&
      aDate.getFullYear() === today.getFullYear()
    );
  }
  if (allowFutureDate) return aDate >= today;
  return aDate <= today;
}

/**
 * https://en.wikipedia.org/wiki/Email_address
 * The current implementation doesn't support email "comment" or IP address domain
 * @param value an email address string to be validated
 * @returns boolean after the validation
 */
export const emailValidation = (value: string): boolean => {
  if (!value) return true;

  const strArr = value.split('@');
  if (strArr.length !== 2) return false; // need local + domain parts

  // test local part
  if (
    strArr[0].match(/^\.|\.$/g) !== null || // local part can't start or end with period
    strArr[0].length > 96 || // max length is 64 octets (96 characters)
    strArr[0].match(/[^\w\d#$%&='*+/?^!`{|}~.-]+/) !== null // contain invalid character(s)
  )
    return false;

  // test domain part
  if (
    // domain part must start and end with alphanumeric
    strArr[1].match(/^[a-zA-Z0-9]/) === null ||
    strArr[1].match(/[a-zA-Z0-9]$/) === null ||
    strArr[1].split('.').length < 2 || // need contains at least a dot
    strArr[1].length > 63 || // maximum length
    strArr[1].match(/[^A-Za-z0-9.-]+/) !== null // contain invalid character(s)
  )
    return false;
  return true; // passed validation
};

/**
 * Ensure the return value is an array type
 * @param val value to be converted
 * @returns incoming val if exists, or an empty array
 */
export function ensureArray<T>(val: T[] | undefined): T[] {
  return (val || []) as T[];
}

/**
 * Ensure that the value is a string type
 * @param val value to be converted
 * @returns empty string if the value does not exist, or the given value
 */
export function ensureString(val: null | undefined | string): string {
  return val || '';
}

/**
 * Return the given error if value exists; otherwise, return undefined
 * @param val a flag/value for error determination
 * @param err an intended error message
 * @returns the given error message or undefined
 */
export const getErrorMsg = (val: string | number | boolean | undefined, err: string | undefined): string | undefined =>
  val ? err : undefined;

/**
 * Check if a field has an error
 * @param checks an array of elements to be check they are all true
 * @returns true if every element in the given checks array exists and true
 */
export const hasFieldError = (checks: CheckType[]): boolean => {
  return checks.every((elem) => !!elem);
};

/**
 * Validate an incoming value to ensure that it contains only valid characters
 * @param value a string to be validated
 * @returns boolean after validation
 */
export const nameCharacterValidation = (value: string): boolean => !value || value.match(/^[a-zA-Z'-]+$/) !== null;

/**
 * A no-operation function that can be used as a default callback
 * Useful for providing default props or handling optional callbacks
 * @example
 * const handleClick = onClickCallback ?? noOp;
 */
export const noOp = (): void => {
  // Intentionally empty function for use as a default no-operation callback
  // Used when a function is required but no operation needs to be performed
};

/**
 * Ensure the value is type of number or undefined
 * @param val a value to be converted
 * @returns a number if the incoming value is a number, or undefined otherwise
 */
export function numberOrUndefined(val: null | undefined | string | number): number | undefined {
  if (val === undefined || val === null) return undefined;
  if (typeof val === 'number') return val;

  const result = parseInt(val, 10);
  return Number.isNaN(result) ? undefined : result;
}

/**
 * Mutate the incoming value by cleaning out any empty value
 * @param value value to be sanitized
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sanitizeData(value: any): void {
  if (typeof value === 'object' && value && Object.keys(value).length > 0) {
    const keys = Object.keys(value);
    keys.forEach((key) => {
      if (
        value[key] === '' ||
        value[key] === undefined ||
        value[key] === null ||
        (typeof value[key] === 'object' && (!value || !Object.keys(value).length))
      ) {
        delete value[key];
      } else sanitizeData(value[key]);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (typeof value[key] === 'object' && !Object.keys(value[key]).length) delete value[key];
    });
  }
  if (typeof value === 'object' && value && !Object.keys(value).length) value = {};
}

export function capitalizeFirstCharacter(value: string | undefined): string {
  if (!value) return '';

  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function validateANumberOrSsn(value: string | undefined): boolean {
  if (!value) return true;

  return value.replace(/-/g, '').match(/^\d{9}$/) !== null;
}

export function validateUOAN(value: string | undefined): boolean {
  if (!value) return true;

  return value.replace(/-/g, '').match(/^\d{12}$/) !== null;
}

export function validatePhoneNumber(value: string | undefined): boolean {
  if (!value) return true;

  return value.replace(/[-()\s]/g, '').match(/^\d{10}$/) !== null;
}
