import * as React from 'react';
import { TextFieldWithQuestion } from '@components';

const Anumber: React.FC = () => {
  return (
    <TextFieldWithQuestion
      question="What is your A-Number? (if any)"
      instructions="Your A-Number is located on your Permanent Resident Card (formerly known as the Alien Registration Card or referred to as the Green Card), and consists of a 7, 8, or 9-digit number.  The A-Number may be located on the front or back the card, depending on when the card was issued."
      helperText="Provide a 7, 8, or 9-digit number. If the A-Number is fewer than 9 digits, the system will automatically add zero(s) after the “A” and before the first digit so there is a total of 9 digits, for example: A-001234567."
      name={'applicantInfo.alienRegistrationNumber'}
    />
  );
};

export default Anumber;
