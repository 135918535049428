import React, { useMemo } from 'react';
import { Box, Typography, Paper, Grid, Divider, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { FormSectionComponentProps, I131FormValues } from '@src/types/form.types';
import { generateFields } from './utils';
import { FieldDisplay, SectionData } from './ReviewAndSubmit.types';

/* istanbul ignore next - This should come in from the config, but leaving here for now. */
function normalizeString(str: string): string {
  return str.replace(/Lpr/gi, 'LRP').replace(/\b\w*Us\b/gi, (match) => match.replace('Us', 'US'));
}

/* istanbul ignore next - Same as above */
// Probably should stick this in the config, rather than here which should be generic
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FORM_VALUE_TO_DISPLAY: Record<string, (v: any) => string> = {
  height: (value: I131FormValues['biographicInfo']['height']) => {
    const feet = value.feet ? `${value.feet}'` : '';
    const inches = value.inches ? `${value.inches}"` : '';
    return [feet, inches].join('');
  },
  noticeSendTo: (value: I131FormValues['processingInfo']['noticeSendTo']) => {
    const address: string[] = [];
    return [value.selectDestination, address.join(',')].join(' ');
  },
  fullName: (value: I131FormValues['interpreterContactAndCertification']['fullName']) => {
    return [value.givenName, value.familyName].join(' ');
  },
};

/* istanbul ignore next - Same as above */
const formatValue = (value: unknown): string => {
  if (value === null || value === undefined || value === '') {
    return 'Not provided';
  }
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  if (Array.isArray(value)) {
    return (
      value
        .filter(Boolean)
        .map((item) => {
          if (typeof item === 'string') {
            return item.replace(/([A-Z])/g, ' $1').trim();
          }
          return item;
        })
        .join(', ') || 'Not provided'
    );
  }
  if (typeof value === 'object') {
    if ('feet' in value && 'inches' in value) {
      return `${value.feet}'${value.inches}"`;
    }

    const objValues = Object.values(value).filter(Boolean);

    if (objValues.length === 0) return 'Not provided';
    return objValues.join(' ');
  }
  return String(value);
};

interface ReviewSectionProps {
  section: SectionData;
  onEdit: (sectionId: string, subSectionId: string) => void;
  className?: string;
}

const ReviewSection: React.FC<ReviewSectionProps> = ({ section, onEdit, className = '' }: ReviewSectionProps) => {
  return (
    <Paper
      className={className}
      sx={{ p: 3, mb: 2 }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">{section.title}</Typography>
      </Box>
      <Divider sx={{ mb: 2 }} />
      <Grid
        container
        spacing={2}
      >
        {section.fields.map((field, index) => (
          <React.Fragment key={`${field.label}-${index}`}>
            <Grid
              item
              xs={12}
              sm={5}
            >
              <Typography
                variant="subtitle2"
                color="text.secondary"
              >
                {field.label}
              </Typography>
            </Grid>
            <Grid
              item
              xs={10}
              sm={6}
            >
              <Typography variant="body2">{formatValue(field.value)}</Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sm={1}
            >
              <IconButton
                size="small"
                onClick={(): void => onEdit(field.sectionId, field.subSectionId)}
                sx={{ ml: 1 }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Paper>
  );
};

function ReviewAndSubmit({ formikProps }: FormSectionComponentProps<I131FormValues>): React.ReactNode {
  const { values, configData } = formikProps;

  const handleEdit = (sectionId: string, subSectionId: string): void => {
    const currentExpandedSections = formikProps.expandedSections || {};

    // Create new expanded sections state with all sections collapsed except target
    const newExpandedSections = Object.keys(currentExpandedSections).reduce(
      (acc, key) => ({
        ...acc,
        [key]: key === sectionId,
      }),
      {
        [sectionId]: true,
      }
    );

    formikProps.setExpandedSections(newExpandedSections);
    formikProps.handleSectionClick(sectionId);
    formikProps.handleSubSectionClick(sectionId, subSectionId);
  };

  const sections = useMemo(() => {
    const sections: SectionData[] = [];

    for (const section of configData.sections) {
      const isHidden = typeof section.hidden === 'function' ? section.hidden(values) : section.hidden;
      if (isHidden || !section.subSections || section.id === 'review') continue;

      const fields: FieldDisplay[] = [];

      for (const ss of section.subSections) {
        const isHidden = typeof ss.hidden === 'function' ? ss.hidden(values) : ss.hidden;
        if (isHidden || !ss.validation?.fields) continue;

        const fields_ = generateFields(ss.validation.fields, values, {
          sectionId: section.id,
          subSectionId: ss.id,
          normalizeStringFn: normalizeString,
          valueToStringFns: FORM_VALUE_TO_DISPLAY,
        });
        fields.push(...fields_);
      }

      sections.push({
        title: section.menuTitle,
        sectionId: section.id,
        fields,
      });
    }

    return sections;
  }, [configData, values]);

  return (
    <Box sx={{ maxWidth: '4xl', mx: 'auto', py: 4 }}>
      <Typography
        variant="h4"
        sx={{ mb: 4, textAlign: 'center' }}
      >
        Review Your Application
      </Typography>

      {sections.map((section: SectionData, index: number) => (
        <ReviewSection
          key={`${section.sectionId}-${index}`}
          section={section}
          onEdit={handleEdit}
        />
      ))}
    </Box>
  );
}

export default ReviewAndSubmit;
