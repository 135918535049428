import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type TheirCountryOfBirthSchemaType = Partial<I131FormValues> & {
  infoAboutThem: {
    countryOfBirth: string;
  };
};

const TheirCountryOfBirthSchema = createPartialSchema<TheirCountryOfBirthSchemaType>({
  infoAboutThem: Yup.object({
    countryOfBirth: Yup.string().default(''),
  }),
});

export default TheirCountryOfBirthSchema;
