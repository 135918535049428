import { ReactElement } from 'react';
import { Field, FieldProps } from 'formik';
import { Box, TextField, Typography } from '@mui/material';
import QuestionWithInstruction from '@components/QuestionWithInstruction';

interface FullNameProps {
  question?: string;
  givenNamePath: string;
  middleNamePath: string;
  familyNamePath: string;
  givenNameLabel?: string;
  middleNameLabel?: string;
  familyNameLabel?: string;
}

const FullName = ({
  question,
  givenNamePath,
  middleNamePath,
  familyNamePath,
  givenNameLabel = 'Given name (First Name)',
  middleNameLabel = 'Middle name (if applicable)',
  familyNameLabel = 'Family name (Last Name)',
}: FullNameProps): ReactElement => {
  return (
    <Box sx={{ maxWidth: '800px', mx: 'auto', p: 3 }}>
      {question && <QuestionWithInstruction question={question} />}
      {/* First and Middle Name Row */}
      <Box sx={{ display: 'flex', gap: 3, mb: 3 }}>
        {/* First Name Field */}
        <Box sx={{ flex: 1 }}>
          <Typography
            component="label"
            htmlFor="first-name"
            sx={{
              display: 'block',
              mb: 1,
              color: 'rgba(0, 0, 0, 0.87)',
              fontSize: '1rem',
              fontWeight: 400,
            }}
          >
            {givenNameLabel}
          </Typography>
          <Field name={givenNamePath}>
            {({ field, meta }: FieldProps): ReactElement => (
              <TextField
                {...field}
                id="first-name"
                fullWidth
                variant="outlined"
                error={meta.touched && Boolean(meta.error)}
                helperText={meta.touched && meta.error}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                  },
                }}
              />
            )}
          </Field>
        </Box>

        {/* Middle Name Field */}
        <Box sx={{ flex: 1 }}>
          <Typography
            component="label"
            htmlFor="middle-name"
            sx={{
              display: 'block',
              mb: 1,
              color: 'rgba(0, 0, 0, 0.87)',
              fontSize: '1rem',
              fontWeight: 400,
            }}
          >
            {middleNameLabel}
          </Typography>
          <Field name={middleNamePath}>
            {({ field, meta }: FieldProps): ReactElement => (
              <TextField
                {...field}
                id="middle-name"
                fullWidth
                variant="outlined"
                error={meta.touched && Boolean(meta.error)}
                helperText={meta.touched && meta.error}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                  },
                }}
              />
            )}
          </Field>
        </Box>
      </Box>

      {/* Last Name Field */}
      <Box sx={{ mb: 3 }}>
        <Typography
          component="label"
          htmlFor="last-name"
          sx={{
            display: 'block',
            mb: 1,
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: '1rem',
            fontWeight: 400,
          }}
        >
          {familyNameLabel}
        </Typography>
        <Field name={familyNamePath}>
          {({ field, meta }: FieldProps): ReactElement => {
            return (
              <TextField
                {...field}
                id="last-name"
                fullWidth
                variant="outlined"
                error={meta.touched && Boolean(meta.error)}
                helperText={meta.touched && meta.error}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                  },
                }}
              />
            );
          }}
        </Field>
      </Box>
    </Box>
  );
};

export default FullName;
