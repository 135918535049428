import * as Yup from 'yup';
import { createPartialSchema, I131FormValues } from '@src/types/form.types';

// Outside Initial Parole Schema Type
type OutsideInitialParoleSchemaType = Partial<I131FormValues> & {
  outsideUsInitParoleDocument: {
    program: string;
    forPerson: string;
    receiptNumber: string;
    govExecBranch: string;
    repEmailAddress: string;
    otherProgram: string;
  };
};

const outsideInitialParoleSchema = createPartialSchema<OutsideInitialParoleSchemaType>({
  outsideUsInitParoleDocument: Yup.object({
    program: Yup.string().required('Please select a program'),
    forPerson: Yup.string().when('program', {
      is: 'B',
      then: () => Yup.string().required('Please select a category'),
    }),
    receiptNumber: Yup.string().when('program', {
      is: (value: string) => ['A', 'D'].includes(value),
      then: () => Yup.string().required('Receipt number is required'),
    }),
    govExecBranch: Yup.string().when('program', {
      is: 'C',
      then: () => Yup.string().required('U.S. Federal Executive Branch Government Agency is required'),
    }),
    repEmailAddress: Yup.string().when('program', {
      is: 'C',
      then: () =>
        Yup.string()
          .email('Invalid email address')
          .required('U.S. Federal Government Agency Representative Official Email Address is required'),
    }),
    otherProgram: Yup.string().when('program', {
      is: 'E',
      then: () => Yup.string().required('Please list specific parole program or process'),
    }),
  }),
});

export default outsideInitialParoleSchema;
