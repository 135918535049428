import React, { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const RequiredFieldsMessage = (): ReactElement => {
  const theme = useTheme();

  return (
    <Box sx={{ mb: 4, width: '100%' }}>
      <Typography
        component="h2"
        sx={{
          fontSize: {
            xs: theme.typography.h6.fontSize,
            md: theme.typography.h5.fontSize,
          },
          fontWeight: theme.typography.fontWeightRegular,
          color: 'grey.600',
          lineHeight: 1.4,
          '& .required-asterisk': {
            color: 'error.main',
            fontWeight: 'bold',
          },
        }}
      >
        You must complete all fields with an asterisk (<span className="required-asterisk">*</span>) to submit this
        form.
      </Typography>
    </Box>
  );
};

export default RequiredFieldsMessage;
