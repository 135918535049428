import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import axios from 'axios';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormContent } from './FormContent/FormContent';
import { useFormData, useSaveFormData } from '@src/services/formDataService';
import {
  BaseFormValues,
  DEFAULT_FORM_VALUES,
  DraftLoadType,
  FormConfig,
  FormDataError,
  isI131FormValues,
  NavigationCheck,
  PreviousCheck,
  SaveFormDataMutationOptions,
  Section,
  SubSection,
} from '@src/types/form.types';
import { FormState } from '@src/types/hooks.types';
import { prefixUrl } from '@src/hooks/useDraftHooks/draftUrl.constant';
import { useQueryClient } from '@tanstack/react-query';
import { useFormInitialization } from '@src/hooks/useFormHooks/useFormInitialization';
import { useBeforeUnloadSave } from '@src/hooks/useFormHooks/useBeforeUnloadSave';
import useLoadDraft from '@src/hooks/useDraftHooks/useLoadDraft';
import useCreateDraft from '@src/hooks/useDraftHooks/useCreateDraft';
import useSaveDraft from '@src/hooks/useDraftHooks/useSaveDraft';

import { clearFormState } from '@src/utils/formStateUtils';
import { I131_SAMPLE_YUP_DATA } from '@src/utils/I131SampleData';

type FormStateUpdater<T extends BaseFormValues> = (prevState: FormState<T>) => FormState<T>;

export const FORM_QUERY_KEY = 'form-data';

// const FormFactory: React.FC<FormFactoryProps> = () => {
function FormFactory<T extends BaseFormValues>(): React.ReactNode {
  const formikRef = useRef<FormikProps<T> | null>(null);
  const saveFormConfig = useRef<FormConfig<T> | undefined>(undefined);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location as { state: { formType: string } };
  const queryClient = useQueryClient();
  const mountedRef = useRef(true);
  const initializeStartedRef = useRef(false);
  const draftLoadedRef = useRef(false);
  const formRefId = useRef<number>(0);
  const [aId, setAId] = useState<string | number | undefined>(undefined);

  // Make form state updates safe
  const safeSetState = useCallback((updater: FormStateUpdater<T>): FormState<T> | undefined => {
    if (mountedRef.current) {
      // Check if we're still mounted
      return updater({} as FormState<T>);
    }
    return undefined;
  }, []);

  const {
    formConfig,
    selectedSection,
    setSelectedSection,
    selectedSubSection,
    setSelectedSubSection,
    expandedSections,
    setExpandedSections,
    unlockedSections,
    setUnlockedSections,
  } = useFormInitialization<T>(state?.formType, safeSetState);

  const formId = useMemo(() => state?.formType || '', [state?.formType]);
  const fullDraftData = useLoadDraft<DraftLoadType, T>(formId, formConfig, formRefId);
  const draftId = useCreateDraft({ ...DEFAULT_FORM_VALUES }, formConfig, formRefId);

  if (draftId > 0 && !formRefId.current) formRefId.current = draftId;

  const saveDraft = useSaveDraft(formikRef, formRefId, saveFormConfig);

  useEffect(() => {
    if (formConfig) saveFormConfig.current = formConfig;
  }, [formConfig]);

  useEffect(() => {
    if (fullDraftData?.id) {
      setAId(fullDraftData.id);
      formRefId.current = fullDraftData.id;
    }
  }, [fullDraftData, setAId]);

  useEffect(() => {
    if (!formId || draftLoadedRef.current || !(fullDraftData?.id || draftId)) return;

    if (fullDraftData?.formData) {
      // Draft exists, update form data
      queryClient.setQueryData(['form-data', formId], {
        id: fullDraftData.id,
        values: { ...DEFAULT_FORM_VALUES, ...fullDraftData.formData },
        formType: state?.formType || '',
        // lastUpdated: new Date().toISOString(),
      });
      draftLoadedRef.current = true;
    } else if (draftId) {
      // New draft was created
      queryClient.setQueryData(['form-data', formId], {
        id: draftId,
        values: {
          ...DEFAULT_FORM_VALUES,
          formType: formId,
          // lastUpdated: new Date().toISOString(),
        },
        formType: state?.formType || '',
        // lastUpdated: new Date().toISOString(),
      });
      draftLoadedRef.current = true;
    }
  }, [formId, fullDraftData?.formData, fullDraftData?.id, draftId, queryClient, state?.formType]);

  // Must be effect at the top, before other effects
  useEffect(() => {
    mountedRef.current = true;
    initializeStartedRef.current = false;

    // Clear on mount to ensure fresh start
    if (formId) {
      clearFormState(queryClient, formId);
    }

    const cleanup = (): void => {
      mountedRef.current = false;

      // Only log and cleanup if we actually started initialization
      if (initializeStartedRef.current) {
        console.log('FormFactory unmounting:', {
          currentLocation: location,
          formConfig,
          selectedSection,
          selectedSubSection,
        });

        if (formId) {
          clearFormState(queryClient, formId);
        }
      }
    };

    return cleanup;
    // empty dependency array means this useEffect is called on load only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This effect must be after useFormInitialization
  useEffect(() => {
    if (formConfig) {
      initializeStartedRef.current = true;
    }
  }, [formConfig]);

  // Local error boundary
  useEffect(() => {
    const handleError = (event: ErrorEvent): void => {
      console.error('FormFactory caught error:', {
        error: event.error,
        message: event.message,
        // Log relevant state
        state: {
          location,
          formConfig,
          selectedSection,
          selectedSubSection,
          previousValues: null, // previousValuesRef.current,
        },
      });
    };

    window.addEventListener('error', handleError);
    return () => window.removeEventListener('error', handleError);
  }, [location, formConfig, selectedSection, selectedSubSection]);

  useEffect(() => {
    const currentPath = location.pathname;

    return () => {
      if (currentPath.includes('/form') && mountedRef.current) {
        // Clear the form data only when leaving the form entirely
        if (formConfig?.formId) {
          queryClient.removeQueries({
            queryKey: ['form-data', formConfig.formId],
            exact: true,
          });

          // Also clear any related data
          queryClient.removeQueries({
            predicate: (query) => Array.isArray(query.queryKey) && query.queryKey[0] === 'form-data',
          });
        }
      }
    };
  }, [location.pathname, formConfig?.formId, queryClient]);

  const checkNextSubSection = useCallback(
    (currentSectionId: string, currentSubSectionId: string, values: T): NavigationCheck => {
      if (!formConfig) return { hasNext: false };

      // Find current section and its index
      const currentSectionIndex = formConfig.sections.findIndex((s) => s.id === currentSectionId);
      const currentSection = formConfig.sections[currentSectionIndex];

      if (!currentSection?.subSections) return { hasNext: false };

      // Find current subsection index
      const currentSubSection = currentSubSectionId.replace(`${currentSectionId}-`, '');
      const visibleSubSections = currentSection.subSections.filter(
        (ss) => !(typeof ss.hidden === 'function' ? ss.hidden(values) : ss.hidden)
      );
      const currentIndex = visibleSubSections.findIndex((ss) => ss.id === currentSubSection);

      // Check if there are more visible subsections in current section
      if (currentIndex < visibleSubSections.length - 1) {
        return { hasNext: true };
      }

      // Check next sections for visible subsections
      for (let i = currentSectionIndex + 1; i < formConfig.sections.length; i++) {
        const nextSection = formConfig.sections[i];
        // Check if section is hidden
        const isSectionHidden =
          typeof nextSection.hidden === 'function' ? nextSection.hidden(values) : !!nextSection.hidden;

        if (!isSectionHidden) {
          const hasVisibleSubSections = nextSection.subSections?.some(
            (ss) => !(typeof ss.hidden === 'function' ? ss.hidden(values) : ss.hidden)
          );

          if (hasVisibleSubSections) {
            return {
              hasNext: true,
              nextSection: nextSection.id,
            };
          }
        }
      }

      // If we get here, there are no more visible sections/subsections
      return { hasNext: false };
    },
    [formConfig]
  );

  const checkPreviousSubSection = useCallback(
    (currentSectionId: string, currentSubSectionId: string, values: T): PreviousCheck => {
      if (!formConfig) return { hasPrevious: false };

      // TODO(myles): Extract to form-based file, rather than in `FormFactory`
      if (isI131FormValues(values)) {
        // Special case for i94-info
        if (currentSubSectionId.includes('i94-info') && values.applicationType === '11') {
          return {
            hasPrevious: true,
            shouldUpdateApplicationType: true,
            newApplicationType: '10',
          };
        }
      }

      const currentSectionIndex = formConfig.sections.findIndex((s) => s.id === currentSectionId);
      const currentSection = formConfig.sections[currentSectionIndex];

      if (!currentSection?.subSections) return { hasPrevious: false };

      const currentIndex = currentSection.subSections.findIndex(
        (ss) => `${currentSectionId}-${ss.id}` === currentSubSectionId
      );

      // Check previous subsections in current section
      for (let i = currentIndex - 1; i >= 0; i--) {
        const subsection = currentSection.subSections[i];
        const isHidden = typeof subsection.hidden === 'function' ? subsection.hidden(values) : subsection.hidden;

        if (!isHidden) {
          return { hasPrevious: true };
        }
      }

      // Check previous sections
      for (let i = currentSectionIndex - 1; i >= 0; i--) {
        const prevSection = formConfig.sections[i];
        // Check if section is hidden
        const isSectionHidden =
          typeof prevSection.hidden === 'function' ? prevSection.hidden(values) : !!prevSection.hidden;

        if (!isSectionHidden && prevSection.subSections?.some((ss) => !ss.hidden)) {
          return { hasPrevious: true };
        }
      }

      return { hasPrevious: false };
    },
    [formConfig]
  );

  const clearHiddenValues = useCallback(
    (values: T): T => {
      const formik = formikRef.current;
      if (!formConfig || !formik) return values;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const result: Record<string, any> = values;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const defaults: Record<string, any> = DEFAULT_FORM_VALUES;

      // Need to dive into the fields in each section, to determine if a field is hidden and needs to be replaced.
      for (const section of formConfig.sections) {
        if (!section.subSections) continue;
        for (const subSection of section.subSections) {
          const isHidden = typeof subSection.hidden === 'function' ? subSection.hidden(values) : subSection.hidden;
          if (!isHidden || !subSection.validation) continue;

          for (const field in subSection.validation.fields) {
            result[field] = defaults[field];
          }
        }
      }

      return result as T;
    },
    [formConfig]
  );

  const getVisibleSubSections = useCallback((section: Section<T>, values: T): SubSection<T>[] => {
    return (
      section.subSections?.filter((subSection) => {
        const isHidden = typeof subSection.hidden === 'function' ? subSection.hidden(values) : subSection.hidden;
        return !isHidden;
      }) || []
    );
  }, []);

  const createVisibleSubSectionsMap = useCallback(
    (values: T): Record<string, SubSection<T>[]> => {
      if (!formConfig) return {};
      return formConfig.sections
        .filter((section) => {
          const isHidden = typeof section.hidden === 'function' ? section.hidden(values) : !!section.hidden;
          return !isHidden;
        })
        .reduce(
          (acc, section) => {
            acc[section.id] = getVisibleSubSections(section, values);
            return acc;
          },
          {} as Record<string, SubSection<T>[]>
        );
    },
    [formConfig, getVisibleSubSections]
  );

  const saveFormMutation = useSaveFormData<T>({
    onError: (error: FormDataError) => {
      console.error('Error saving form data:', error);
    },
  } as SaveFormDataMutationOptions);

  const handleAutoSave = useCallback(
    async (values: T): Promise<void> => {
      if (formConfig) {
        saveDraft();
        await saveFormMutation.mutateAsync({
          id: aId as string,
          values,
          formType: state?.formType || '',
          lastUpdated: new Date().toISOString(),
        });
      }
    },
    [formConfig, saveFormMutation, saveDraft, state?.formType, aId]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateCurrentSection = async <T extends BaseFormValues>(
    values: T,
    currentSection: Section<T> | undefined,
    currentSubSection: SubSection<T> | null
  ): Promise<boolean> => {
    if (!currentSection || !currentSubSection?.validation) return true;

    // Validate using the subsection schema
    return await currentSubSection.validation.isValid(values, {
      abortEarly: false,
      strict: false,
    });
  };

  const getCurrentSubSection = useCallback((): SubSection<T> | null => {
    if (!formConfig || !selectedSection || !selectedSubSection) return null;
    const section = formConfig.sections.find((s) => s.id === selectedSection);
    if (!section?.subSections) return null;
    const subSectionId = selectedSubSection.replace(`${selectedSection}-`, '');
    return section.subSections.find((ss) => ss.id === subSectionId) || null;
  }, [formConfig, selectedSection, selectedSubSection]);

  const handleFormSubmit = useCallback(
    async (values: T): Promise<void> => {
      if (formConfig) {
        try {
          await saveFormMutation.mutateAsync({
            id: formConfig.formId,
            values,
            formType: state?.formType || '',
            lastUpdated: new Date().toISOString(),
          });

          // add missing data from the form, i.e. applicant info + info about them + totalTimeOutsideUs
          if (formikRef?.current?.values) {
            formikRef.current.values = {
              ...formikRef.current.values,
              applicantInfo: { ...I131_SAMPLE_YUP_DATA.applicantInfo },
              infoAboutThem: { ...I131_SAMPLE_YUP_DATA.infoAboutThem },
              totalTimeOutsideUs: I131_SAMPLE_YUP_DATA.totalTimeOutsideUs,
            };
          }
          saveDraft();

          axios
            .post(`${prefixUrl}/draft-service/drafts/${aId}/submit`, { headers: {}, withCredentials: true })
            .then((response) => {
              // Clear form state after successful submission
              clearFormState(queryClient, formConfig.formId);
              const respData = response.data;

              navigate(`/submission-confirmation?receiptNumber=${respData.receiptNumber}`, { replace: true });
            })
            .catch((e) => {
              console.log('Failed to create draft: ', e.errors);
            });
        } catch (error) {
          console.error('Error submitting form:', error);
        }
      }
    },
    [aId, formConfig, navigate, queryClient, saveDraft, saveFormMutation, state?.formType]
  );

  const handleBack = useCallback(
    async (formikProps: FormikProps<T>): Promise<void> => {
      const { values } = formikProps;
      await handleAutoSave(values);

      const currentSectionIndex = formConfig?.sections.findIndex((s) => s.id === selectedSection) ?? -1;
      const visibleSubSections = createVisibleSubSectionsMap(values)[selectedSection] || [];
      const currentSubSectionId = selectedSubSection.replace(`${selectedSection}-`, '');
      const currentIndex = visibleSubSections.findIndex((ss) => ss.id === currentSubSectionId);

      if (currentIndex > 0) {
        // Move to previous subsection in current section
        const newExpandedSections: Record<string, boolean> = {
          ...expandedSections,
          [selectedSection]: true,
        };

        setExpandedSections(newExpandedSections);
        setSelectedSection(selectedSection);
        setSelectedSubSection(`${selectedSection}-${visibleSubSections[currentIndex - 1].id}`);
      } else if (currentSectionIndex > 0) {
        // Move to last subsection of previous section
        const previousSection = formConfig?.sections[currentSectionIndex - 1];
        if (previousSection) {
          const previousSectionSubSections = createVisibleSubSectionsMap(values)[previousSection.id] || [];
          if (previousSectionSubSections.length > 0) {
            const lastSubSection = previousSectionSubSections[previousSectionSubSections.length - 1];

            // Expand previous section and collapse current section
            const newExpandedSections: Record<string, boolean> = {
              ...expandedSections,
              [selectedSection]: false,
              [previousSection.id]: true,
            };

            setExpandedSections(newExpandedSections);
            setSelectedSection(previousSection.id);
            setSelectedSubSection(`${previousSection.id}-${lastSubSection.id}`);
          }
        }
      }
    },
    [
      selectedSection,
      selectedSubSection,
      handleAutoSave,
      createVisibleSubSectionsMap,
      formConfig,
      setSelectedSection,
      setSelectedSubSection,
      setExpandedSections,
      expandedSections,
    ]
  );

  const handleNext = useCallback(
    async (formikProps: FormikProps<T>): Promise<void> => {
      const { values } = formikProps;

      try {
        // Get current section and subsection
        const currentSection = formConfig?.sections.find((s) => s.id === selectedSection);
        const currentSubSection = getCurrentSubSection();

        if (!currentSection || !formConfig) {
          console.log('No current section or form config found');
          return;
        }

        // Validate current section
        const isValid = await validateCurrentSection(values, currentSection, currentSubSection);
        if (!isValid) {
          console.log('Current section validation failed');
          return;
        }

        const sanitizedValues = clearHiddenValues(values);

        // Save current state
        await handleAutoSave(sanitizedValues);

        const visibleSubSectionsMap = createVisibleSubSectionsMap(sanitizedValues);
        const currentSectionSubSections = visibleSubSectionsMap[selectedSection] || [];
        const currentSubSectionId = selectedSubSection.replace(`${selectedSection}-`, '');
        const currentIndex = currentSectionSubSections.findIndex((ss) => ss.id === currentSubSectionId);

        // Check if we should move to the next section
        if (currentIndex === currentSectionSubSections.length - 1) {
          // Find next section with visible subsections
          const currentSectionIndex = formConfig.sections.findIndex((s) => s.id === selectedSection);

          // Look for next section with visible subsections
          for (let i = currentSectionIndex + 1; i < formConfig.sections.length; i++) {
            const nextSection = formConfig.sections[i];
            if (!nextSection) continue;

            const nextSectionSubSections = visibleSubSectionsMap[nextSection.id] || [];

            if (nextSectionSubSections.length > 0) {
              // FOR DEBUGGING PURPOSES, LEAVE IN.
              // console.log('Moving to next section:', {
              //   fromSection: selectedSection,
              //   toSection: nextSection.id,
              //   toSubSection: nextSectionSubSections[0].id,
              // });

              // Create new expanded sections state and unlock next section
              const newExpandedSections: Record<string, boolean> = {
                ...expandedSections,
                [selectedSection]: false,
                [nextSection.id]: true,
              };

              const newUnlockedSections: Record<string, boolean> = {
                ...unlockedSections,
                [nextSection.id]: true,
              };

              setExpandedSections(newExpandedSections);
              setUnlockedSections(newUnlockedSections);
              setSelectedSection(nextSection.id);
              setSelectedSubSection(`${nextSection.id}-${nextSectionSubSections[0].id}`);
              return;
            }
          }

          // console.log('No next section found with visible subsections');
          return;
        }

        // Move to next subsection in current section
        const nextSubSection = currentSectionSubSections[currentIndex + 1];
        if (nextSubSection) {
          // Create new expanded sections state
          const newExpandedSections: Record<string, boolean> = {
            ...expandedSections,
            [selectedSection]: true,
          };

          setExpandedSections(newExpandedSections);
          setSelectedSection(selectedSection);
          setSelectedSubSection(`${selectedSection}-${nextSubSection.id}`);
        }
      } catch (error) {
        console.error('Error in handleNext:', error);
      }
    },
    [
      formConfig,
      getCurrentSubSection,
      clearHiddenValues,
      handleAutoSave,
      createVisibleSubSectionsMap,
      selectedSection,
      selectedSubSection,
      expandedSections,
      unlockedSections,
      setExpandedSections,
      setUnlockedSections,
      setSelectedSection,
      setSelectedSubSection,
    ]
  );

  useBeforeUnloadSave<T>(formikRef, formConfig, state?.formType, saveFormMutation);

  const { data: storedFormData, isLoading } = useFormData(formId, {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: !!formId,
    staleTime: 0,
    initialData: () => {
      // Always start with default values
      return {
        id: formId,
        values: DEFAULT_FORM_VALUES,
        formType: state?.formType || '',
        lastUpdated: new Date().toISOString(),
      };
    },
  });

  // Extracted initial values calculation
  const initialValues = useMemo((): T => {
    if (!formConfig) return { formStatus: 'no-form-config' } as T;
    const loadedFormData = fullDraftData?.id ? fullDraftData.formData : {};
    return storedFormData?.values
      ? { ...DEFAULT_FORM_VALUES, ...formConfig.initialValues, ...storedFormData.values, ...loadedFormData }
      : { ...DEFAULT_FORM_VALUES, ...formConfig.initialValues, ...loadedFormData };
  }, [formConfig, fullDraftData, storedFormData]);

  const handleSectionClick = useCallback(
    (sectionId: string): void => {
      setExpandedSections({
        ...expandedSections,
        [sectionId]: !expandedSections[sectionId],
      });
    },
    [expandedSections, setExpandedSections]
  );

  const handleSubSectionClick = useCallback(
    (sectionId: string, subSectionId: string): void => {
      const fullSubSectionId = `${sectionId}-${subSectionId}`;

      // Remove the resetForm call when changing sections
      setSelectedSection(sectionId);
      setSelectedSubSection(fullSubSectionId);
    },
    [setSelectedSection, setSelectedSubSection]
  );

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!formConfig) {
    return <Typography>No form configuration found</Typography>;
  }

  return (
    <Formik<T>
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      enableReinitialize={true}
      innerRef={formikRef}
      validateOnMount={false}
      initialTouched={{}}
      validationSchema={getCurrentSubSection()?.validation}
      validateOnChange={true}
      validateOnBlur={true}
    >
      <FormContent<T>
        selectedSection={selectedSection}
        selectedSubSection={selectedSubSection}
        checkNextSubSection={checkNextSubSection}
        checkPreviousSubSection={checkPreviousSubSection}
        createVisibleSubSectionsMap={createVisibleSubSectionsMap}
        handleNext={handleNext}
        handleBack={handleBack}
        formConfig={formConfig}
        getCurrentSubSection={getCurrentSubSection}
        handleSubSectionClick={handleSubSectionClick}
        handleSectionClick={handleSectionClick}
        expandedSections={expandedSections}
        unlockedSections={unlockedSections}
        setExpandedSections={
          setExpandedSections as (
            sections: Record<string, boolean> | ((prev: Record<string, boolean>) => Record<string, boolean>)
          ) => void
        }
      />
    </Formik>
  );
}

export default FormFactory;
