import { Theme, SxProps } from '@mui/material';
import { themeConstants } from '../constants/themeConstants';
import { styleConstants } from '../constants/styleConstants';

export const formLayoutStyles = {
  container: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    minHeight: '100vh',
    width: '100%',
    position: 'relative',
    bgcolor: 'background.default',
  } as const,

  navigationWrapper: {
    display: {
      xs: 'none',
      sm: 'block',
    },
    width: {
      sm: '25%',
    },
    position: 'sticky',
    top: '64px', // Header height
    alignSelf: 'flex-start',
    bgcolor: 'background.paper',
  } as const,

  navigationColumn: {
    width: '100%',
    minHeight: 'fit-content',
    bgcolor: themeConstants.colors.grey[100],
    overflowY: 'auto',
  } as const,

  contentColumn: {
    width: {
      xs: '100%',
      sm: '75%',
    },
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
  } as const,

  contentSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: {
      xs: 2,
      sm: 3,
    },
  } as const,

  formContentWrapper: {
    width: '100%',
    maxWidth: styleConstants.layout.form.maxWidth,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: 12,
  } as const,

  mobileHeader: {
    position: 'sticky',
    top: 0,
    height: '56px',
    bgcolor: 'background.paper',
    display: {
      xs: 'flex',
      sm: 'none',
    },
    alignItems: 'center',
    px: 2,
  } as const,

  mobileDrawer: {
    display: { xs: 'block', sm: 'none' },
    '& .MuiDrawer-paper': {
      width: '100%',
      height: 'auto',
      maxHeight: '80vh',
      top: '56px', // Below mobile header
      bgcolor: 'background.paper',
    },
  } as const,

  menuList: {
    width: '100%',
    p: 0,
    bgcolor: themeConstants.colors.grey[100],
    '.MuiListItem-root': {
      width: '100%',
    },
  } as const,

  mobileMenu: {
    width: '100%',
    bgcolor: themeConstants.colors.grey[100],
  } as const,

  subMenuList: {
    pl: {
      xs: 2,
      sm: 4,
    },
    bgcolor: themeConstants.colors.grey[100],
  } as const,

  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  } as const,

  formTitle: (theme: Theme): SxProps<Theme> => ({
    fontFamily: 'Source Sans Pro, sans-serif',
    fontSize: {
      xs: theme.typography.h6.fontSize,
      sm: theme.typography.h5.fontSize,
      md: theme.typography.h4.fontSize,
    },
    fontWeight: 400,
    lineHeight: {
      xs: '24px',
      sm: '27px',
      md: '30px',
    },
    textAlign: 'left',
    color: theme.palette.text.primary,
    p: 2,
    width: '100%',
    wordBreak: 'break-word', // Prevent text overflow
    maxWidth: '100%',
    boxSizing: 'border-box',
  }),

  // Add ARIA-specific styles
  srOnly: {
    position: 'absolute',
    width: '1px',
    height: '1px',
    padding: 0,
    margin: '-1px',
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    whiteSpace: 'nowrap',
    border: 0,
  } as const,

  menuButton: (isUnlocked: boolean): SxProps<Theme> => ({
    opacity: isUnlocked ? 1 : 0.5,
    cursor: isUnlocked ? 'pointer' : 'not-allowed',
    width: '100%',
    bgcolor: themeConstants.colors.grey[100],
    '&:hover': {
      bgcolor: isUnlocked ? 'action.hover' : 'transparent',
    },
    '&.Mui-selected': {
      bgcolor: themeConstants.colors.blue[100],
      '&:hover': {
        bgcolor: themeConstants.colors.blue[100],
      },
    },
    '&:focus-visible': {
      outline: '2px solid',
      outlineColor: 'primary.main',
      outlineOffset: '-2px',
    },
  }),

  backButton: {
    width: {
      xs: 'auto', // Flexible on mobile
      sm: '154px', // Fixed width on desktop
    },
    height: '48px',
    padding: '12px 16px',
    borderRadius: '3px 0 0 0',
    border: `1px solid ${themeConstants.colors.blue[500]}`,
    bgcolor: 'common.white',
    color: themeConstants.colors.blue[500],
    fontSize: '1rem',
    fontWeight: 500,
    textTransform: 'none',
    '&:hover': {
      bgcolor: 'common.white',
      borderColor: themeConstants.colors.blue[600],
    },
    '&:disabled': {
      borderColor: 'grey.300',
      color: 'grey.300',
    },
  } as const,

  continueButton: {
    width: {
      xs: 'auto', // Flexible on mobile
      sm: '154px', // Fixed width on desktop
    },
    height: '48px',
    padding: '12px 16px',
    borderRadius: '3px 0 0 0',
    bgcolor: themeConstants.colors.blue[500],
    color: 'common.white',
    fontSize: '1rem',
    fontWeight: 500,
    textTransform: 'none',
    '&:hover': {
      bgcolor: themeConstants.colors.blue[600],
    },
    '&:disabled': {
      bgcolor: 'grey.300',
      color: 'common.white',
    },
  } as const,

  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between', // Back to space-between for proper spacing
    bgcolor: 'background.paper',
    marginTop: '0',
    flexWrap: {
      xs: 'wrap',
      sm: 'nowrap',
    },
    padding: '0',
  } as const,

  backButtonWrapper: {
    flex: '0 0 auto',
    margin: {
      xs: 1,
      md: 0,
    },
  } as const,

  // Add a placeholder div that takes up the back button space when there is no back button
  buttonPlaceholder: {
    flex: '0 0 154px', // Same width as back button
    '@media (max-width: 600px)': {
      display: 'none', // Hide on mobile since buttons stack
    },
  } as const,

  nextButtonWrapper: {
    flex: '0 0 auto',
    margin: {
      xs: 1,
      md: 0,
    },
  } as const,
} as const;
