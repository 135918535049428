interface StreamChunk {
  chunk: string;
}

export const extractDataObjects = (text: string): StreamChunk[] => {
  const regex = /data:\s*({[^}]+})/g;
  const matches = text.match(regex);

  if (!matches) return [];

  return matches
    .map((match: string) => {
      const jsonString = match.replace(/^data:\s*/, '');
      try {
        return JSON.parse(jsonString) as StreamChunk;
      } catch (error) {
        console.error('Failed to parse JSON:', jsonString);
        return null;
      }
    })
    .filter((chunk): chunk is StreamChunk => chunk !== null);
};

export const generateSessionId = (): string => {
  try {
    return crypto.randomUUID();
  } catch (error) {
    // Fallback for environments where randomUUID is not available
    return Math.random().toString(36).substring(2) + Date.now().toString(36);
  }
};
