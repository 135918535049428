import * as React from 'react';
import CertificationSignature from '@src/containers/FormSubSections/SharedSubSections/CertificationSignature/CertificationSignature';

const InterpreterCertification: React.FC = () => {
  return (
    <CertificationSignature
      question="Interpreter's Certification and Signature"
      signatureLabel="Interpreter's Signature"
      signaturePath="interpreterContactAndCertification.signature"
      datePath="interpreterContactAndCertification.dateOfSignature"
      languagePath="interpreterContactAndCertification.language"
    />
  );
};

export default InterpreterCertification;
