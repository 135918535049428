import { ReactElement } from 'react';
import { useFormikContext } from 'formik';
import { Box } from '@mui/material';
import { RadioOption } from '@src/types/pages.types';
import CustomRadio from '@components/CustomRadio';
import RequiredFieldsMessage from '@components/RequiredFieldsMessage/RequiredFieldsMessage';
import { TextFieldWithQuestion } from '@src/components';
import { I131Schema } from '@src/utils/I131.schema';

function SelfOtherInUsInitParoleInfo(): ReactElement {
  const { values, setFieldValue } = useFormikContext<I131Schema>();

  const paroleInPlaceOptions: RadioOption[] = [
    { value: 'A', label: 'Military Parole in Place (PIP)' },
    { value: 'B', label: 'Family Reunification Task Force (FRTF) Process; Task Force Registration Number:' },
    { value: 'C', label: 'Other: (List specific program or process)' },
  ];

  const paroleInPlaceMilitaryStatusOptions: RadioOption[] = [
    { value: '1', label: 'A current or former service member' },
    { value: '2', label: 'A spouse, parent, son, or daughter of a current or former service member' },
  ];

  const clearParoleInPlaceFields = (program: string): void => {
    switch (program) {
      case 'A':
        setFieldValue('selfOrOtherInUsInitParole.otherProgram', '');
        setFieldValue('selfOrOtherInUsInitParole.receiptNumber', '');
        break;
      case 'B':
        setFieldValue('selfOrOtherInUsInitParole.forPersonStatus', '');
        setFieldValue('selfOrOtherInUsInitParole.otherProgram', '');
        break;
      case 'C':
        setFieldValue('selfOrOtherInUsInitParole.receiptNumber', '');
        setFieldValue('selfOrOtherInUsInitParole.forPersonStatus', '');
        break;
    }
  };

  const handleProgramChange = (value: string): void => {
    clearParoleInPlaceFields(value);
  };

  return (
    <Box sx={{ py: 4 }}>
      <RequiredFieldsMessage />
      <Box sx={{ width: '100%', maxWidth: '800px' }}>
        <CustomRadio
          fieldName="selfOrOtherInUsInitParole.program"
          question="Initial Request for Arrival/Departure Record for Parole In Place"
          instructions="I am applying for an initial period of parole in place under INA section 212(d)(5)(A) and I am inside the United States, or I am applying for an initial period of parole under INA section 212(d)(5)(A) on behalf of someone else who is inside the United States, under:"
          options={paroleInPlaceOptions}
          direction="column"
          required
          onChange={handleProgramChange}
        />

        {values.selfOrOtherInUsInitParole?.program === 'A' && (
          <Box sx={{ mt: 2, ml: 4 }}>
            <CustomRadio
              fieldName="selfOrOtherInUsInitParole.forPersonStatus"
              question="I am:"
              options={paroleInPlaceMilitaryStatusOptions}
              direction="column"
              required
            />
          </Box>
        )}

        {values.selfOrOtherInUsInitParole?.program === 'B' && (
          <TextFieldWithQuestion
            name="selfOrOtherInUsInitParole.receiptNumber"
            question="What is the task force registration number?"
          />
        )}

        {values.selfOrOtherInUsInitParole?.program === 'C' && (
          <TextFieldWithQuestion
            name="selfOrOtherInUsInitParole.otherProgram"
            question="List specific program or process."
          />
        )}
      </Box>
    </Box>
  );
}

export default SelfOtherInUsInitParoleInfo;
