import { BaseFormValues, FormConfig } from '@src/types/form.types';
import { i131Config } from './I131FormConfig';

// SAFETY: This must be of type `any` to be generic, because we can't know the
// exact type of the form values, and we can't define the `Record` generically
// in this declaration.
//
// Other considerations include using a `switch` in `getFormConfig`, or
// refactoring this function into a class.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formConfigs: Record<string, FormConfig<any>> = {
  [i131Config.formId]: i131Config,
};

export const getFormConfig = <T extends BaseFormValues>(formType: string): FormConfig<T> | undefined => {
  return formConfigs[formType];
};
