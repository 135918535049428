import { fullNameSchema } from '@src/utils/I131.schema';
import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type TheirOtherNamesSchemaType = Partial<I131FormValues> & {
  infoAboutThem: {
    otherNames: {
      givenName: string;
      middleName: string;
      familyName: string;
    }[];
  };
};

const theirOtherNamesSchema = createPartialSchema<TheirOtherNamesSchemaType>({
  infoAboutThem: Yup.object({
    otherNames: Yup.array().of(fullNameSchema).default([]).max(3),
  }),
});

export default theirOtherNamesSchema;
