/* eslint-disable @typescript-eslint/no-explicit-any */
// Disabling no-explicit-any because we don't know the shape of the data; these functions should take all
import { FieldDisplay } from './ReviewAndSubmit.types';

/**
 * Collapse an object into a flattened string
 */
export function objectToString(value: any): string {
  const displayTypes = ['string', 'number', 'boolean'];
  if (displayTypes.includes(typeof value)) {
    return value.toString();
  } else if (Array.isArray(value)) {
    return value.join(', ');
  }

  return Object.entries(value)
    .map<string | undefined>(([k, v]) => {
      if (v === '' || v == null) {
        return undefined;
      }

      if (typeof v === 'object') {
        return objectToString(v);
      }

      return `${k}: ${v}`;
    })
    .filter((s) => s)
    .join(', ')
    .trim();
}

export type FlattenObjectSchemaOptions = {
  sectionId: string;
  subSectionId: string;
  normalizeStringFn?: (str: string) => string;
  valueToStringFns?: Record<string, (value: any) => string>;
};

/**
 * Generate subsection fields into a flat array
 */
export function generateFields(
  fields: Record<string, any>,
  values: Record<string, any>,
  opts: FlattenObjectSchemaOptions
): FieldDisplay[] {
  const fields_: FieldDisplay[] = [];

  const normalizeString = opts.normalizeStringFn || ((str: string): string => str);

  for (const key in fields) {
    const keys = new Set<string>();
    const nodes: string[] | undefined = fields[key]['_nodes'];
    if (!nodes) {
      fields_.push({
        label: normalizeString(key).toDisplay(),
        value: values[key],
        sectionId: opts.sectionId,
        subSectionId: opts.subSectionId,
      });
      continue;
    }

    // Dedupe fields, there might be duplicates
    for (const key of nodes) {
      keys.add(key);
    }

    for (const field of keys) {
      const fn = opts.valueToStringFns?.[field] || objectToString;

      fields_.push({
        label: normalizeString(field).toDisplay(),
        value: fn(values[key][field]).toDisplay(),
        sectionId: opts.sectionId,
        subSectionId: opts.subSectionId,
      });
    }
  }

  return fields_;
}
