import * as React from 'react';
import { CustomRadio } from '@components';

const PreviousProceedings: React.FC = () => {
  return (
    <CustomRadio
      question="Has the person who will receive the travel document, parole document, or Arrival/Departure Record, if approved, been in any exclusion, deportation, removal, or rescission proceedings?"
      fieldName="processingInfo.previousProceedings"
      required
    />
  );
};

export default PreviousProceedings;
