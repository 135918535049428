import * as React from 'react';
import { TextFieldWithQuestion } from '@components';

const TheirI94ArrivalDepartureNumber: React.FC = (): JSX.Element => {
  return (
    <TextFieldWithQuestion
      name={'infoAboutThem.i94Number'}
      question="What is their most recent Form I-94 Arrival/Departure Record Number? (if any)"
    />
  );
};

export default TheirI94ArrivalDepartureNumber;
