/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { AdditionalInformationSchema, AddressSchema, BooleanSchema, FullNameSchema, I131Schema } from './I131.schema';
import { AddressType, FullNameType, I131JsonSchema, UnitType } from './I131Json.schema';
import { capitalizeFirstCharacter, ensureArray, ensureString } from './pureFunctions';

export function booleanToString(input: boolean | undefined): BooleanSchema {
  if (input === true) return 'Yes';
  if (input === false) return 'No';

  return '';
}

export function hasInterpreter(json: I131JsonSchema): BooleanSchema {
  return json.interpreterContactAndCertification?.fullName?.familyName ||
    json.interpreterContactAndCertification?.fullName?.givenName ||
    json.interpreterContactAndCertification?.businessName ||
    json.interpreterContactAndCertification?.daytimePhone ||
    json.interpreterContactAndCertification?.email ||
    json.interpreterContactAndCertification?.mobilePhone ||
    json.interpreterContactAndCertification?.language ||
    json.interpreterContactAndCertification?.signature ||
    json.interpreterContactAndCertification?.dateOfSignature
    ? 'Yes'
    : '';
}

export function hasPreparer(json: I131JsonSchema): BooleanSchema {
  return json.preparerContactAndCertification?.fullName?.familyName ||
    json.preparerContactAndCertification?.fullName?.givenName ||
    json.preparerContactAndCertification?.businessName ||
    json.preparerContactAndCertification?.daytimePhone ||
    json.preparerContactAndCertification?.email ||
    json.preparerContactAndCertification?.mobilePhone ||
    json.preparerContactAndCertification?.signature ||
    json.preparerContactAndCertification?.dateOfSignature
    ? 'Yes'
    : '';
}

export function intendSendToOutsideUs(json: I131JsonSchema): BooleanSchema {
  return json.initPipReparole?.dateOfIntendedArrival ||
    json.initPipReparole?.locationToNotify?.cityOrTown ||
    json.initPipReparole?.locationToNotify?.country
    ? 'Yes'
    : '';
}

export function mapAddressToYup(input: AddressType | undefined): AddressSchema {
  return {
    inCareOfName: input?.inCareOfName || '',
    street: input?.street || '',
    unitType: (input?.unitType || '') as UnitType,
    unitNumber: input?.unitNumber || '',
    cityOrTown: input?.cityOrTown || '',
    state: input?.state || '',
    zipCode: input?.zipCode || '',
    province: input?.province || '',
    postalCode: input?.postalCode || '',
    country: input?.country || '',
  };
}

export function mapIsPhysicalSameAsMailingAddress(
  mailingAddress: AddressType | undefined,
  physicalAddress: AddressType | undefined
): '' | 'Yes' | 'No' {
  return _.isEqual(mailingAddress, physicalAddress) ? 'Yes' : 'No';
}

export function mapApplicationType(json: I131JsonSchema): string {
  if (json.applicationType?.reentryPermit) return '1';
  if (json.applicationType?.refugeeStatusForRefugeeTravelDocument) return '2';
  if (json.applicationType?.LprStatusForRefugeeTravelDocument) return '3';
  if (json.applicationType?.TpsBeneInUsForAdmissionBackToUs) return '4';
  if (
    json.applicationType?.inUsAdvanceParoleDocument?.pendingI485 ||
    json.applicationType?.inUsAdvanceParoleDocument?.pendingI589 ||
    json.applicationType?.inUsAdvanceParoleDocument?.pendingI821 ||
    json.applicationType?.inUsAdvanceParoleDocument?.deferredEnforcedDeparture ||
    json.applicationType?.inUsAdvanceParoleDocument?.approvedI821d ||
    json.applicationType?.inUsAdvanceParoleDocument?.approvedI914 ||
    json.applicationType?.inUsAdvanceParoleDocument?.approvedI918 ||
    json.applicationType?.inUsAdvanceParoleDocument?.paroleeUnderIna212 ||
    json.applicationType?.inUsAdvanceParoleDocument?.approvedI817 ||
    json.applicationType?.inUsAdvanceParoleDocument?.pendingI687 ||
    json.applicationType?.inUsAdvanceParoleDocument?.approvedVStatus ||
    json.applicationType?.inUsAdvanceParoleDocument?.CNMI ||
    json.applicationType?.inUsAdvanceParoleDocument?.other
  )
    return '5';
  if (
    json.applicationType?.outsideUsInitParoleDocument?.FWVP ||
    json.applicationType?.outsideUsInitParoleDocument?.IMMVI ||
    json.applicationType?.outsideUsInitParoleDocument?.intergovParoleReferral ||
    json.applicationType?.outsideUsInitParoleDocument?.FRTF ||
    json.applicationType?.outsideUsInitParoleDocument?.other
  )
    return '6';
  if (json.applicationType?.selfOutsideUsParoleOrOtherOutsideUsInitParole) return '7';
  if (
    json.applicationType?.selfOrOtherInUsInitParole?.pip ||
    json.applicationType?.selfOrOtherInUsInitParole?.FRTF ||
    json.applicationType?.selfOrOtherInUsInitParole?.other
  )
    return '8';
  if (json.applicationType?.selfOrOtherInUsInitParoleNotSpecific) return '9';
  if (
    json.applicationType?.selfOrOtherRenewParole?.familyReunion ||
    json.applicationType?.selfOrOtherRenewParole?.afghanParole ||
    json.applicationType?.selfOrOtherRenewParole?.ukrainianParole ||
    json.applicationType?.selfOrOtherRenewParole?.FWVP ||
    json.applicationType?.selfOrOtherRenewParole?.IMMVI ||
    json.applicationType?.selfOrOtherRenewParole?.CAM ||
    json.applicationType?.selfOrOtherRenewParole?.FRTF ||
    json.applicationType?.selfOrOtherRenewParole?.pip ||
    json.applicationType?.selfOrOtherRenewParole?.other
  )
    return '10';
  if (json.applicationType?.selfOrOtherRenewParoleNotSpecific) return '11';

  return '';
}

export function mapInUsAP(appInput?: I131JsonSchema['applicationType']): {
  selection: string;
  receipt: string;
} {
  if (!appInput?.inUsAdvanceParoleDocument) return { selection: '', receipt: '' };
  const input = appInput.inUsAdvanceParoleDocument;

  if (input.pendingI485) return { selection: 'A', receipt: ensureString(input.pendingI485Receipt) };
  if (input.pendingI589) return { selection: 'B', receipt: ensureString(input.pendingI589Receipt) };
  if (input.pendingI821) return { selection: 'C', receipt: ensureString(input.pendingI821Receipt) };
  if (input.deferredEnforcedDeparture) return { selection: 'D', receipt: '' };
  if (input.approvedI821d) return { selection: 'E', receipt: ensureString(input.approvedI821dReceipt) };
  if (input.approvedI914) return { selection: 'F', receipt: ensureString(input.approvedI914Receipt) };
  if (input.approvedI918) return { selection: 'G', receipt: ensureString(input.approvedI918Receipt) };
  if (input.paroleeUnderIna212) return { selection: 'H', receipt: ensureString(input.paroleeUnderIna212CoA) };
  if (input.approvedI817) return { selection: 'I', receipt: ensureString(input.approvedI817Receipt) };
  if (input.pendingI687) return { selection: 'J', receipt: ensureString(input.pendingI687Receipt) };
  if (input.approvedVStatus) return { selection: 'K', receipt: ensureString(input.approvedVStatusReceipt) };
  if (input.CNMI) return { selection: 'L', receipt: ensureString(input.CNMIReceipt) };
  if (input.other) return { selection: 'M', receipt: ensureString(input.otherExplanation) };

  return { selection: '', receipt: '' };
}

export function mapJ2FAdditionalInformation(
  input: AdditionalInformationSchema[] | undefined,
  fieldName: string
): string {
  let result: AdditionalInformationSchema | undefined;
  switch (fieldName) {
    case 'planToTravelBackExplanation':
      result = input?.find((i) => i.question === 'planToTravelBackExplanation:');
      break;
    case 'returnToCountryExplanation':
      result = input?.find((i) => i.question === 'returnToCountryExplanation:');
      break;
    case 'appliedForPassportExplanation':
      result = input?.find((i) => i.question === 'appliedForPassportExplanation:');
      break;
    case 'receiveBenefitsExplanation':
      result = input?.find((i) => i.question === 'receiveBenefitsExplanation:');
      break;
    case 'reacquiredNationalityExplanation':
      result = input?.find((i) => i.question === 'reacquiredNationalityExplanation:');
      break;
    case 'acquiredNewNationalityExplanation':
      result = input?.find((i) => i.question === 'acquiredNewNationalityExplanation:');
      break;
    case 'grantedAsyleeForOtherCountryExplanation':
      result = input?.find((i) => i.question === 'grantedAsyleeForOtherCountryExplanation:');
      break;
    case 'fileForRefugeeBeforeLeavingUsExplanation':
      result = input?.find((i) => i.question === 'fileForRefugeeBeforeLeavingUsExplanation:');
      break;
    default:
      result = undefined;
  }

  return result?.explanation ?? '';
}

export function mapJ2FDocSendTo(toAddr: boolean | undefined, toEmbassy: boolean | undefined): string {
  if (toAddr) return 'usAddress';
  if (toEmbassy) return 'usEmbassy';

  return '';
}

export function mapJ2FNoticeSendTo(p2q3: boolean | undefined, p4q9a: boolean | undefined): string {
  if (p2q3) return 'toP2Q3';
  if (p4q9a) return 'toP4Q9a';

  return '';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapJ2FProposedTravel(input: any): any {
  if (!input) return [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return input.map((item: any) => {
    return {
      ...item,
      departureDate: toUiDate(item.departureDate),
      expectedLength: numberToString(item.expectedLength),
    };
  });
}

export function mapOtherNames(fullName: FullNameType[] | undefined): FullNameSchema[] {
  if (!fullName) return [] as FullNameSchema[];
  return fullName.map((fn) => {
    return {
      givenName: fn.givenName || '',
      middleName: fn.middleName || '',
      familyName: fn.familyName || '',
    };
  }) as FullNameSchema[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapOutsideUsInitIP(input: any): { selection: string; receipt: string } {
  if (!input) return { selection: '', receipt: '' };
  if (input.FWVP) return { selection: 'A', receipt: input.FWVPReceipt || '' };
  if (input.IMMVI) return { selection: 'B', receipt: '' };
  if (input.intergovParoleReferral) return { selection: 'C', receipt: '' };
  if (input.FRTF) return { selection: 'D', receipt: input.FRTFReceipt || '' };
  if (input.other) return { selection: 'E', receipt: '' };

  return { selection: '', receipt: '' };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapSelfOrOtherInUsIP(input: any): string {
  if (!input) return '';
  if (input.pip) return 'A';
  if (input.FRTF) return 'B';
  if (input.other) return 'C';

  return '';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapSelfOrOtherRP(input: any): string {
  if (!input) return '';
  if (input.familyReunion) return 'A';
  if (input.afghanParole) return 'B';
  if (input.ukrainianParole) return 'C';
  if (input.FWVP) return 'D';
  if (input.IMMVI) return 'E';
  if (input.CAM) return 'F';
  if (input.FRTF) return 'G';
  if (input.pip) return 'H';
  if (input.other) return 'I';

  return '';
}

export function mapSsn(input: string | undefined): string {
  if (!input || input.length !== 9) return '';

  return `${input.substring(0, 3)}-${input.substring(3, 5)}-${input.substring(5)}`;
}

export function numberToString(input: number | undefined): string {
  if (input === undefined) return '';

  return input + '';
}

export function toUiDate(input: string | undefined): string {
  if (!input || input.match(/^\d{4}-\d{2}-\d{2}$/) === null) return input || '';

  const strArr = input.split('-');
  return `${strArr[1]}/${strArr[2]}/${strArr[0]}`;
}

// replace return type with I-131 yup-schema type
function I131JsonToFormik(json: I131JsonSchema): I131Schema {
  const inUsAP = mapInUsAP(json.applicationType);
  const outsideUsInitIP = mapOutsideUsInitIP(json.applicationType?.outsideUsInitParoleDocument);
  const selfOrOtherInUsIP = mapSelfOrOtherInUsIP(json.applicationType?.selfOrOtherInUsInitParole);
  const selfOrOtherRP = mapSelfOrOtherRP(json.applicationType?.selfOrOtherRenewParole);

  return {
    applicationType: mapApplicationType(json),
    tpsBeneficiary: { tpsReceiptNumber: ensureString(json.applicationType?.TpsBeneInUsForAdmissionReceipt) },
    inUsAdvanceParoleDocument: {
      program: inUsAP.selection,
      receiptNumber: inUsAP.receipt,
    },
    outsideUsInitParoleDocument: {
      program: outsideUsInitIP.selection,
      forPerson: ensureString(json.applicationType?.outsideUsInitParoleDocument?.IMMVIStatus),
      receiptNumber: outsideUsInitIP.receipt,
      govExecBranch: ensureString(
        json.applicationType?.outsideUsInitParoleDocument?.intergovParoleReferralAgencyBranch
      ),
      repEmailAddress: ensureString(
        json.applicationType?.outsideUsInitParoleDocument?.intergovParoleReferralAgencyRepEmail
      ),
      otherProgram: ensureString(json.applicationType?.outsideUsInitParoleDocument?.otherExplanation),
    },
    selfOrOtherInUsInitParole: {
      program: selfOrOtherInUsIP,
      forPersonStatus: ensureString(json.applicationType?.selfOrOtherInUsInitParole?.pipStatus),
      receiptNumber: ensureString(json.applicationType?.selfOrOtherInUsInitParole?.FRTFReceipt),
      otherProgram: ensureString(json.applicationType?.selfOrOtherInUsInitParole?.otherExplanation),
    },
    selfOrOtherRenewParole: {
      program: selfOrOtherRP,
      forPerson: ensureString(json.applicationType?.selfOrOtherRenewParole?.IMMVIStatus),
      forPersonStatus: ensureString(json.applicationType?.selfOrOtherRenewParole?.pipStatus),
      otherProgram: ensureString(json.applicationType?.selfOrOtherRenewParole?.otherExplanation),
    },
    i94AdmittedDate: toUiDate(json.applicationType?.i94AdmittedDate),
    refugeeOrLprRefugee: booleanToString(json.applicationType?.refugeeOrLprRefugee),
    applicantInfo: {
      fullName: {
        givenName: ensureString(json.applicantInfo?.fullName?.givenName),
        middleName: ensureString(json.applicantInfo?.fullName?.middleName),
        familyName: ensureString(json.applicantInfo?.fullName?.familyName),
      },
      otherNames: mapOtherNames(json.applicantInfo?.otherNames),
      currentMailingAddress: mapAddressToYup(json.applicantInfo?.currentMailingAddress),
      isPhysicalSameAsMailing: mapIsPhysicalSameAsMailingAddress(
        json.applicantInfo?.currentMailingAddress,
        json.applicantInfo?.currentPhysicalAddress
      ),
      currentPhysicalAddress: mapAddressToYup(json.applicantInfo?.currentPhysicalAddress),
      alienRegistrationNumber: ensureString(json.applicantInfo?.alienRegistrationNumber),
      countryOfBirth: ensureString(json.applicantInfo?.countryOfBirth),
      countryOfCitizenship: ensureString(json.applicantInfo?.countryOfCitizenship),
      gender: ensureString(json.applicantInfo?.gender),
      dateOfBirth: toUiDate(json.applicantInfo?.dateOfBirth),
      socialSecurityNumber: mapSsn(json.applicantInfo?.socialSecurityNumber),
      uscisOnlineAccountNumber: ensureString(json.applicantInfo?.uscisOnlineAccountNumber),
      classOfAdmission: ensureString(json.applicantInfo?.classOfAdmission),
      i94Number: ensureString(json.applicantInfo?.i94Number),
      expirationDate: toUiDate(json.applicantInfo?.expirationDate),
      eMedicalUSParoleeID: ensureString(json.applicantInfo?.eMedicalUSParoleeID),
      applyingForSomeoneElse: '',
    },
    infoAboutThem: {
      fullName: {
        givenName: ensureString(json.infoAboutThem?.fullName?.givenName),
        middleName: ensureString(json.infoAboutThem?.fullName?.middleName),
        familyName: ensureString(json.infoAboutThem?.fullName?.familyName),
      },
      otherNames: mapOtherNames(json.infoAboutThem?.otherNames),
      currentMailingAddress: mapAddressToYup(json.infoAboutThem?.currentMailingAddress),
      isPhysicalSameAsMailing: mapIsPhysicalSameAsMailingAddress(
        json.infoAboutThem?.currentMailingAddress,
        json.infoAboutThem?.currentPhysicalAddress
      ),
      currentPhysicalAddress: mapAddressToYup(json.infoAboutThem?.currentPhysicalAddress),
      alienRegistrationNumber: ensureString(json.infoAboutThem?.alienRegistrationNumber),
      countryOfBirth: ensureString(json.infoAboutThem?.countryOfBirth),
      countryOfCitizenship: ensureString(json.infoAboutThem?.countryOfCitizenship),
      dateOfBirth: toUiDate(json.infoAboutThem?.dateOfBirth),
      classOfAdmission: ensureString(json.infoAboutThem?.classOfAdmission),
      i94Number: ensureString(json.infoAboutThem?.i94Number),
      daytimePhone: ensureString(json.infoAboutThem?.daytimePhone),
      emailAddress: ensureString(json.infoAboutThem?.emailAddress),
    },
    biographicInfo: {
      ethnicity: ensureString(json.biographicInfo?.ethnicity),
      race: ensureArray(json.biographicInfo?.race),
      height: {
        feet: numberToString(json.biographicInfo?.height?.feet),
        inches: numberToString(json.biographicInfo?.height?.inches),
      },
      weight: numberToString(json.biographicInfo?.weight),
      eyeColor: capitalizeFirstCharacter(json.biographicInfo?.eyeColor),
      hairColor: capitalizeFirstCharacter(json.biographicInfo?.hairColor),
    },
    processingInfo: {
      previousProceedings: booleanToString(json.processingInfo?.previousProceedings),
      previousReentryPermit: {
        issued: booleanToString(json.processingInfo?.previousAdvanceParole?.issued),
        dateIssued: toUiDate(json.processingInfo?.previousReentryPermit?.dateIssued),
        disposition: ensureString(json.processingInfo?.previousReentryPermit?.disposition),
      },
      previousAdvanceParole: {
        issued: booleanToString(json.processingInfo?.previousAdvanceParole?.issued),
        dateIssued: toUiDate(json.processingInfo?.previousAdvanceParole?.dateIssued),
        disposition: ensureString(json.processingInfo?.previousAdvanceParole?.disposition),
      },
      requestReplacement: booleanToString(json.processingInfo?.requestReplacement),
      reasonForReplacement: ensureString(json.processingInfo?.reasonForReplacement),
      infoCorrection: ensureArray(json.processingInfo?.infoCorrection),
      infoCorrectionExplanation: ensureString(json.processingInfo?.infoCorrectionExplanation),
      replacementReceiptNumber: ensureString(json.processingInfo?.replacementReceiptNumber),
      documentSendTo: {
        toUsAddressOrEmbassy: mapJ2FDocSendTo(
          json.processingInfo?.documentSendTo?.toUsAddress,
          json.processingInfo?.documentSendTo?.toUsEmbassy
        ),
        embassyCityOrTown: ensureString(json.processingInfo?.documentSendTo?.embassyCityOrTown),
        embassyCountry: ensureString(json.processingInfo?.documentSendTo?.embassyCountry),
      },
      noticeSendTo: {
        selectDestination: mapJ2FNoticeSendTo(
          json.processingInfo?.noticeSendTo?.toP2Q3,
          json.processingInfo?.noticeSendTo?.toP4Q9a
        ),
        address: mapAddressToYup(json.processingInfo?.noticeSendToAddress?.address),
        daytimePhone: ensureString(json.processingInfo?.noticeSendToAddress?.daytimePhone),
        emailAddress: ensureString(json.processingInfo?.noticeSendToAddress?.emailAddress),
      },
    },
    totalTimeOutsideUs: ensureString(json.totalTimeOutsideUs),
    refugeeTravelDocumentInfo: {
      originCountry: ensureString(json.refugeeTravelDocumentInfo?.originCountry),
      planToTravelBack: booleanToString(json.refugeeTravelDocumentInfo?.planToTravelBack),
      planToTravelBackExplanation: mapJ2FAdditionalInformation(
        json.additionalInformation,
        'planToTravelBackExplanation'
      ),
      returnToCountry: booleanToString(json.refugeeTravelDocumentInfo?.returnToCountry),
      returnToCountryExplanation: mapJ2FAdditionalInformation(json.additionalInformation, 'returnToCountryExplanation'),
      appliedForPassport: booleanToString(json.refugeeTravelDocumentInfo?.appliedForPassport),
      appliedForPassportExplanation: mapJ2FAdditionalInformation(
        json.additionalInformation,
        'appliedForPassportExplanation'
      ),
      receiveBenefits: booleanToString(json.refugeeTravelDocumentInfo?.receiveBenefits),
      receiveBenefitsExplanation: mapJ2FAdditionalInformation(json.additionalInformation, 'receiveBenefitsExplanation'),
      reacquiredNationality: booleanToString(json.refugeeTravelDocumentInfo?.reacquiredNationality),
      reacquiredNationalityExplanation: mapJ2FAdditionalInformation(
        json.additionalInformation,
        'reacquiredNationalityExplanation'
      ),
      acquiredNewNationality: booleanToString(json.refugeeTravelDocumentInfo?.acquiredNewNationality),
      acquiredNewNationalityExplanation: mapJ2FAdditionalInformation(
        json.additionalInformation,
        'acquiredNewNationalityExplanation'
      ),
      grantedAsyleeForOtherCountry: booleanToString(json.refugeeTravelDocumentInfo?.grantedAsyleeForOtherCountry),
      grantedAsyleeForOtherCountryExplanation: mapJ2FAdditionalInformation(
        json.additionalInformation,
        'grantedAsyleeForOtherCountryExplanation'
      ),
      fileForRefugeeBeforeLeavingUs: booleanToString(json.refugeeTravelDocumentInfo?.fileForRefugeeBeforeLeavingUs),
      fileForRefugeeBeforeLeavingUsExplanation: mapJ2FAdditionalInformation(
        json.additionalInformation,
        'fileForRefugeeBeforeLeavingUsExplanation'
      ),
      currentlyOutsideUs: booleanToString(json.refugeeTravelDocumentInfo?.currentlyOutsideUs),
      currentLocation: ensureString(json.refugeeTravelDocumentInfo?.currentLocation),
      otherCountries: ensureString(json.refugeeTravelDocumentInfo?.otherCountries),
    },
    proposedTravel: {
      departureDate: toUiDate(json.proposedTravel?.departureDate),
      purpose: ensureString(json.proposedTravel?.purpose),
      countriesIntendedToVisit: ensureString(json.proposedTravel?.countriesIntendedToVisit),
      numberOfTrips: ensureString(json.proposedTravel?.numberOfTrips),
      expectedLength: numberToString(json.proposedTravel?.expectedLength),
    },
    initPipReparole: {
      intendedRecipientOutsideOfUS: intendSendToOutsideUs(json),
      qualification: ensureString(json.initPipReparole?.qualification),
      lengthOfStayInUs: ensureString(json.initPipReparole?.lengthOfStayInUs),
      dateOfIntendedArrival: toUiDate(json.initPipReparole?.dateOfIntendedArrival),
      locationToNotify: {
        cityOrTown: ensureString(json.initPipReparole?.locationToNotify?.cityOrTown),
        country: ensureString(json.initPipReparole?.locationToNotify?.country),
      },
    },
    eadForNewOrReparole: booleanToString(json.eadForNewOrReparole),
    applicantContactAndCertification: {
      daytimePhone: ensureString(json.applicantContactAndCertification?.daytimePhone),
      mobilePhone: ensureString(json.applicantContactAndCertification?.mobilePhone),
      email: ensureString(json.applicantContactAndCertification?.email),
      signature: ensureString(json.applicantContactAndCertification?.signature),
      dateOfSignature: toUiDate(json.applicantContactAndCertification?.dateOfSignature),
    },
    interpreterContactAndCertification: {
      hasInterpreter: hasInterpreter(json),
      fullName: {
        familyName: ensureString(json.interpreterContactAndCertification?.fullName?.familyName),
        givenName: ensureString(json.interpreterContactAndCertification?.fullName?.givenName),
      },
      businessName: ensureString(json.interpreterContactAndCertification?.businessName),
      daytimePhone: ensureString(json.interpreterContactAndCertification?.daytimePhone),
      mobilePhone: ensureString(json.interpreterContactAndCertification?.mobilePhone),
      email: ensureString(json.interpreterContactAndCertification?.email),
      language: ensureString(json.interpreterContactAndCertification?.language),
      signature: ensureString(json.interpreterContactAndCertification?.signature),
      dateOfSignature: toUiDate(json.interpreterContactAndCertification?.dateOfSignature),
    },
    preparerContactAndCertification: {
      hasPreparer: hasPreparer(json),
      fullName: {
        familyName: ensureString(json.preparerContactAndCertification?.fullName?.familyName),
        givenName: ensureString(json.preparerContactAndCertification?.fullName?.givenName),
      },
      businessName: ensureString(json.preparerContactAndCertification?.businessName),
      daytimePhone: ensureString(json.preparerContactAndCertification?.daytimePhone),
      mobilePhone: ensureString(json.preparerContactAndCertification?.mobilePhone),
      email: ensureString(json.preparerContactAndCertification?.email),
      signature: ensureString(json.preparerContactAndCertification?.signature),
      dateOfSignature: toUiDate(json.preparerContactAndCertification?.dateOfSignature),
    },
    additionalInformation: [],
    evidences: ensureArray(json.evidences),
  };
}

export default I131JsonToFormik;
