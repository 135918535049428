import * as React from 'react';
import { TextFieldWithQuestion } from '@components';

const EMedicalID: React.FC = (): JSX.Element => {
  return (
    <TextFieldWithQuestion
      name={'applicantInfo.eMedicalUSParoleeID'}
      question="What is your eMedical U.S. Parolee ID (USPID)? (if any)"
    />
  );
};

export default EMedicalID;
