import * as React from 'react';
import { Container } from '@mui/material';
import { ExplanationBox, RequiredFieldsMessage } from '@src/components';

export const QUALITY_FOR_PAROLE = {
  fieldKey: 'initPipReparole.qualification',
  question:
    'Explain how you qualify for parole, parole in place, or re-parole. Include copies of any supporting documents or evidence you wish considered.',
};

const QualifyForParole: React.FC = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ py: 4 }}
    >
      <RequiredFieldsMessage />
      <ExplanationBox
        name="processingInfo.infoCorrectionExplanation"
        question="Provide an explanation of what is incorrect on your current document to support your request for a correction and attach copies of any documents supporting your request."
      />
    </Container>
  );
};

export default QualifyForParole;
