import * as React from 'react';
import { ReactElement } from 'react';
import FlexibleDropdown from '@src/components/FlexibleDropdown';

const eyeColorOptions = [
  { value: 'Black', label: 'Black' },
  { value: 'Blue', label: 'Blue' },
  { value: 'Brown', label: 'Brown' },
  { value: 'Gray', label: 'Gray' },
  { value: 'Green', label: 'Green' },
  { value: 'Hazel', label: 'Hazel' },
  { value: 'Maroon', label: 'Maroon' },
  { value: 'Pink', label: 'Pink' },
  { value: 'Unknown', label: 'Unknown' },
];

const EyeColor: React.FC = (): ReactElement => {
  return (
    <FlexibleDropdown
      leftName="biographicInfo.eyeColor"
      leftLabel="Eye Color"
      question="What is the eye color of the person who will receive the document?"
      instructions="Only select one option"
      leftOptions={eyeColorOptions}
      testId="eye-color-dropdown"
    />
  );
};

export default EyeColor;
