import { Box, FormLabel, Theme, SxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactElement } from 'react';

interface QuestionProps {
  question?: string;
  instructions?: string;
  required?: boolean;
}

const getQuestionLabelStyles = (theme: Theme): SxProps<Theme> => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: {
    xs: theme.typography.h6.fontSize,
    sm: theme.typography.h5.fontSize,
    md: theme.typography.h4.fontSize,
    lg: theme.typography.h3.fontSize,
  },
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.palette.text.primary,
  textAlign: 'left',
  maxWidth: '100%',
  mb: 3,
  '&.Mui-focused': {
    color: theme.palette.text.primary,
  },
});

const getInstructionsLabelStyles = (theme: Theme): SxProps<Theme> => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: {
    xs: theme.typography.body2.fontSize,
    sm: theme.typography.body1.fontSize,
  },
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.text.primary,
  textAlign: 'left',
  maxWidth: '100%',
  mb: 3,
  display: 'block',
  '&.Mui-focused': {
    color: theme.palette.text.primary,
  },
});

const questionContainerStyles: SxProps<Theme> = {
  width: '100%',
  maxWidth: '100%',
  mb: 3,
};

const QuestionWithInstruction = ({ question, instructions, required = false }: QuestionProps): ReactElement => {
  const theme = useTheme();

  // Find the last word and add the asterisk to it to prevent wrapping
  let questionWithoutLastWord;
  let lastWord;
  if (question && required) {
    const words = question.split(' ');
    lastWord = words.pop();
    questionWithoutLastWord = words.join(' ');
  }

  return (
    <Box sx={questionContainerStyles}>
      {question && (
        <FormLabel
          component="label"
          sx={getQuestionLabelStyles(theme)}
        >
          {required ? (
            <>
              {questionWithoutLastWord}{' '}
              <span style={{ whiteSpace: 'nowrap' }}>
                {lastWord}
                <span style={{ color: theme.palette.error.main }}> *</span>
              </span>
            </>
          ) : (
            question
          )}
        </FormLabel>
      )}
      {instructions && (
        <FormLabel
          component="label"
          sx={getInstructionsLabelStyles(theme)}
        >
          {instructions}
        </FormLabel>
      )}
    </Box>
  );
};

export default QuestionWithInstruction;
