import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { ReactElement } from 'react';
import { Field, FieldProps } from 'formik';
import { FormControl, FormControlLabel, Radio, RadioGroup, FormHelperText, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CustomRadioProps, RadioOption } from '../../types/pages.types';
import { radioStyles } from '../../styles';
import QuestionWithInstruction from '../QuestionWithInstruction';

const defaultOptions: RadioOption[] = [
  {
    value: 'No',
    label: 'No',
    icon: <CancelIcon sx={{ color: 'primary.main' }} />,
    checkedIcon: <CancelIcon sx={{ color: 'primary.main' }} />,
  },
  {
    value: 'Yes',
    label: 'Yes',
    icon: <CheckCircleIcon sx={{ color: 'success.main' }} />,
    checkedIcon: <CheckCircleIcon sx={{ color: 'success.main' }} />,
  },
];

const CustomRadio = ({
  fieldName,
  question,
  instructions,
  required = false,
  options = defaultOptions,
  direction = 'row',
  onChange,
  onBlur,
}: CustomRadioProps): ReactElement => {
  const theme = useTheme();

  const hasLongLabel = options.some((option) => option.label.length > 20);
  const effectiveDirection = options.length > 2 || (options.length < 3 && hasLongLabel) ? 'column' : direction;

  return (
    <Box sx={{ maxWidth: '800px', mx: 'auto', marginTop: '32px' }}>
      <Field name={fieldName}>
        {({ field, meta }: FieldProps): ReactElement => (
          <FormControl
            component="fieldset"
            error={meta.touched && !!meta.error}
            sx={radioStyles.formControl}
          >
            <QuestionWithInstruction
              question={question}
              instructions={instructions}
              required={required}
            />

            <Box sx={radioStyles.radioContainer}>
              <RadioGroup
                {...field}
                sx={radioStyles.getRadioGroupStyles(effectiveDirection)}
                onChange={(e): void => {
                  field.onChange(e);
                  if (onChange) {
                    onChange(e.target.value);
                  }
                }}
                onBlur={(e): void => {
                  field.onBlur(e);
                  if (onBlur) {
                    onBlur(e);
                  }
                }}
              >
                {options.map((option: RadioOption) => (
                  <Box
                    key={option.value}
                    component="label"
                    htmlFor={`${fieldName}-${option.value}`}
                    sx={{
                      ...radioStyles.getRadioBoxStyles(theme, field.value === option.value),
                      width:
                        effectiveDirection === 'column' || window.innerWidth < theme.breakpoints.values.sm
                          ? '100%'
                          : '277.5px',
                    }}
                  >
                    <FormControlLabel
                      id={`${fieldName}-${option.value}`}
                      value={option.value}
                      control={
                        <Radio
                          icon={option.icon || undefined}
                          checkedIcon={option.checkedIcon || option.icon || undefined}
                          sx={radioStyles.getRadioControlStyles(!!option.icon)}
                        />
                      }
                      label={option.label}
                      sx={{
                        ...radioStyles.getFormControlLabelStyles(theme, !!option.icon),
                        ...(effectiveDirection === 'column' && {
                          '.MuiFormControlLabel-label': {
                            textAlign: 'left',
                          },
                        }),
                      }}
                    />
                  </Box>
                ))}
              </RadioGroup>
            </Box>

            {meta.touched && meta.error && (
              <FormHelperText
                error
                sx={radioStyles.getErrorStyles(theme)}
              >
                {meta.error}
              </FormHelperText>
            )}
          </FormControl>
        )}
      </Field>
    </Box>
  );
};

export default CustomRadio;
