import { FC, useState, SyntheticEvent, useEffect } from 'react';
import { Autocomplete, Box, Button, Container, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FileAFormConfig, FormOption } from 'src/types/form.types';
import { useQueryClient } from '@tanstack/react-query';
import { clearFormState } from '@src/utils/formStateUtils';

const formRouteConfig: Record<FormOption, FileAFormConfig> = {
  'I-131v2': {
    route: '/form',
    formValue: 'I-131',
    description: 'Application for Travel Document',
  },
};

const FileAForm: FC = () => {
  const [selectedForm, setSelectedForm] = useState<FormOption | null>(null);
  const navigate = useNavigate();
  const formOptions: FormOption[] = ['I-131v2'];
  const queryClient = useQueryClient();

  // Cleanup effect
  useEffect(() => {
    return () => {
      // Clear any existing form data when mounting FileAForm
      queryClient.removeQueries({
        predicate: (query) => Array.isArray(query.queryKey) && query.queryKey[0] === 'form-data',
      });
    };
  }, [queryClient]);

  const handleFormSelect = (newValue: FormOption | null): void => {
    setSelectedForm(newValue);
  };

  const handleNext = (): void => {
    if (selectedForm) {
      const config = formRouteConfig[selectedForm];
      if (config.formValue) {
        // Clear form state before navigation
        clearFormState(queryClient, config.formValue);

        navigate(config.route, {
          state: { formType: config.formValue },
          replace: true,
        });
      }
    }
  };
  const handleBack = (): void => {
    navigate(-1);
  };

  const commonButtonStyles = {
    width: '154px',
    height: '48px',
    padding: '12px 16px',
    gap: '8px',
    borderRadius: '3px 0px 0px 0px',
    borderWidth: '1px 0px 0px 0px',
    borderStyle: 'solid',
    textTransform: 'none',
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
      }}
    >
      <Container
        sx={{
          width: '676px',
          display: 'flex',
          flexDirection: 'column',
          gap: '64px',
          margin: 5,
          padding: 0,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontFamily: 'Source Sans Pro',
            fontSize: '40px',
            fontWeight: 300,
            lineHeight: '60px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            color: '#333333',
          }}
        >
          What form do you want to file?
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography
            sx={{
              color: '#000000',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '24px',
            }}
          >
            Form Name
          </Typography>
          <Autocomplete
            options={formOptions}
            value={selectedForm}
            onChange={(_event: SyntheticEvent, newValue: FormOption | null): void => handleFormSelect(newValue)}
            renderInput={(params): JSX.Element => (
              <TextField
                {...params}
                placeholder="Select one"
                sx={{
                  width: '100%',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '4px',
                    '& fieldset': {
                      borderColor: '#000000',
                    },
                    '&:hover fieldset': {
                      borderColor: '#000000',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#000000',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    display: 'none',
                  },
                }}
              />
            )}
            renderOption={(props, option): JSX.Element => {
              const { key, ...otherProps } = props;
              return (
                <li
                  key={key}
                  {...otherProps}
                >
                  <Typography sx={{ py: 1 }}>
                    {option} - {formRouteConfig[option].description}
                  </Typography>
                </li>
              );
            }}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            justifyContent: 'space-between',
          }}
        >
          <Button
            variant="outlined"
            onClick={handleBack}
            size="large"
            sx={{
              ...commonButtonStyles,
              backgroundColor: '#FFFFFF',
              borderColor: '#006699',
              color: '#006699',
              '&:hover': {
                backgroundColor: '#FFFFFF',
                opacity: 0.9,
                borderColor: '#006699',
              },
            }}
          >
            Back
          </Button>

          <Button
            variant="contained"
            onClick={handleNext}
            disabled={!selectedForm}
            size="large"
            sx={{
              ...commonButtonStyles,
              backgroundColor: '#006699',
              color: '#FFFFFF',
              borderColor: '#006699',
              '&:hover': {
                backgroundColor: '#006699',
                opacity: 0.9,
              },
            }}
          >
            Next
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default FileAForm;
