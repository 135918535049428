import { useEffect, useState } from 'react';
import { getFormConfig } from '@src/configs/formConfigs';
import { FormInitializationReturn, FormState } from '@src/types/hooks.types';
import { BaseFormValues } from 'src/types/form.types';

export const useFormInitialization = <T extends BaseFormValues>(
  formType: string | undefined,
  _safeSetState: (updater: (prevState: FormState<T>) => FormState<T>) => FormState<T> | undefined
): FormInitializationReturn<T> => {
  const [formState, setFormState] = useState<FormState<T>>({
    formConfig: undefined,
    selectedSection: '',
    selectedSubSection: '',
    expandedSections: {},
    unlockedSections: {},
  });

  useEffect(() => {
    if (!formType) return;

    setTimeout(() => {
      const config = getFormConfig<T>(formType);
      if (!config) return;

      const initialSection = config.sections[0]?.id || '';
      const initialSubSection = config.sections[0]?.subSections?.[0]?.id || '';

      // Initialize with only first section expanded and unlocked
      const initialExpandedSections = config.sections.reduce(
        (acc, section) => ({ ...acc, [section.id]: section.id === initialSection }),
        {} as Record<string, boolean>
      );

      const initialUnlockedSections = config.sections.reduce(
        (acc, section) => ({ ...acc, [section.id]: section.id === initialSection }),
        {} as Record<string, boolean>
      );

      setFormState({
        formConfig: config,
        selectedSection: initialSection,
        selectedSubSection: `${initialSection}-${initialSubSection}`,
        expandedSections: initialExpandedSections,
        unlockedSections: initialUnlockedSections,
      });
    }, 0);
  }, [formType]);

  const setSelectedSection = (section: string): void => {
    setFormState((prevState: FormState<T>) => ({
      ...prevState,
      selectedSection: section,
    }));
  };

  const setSelectedSubSection = (subSection: string): void => {
    setFormState((prev) => ({
      ...prev,
      selectedSubSection: subSection,
    }));
  };

  const setExpandedSections = (sections: Record<string, boolean>): void => {
    setFormState((prev) => ({
      ...prev,
      expandedSections: sections,
    }));
  };

  const setUnlockedSections = (sections: Record<string, boolean>): void => {
    setFormState((prev) => ({
      ...prev,
      unlockedSections: sections,
    }));
  };

  return {
    ...formState,
    setSelectedSection,
    setSelectedSubSection,
    setExpandedSections,
    setUnlockedSections,
  };
};
