import * as React from 'react';
import { CustomRadio } from '@components';
import { FormSectionComponentProps, I131FormValues } from '@src/types/form.types';

function EmploymentAuthorization(_props: FormSectionComponentProps<I131FormValues>): React.ReactNode {
  return (
    <CustomRadio
      question="Are you requesting an Employment Authorization Document (EAD) upon approval of your new period of parole (re-parole)?"
      fieldName="eadForNewOrReparole"
    />
  );
}

export default EmploymentAuthorization;
