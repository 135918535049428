import * as React from 'react';
import { TextFieldWithQuestion } from '@src/components';

const DateOfIntendedDeparture: React.FC = () => {
  return (
    <TextFieldWithQuestion
      name="proposedTravel.departureDate"
      question="What is your date of intended departure?"
      label="Date (mm/dd/yyyy)"
    />
  );
};

export default DateOfIntendedDeparture;
