export const US_STATES = [
  {
    value: 'ALA',
    label: 'Alabama',
  },
  {
    value: 'AKA',
    label: 'Alaska',
  },
  {
    value: 'ARZ',
    label: 'Arizona',
  },
  {
    value: 'ARK',
    label: 'Arkansas',
  },
  {
    value: 'CAL',
    label: 'California',
  },
  {
    value: 'COL',
    label: 'Colorado',
  },
  {
    value: 'CON',
    label: 'Connecticut',
  },
  {
    value: 'DEL',
    label: 'Delaware',
  },
  {
    value: 'FLA',
    label: 'Florida',
  },
  {
    value: 'GEO',
    label: 'Georgia',
  },
  {
    value: 'HAW',
    label: 'Hawaii',
  },
  {
    value: 'IDA',
    label: 'Idaho',
  },
  {
    value: 'ILL',
    label: 'Illinois',
  },
  {
    value: 'IND',
    label: 'Indiana',
  },
  {
    value: 'IOW',
    label: 'Iowa',
  },
  {
    value: 'KAN',
    label: 'Kansas',
  },
  {
    value: 'KEN',
    label: 'Kentucky',
  },
  {
    value: 'LOU',
    label: 'Louisiana',
  },
  {
    value: 'MAI',
    label: 'Maine',
  },
  {
    value: 'MRY',
    label: 'Maryland',
  },
  {
    value: 'MAS',
    label: 'Massachusetts',
  },
  {
    value: 'MIC',
    label: 'Michigan',
  },
  {
    value: 'MIN',
    label: 'Minnesota',
  },
  {
    value: 'MIS',
    label: 'Mississippi',
  },
  {
    value: 'MOS',
    label: 'Missouri',
  },
  {
    value: 'MON',
    label: 'Montana',
  },
  {
    value: 'NEB',
    label: 'Nebraska',
  },
  {
    value: 'NEV',
    label: 'Nevada',
  },
  {
    value: 'NHA',
    label: 'New Hampshire',
  },
  {
    value: 'NJE',
    label: 'New Jersey',
  },
  {
    value: 'NME',
    label: 'New Mexico',
  },
  {
    value: 'NYO',
    label: 'New York',
  },
  {
    value: 'NCA',
    label: 'North Carolina',
  },
  {
    value: 'NDA',
    label: 'North Dakota',
  },
  {
    value: 'OHI',
    label: 'Ohio',
  },
  {
    value: 'OKL',
    label: 'Oklahoma',
  },
  {
    value: 'ORE',
    label: 'Oregon',
  },
  {
    value: 'PEN',
    label: 'Pennsylvania',
  },
  {
    value: 'RHO',
    label: 'Rhode Island',
  },
  {
    value: 'SCA',
    label: 'South Carolina',
  },
  {
    value: 'SDA',
    label: 'South Dakota',
  },
  {
    value: 'TEN',
    label: 'Tennessee',
  },
  {
    value: 'TEX',
    label: 'Texas',
  },
  {
    value: 'UTA',
    label: 'Utah',
  },
  {
    value: 'VER',
    label: 'Vermont',
  },
  {
    value: 'VIR',
    label: 'Virginia',
  },
  {
    value: 'WAS',
    label: 'Washington',
  },
  {
    value: 'WVA',
    label: 'West Virginia',
  },
  {
    value: 'WIS',
    label: 'Wisconsin',
  },
  {
    value: 'WYO',
    label: 'Wyoming',
  },
];
