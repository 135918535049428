import { styled } from '@mui/material/styles';
import { Box, Container, Paper } from '@mui/material';
import { styleConstants } from '../constants/styleConstants';

export const LayoutContainer = styled(Container)(() => ({
  display: 'flex',
  maxWidth: '100% !important',
  height: '100vh',
  padding: 0,
}));

export const MainContent = styled(Box)(() => ({
  flexGrow: 1,
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
}));

export const Column = styled(Paper)(({ theme }) => ({
  height: '100vh',
  overflow: 'auto',
  borderRadius: 0,
  backgroundColor: theme.palette.background.paper,
}));

export const ContentSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: styleConstants.layout.form.padding.desktop,
  },
  [theme.breakpoints.down('sm')]: {
    padding: styleConstants.layout.form.padding.mobile,
  },
}));
