import * as React from 'react';
import ContactInformation from '../../SharedSubSections/ContactInformation/ContactInformation';

const ApplicantContactInformation: React.FC = () => {
  return (
    <ContactInformation
      question="Applicant's Contact Information"
      daytimePhoneLabel="Applicant's Daytime Telephone Number"
      mobilePhoneLabel="Applicant's Mobile Telephone Number (if any)"
      emailLabel="Applicant's Email Address (if any)"
      daytimePhonePath="applicantContactAndCertification.daytimePhone"
      mobilePhonePath="applicantContactAndCertification.mobilePhone"
      emailPath="applicantContactAndCertification.email"
    />
  );
};

export default ApplicantContactInformation;
