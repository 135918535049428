import { ExpandMore, ChevronRight } from '@mui/icons-material';
import { Typography, Collapse, Button, IconButton, Drawer, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useFormikContext, Form } from 'formik';
import { FormContentProps, BaseFormValues } from '@src/types/form.types';
import { useFormNavigation } from '@src/hooks/useFormHooks/useFormNavigation';
import AiChatAssistant from '../../AiChatAssistant';
import { useScrollToTop } from '@src/hooks';
import { formLayoutStyles } from '../../../styles/components/formLayoutStyles';
import { ReactElement, useState } from 'react';
import useFormSectionValidation from '../../../hooks/useFormHooks/useFormSectionValidation';

// Mobile header component
const MobileHeader = ({
  isMobileMenuOpen,
  setIsMobileMenuOpen,
  formTitle,
}: {
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: (open: boolean) => void;
  formTitle: string;
}): ReactElement => (
  <Box
    sx={formLayoutStyles.mobileHeader}
    role="banner"
  >
    <IconButton
      onClick={(): void => setIsMobileMenuOpen(!isMobileMenuOpen)}
      sx={{ mr: 2 }}
      aria-expanded={isMobileMenuOpen}
      aria-controls="navigation-menu"
      aria-label={isMobileMenuOpen ? 'Close navigation menu' : 'Open navigation menu'}
    >
      {isMobileMenuOpen ? <ExpandMore /> : <ChevronRight />}
    </IconButton>
    <Typography
      variant="h1"
      sx={{
        ...formLayoutStyles.formTitle(useTheme()),
        fontSize: '1.25rem',
      }}
    >
      {formTitle}
    </Typography>
  </Box>
);

export function FormContent<T extends BaseFormValues>({
  selectedSection,
  selectedSubSection,
  checkNextSubSection,
  checkPreviousSubSection,
  createVisibleSubSectionsMap,
  handleNext,
  handleBack,
  formConfig,
  getCurrentSubSection,
  handleSubSectionClick,
  handleSectionClick,
  expandedSections,
  unlockedSections,
  setExpandedSections,
}: FormContentProps<T>): ReactElement {
  const formik = useFormikContext<T>();
  const scrollToTop = useScrollToTop();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const theme = useTheme();

  const isSectionHidden = (section: (typeof formConfig.sections)[number]): boolean => {
    if (typeof section.hidden === 'function') {
      return section.hidden(formik.values);
    }
    return !!section.hidden;
  };

  const handleSectionNavigation = (sectionId: string): void => {
    if (selectedSection !== sectionId) {
      // Collapse current section if it's different
      handleSectionClick(selectedSection);
    }
    handleSectionClick(sectionId);
  };

  const handleSubSectionNavigation = (sectionId: string, subSectionId: string): void => {
    // First collapse all sections except the target section
    const newExpandedSections = Object.keys(expandedSections).reduce(
      (acc, key) => ({
        ...acc,
        [key]: key === sectionId,
      }),
      {}
    );

    // Update expanded sections state
    setIsMobileMenuOpen(false);
    setExpandedSections(newExpandedSections);
    handleSubSectionClick(sectionId, subSectionId);
  };

  const { previousCheck, visibleSubSectionsMap, handleNextClick, handleBackClick, isLastSection, isLastSubSection } =
    useFormNavigation<T>(
      selectedSection,
      selectedSubSection,
      formik,
      checkNextSubSection,
      checkPreviousSubSection,
      createVisibleSubSectionsMap,
      handleNext,
      handleBack,
      formConfig
    );

  const { isValid, isSubmitting, isValidating } = useFormSectionValidation(getCurrentSubSection);
  const currentSubSection = getCurrentSubSection();

  // Update the NavigationContent component
  const NavigationContent = (): ReactElement => (
    <nav
      aria-label="Form sections"
      id="navigation-menu"
    >
      <Typography
        component="h2"
        sx={formLayoutStyles.formTitle(theme)}
      >
        {formConfig.formTitle}
      </Typography>
      <ul
        role="menubar"
        style={{ listStyle: 'none', padding: 0, margin: 0 }}
      >
        {formConfig.sections.map((section) => {
          const visibleSubSections = visibleSubSectionsMap[section.id];
          const isUnlocked = unlockedSections[section.id];

          if (isSectionHidden(section)) return null;

          return (
            <li
              key={section.id}
              role="none"
            >
              <Button
                onClick={(): void => {
                  if (isUnlocked) {
                    handleSectionNavigation(section.id);
                    setIsMobileMenuOpen(false);
                  }
                }}
                aria-expanded={expandedSections[section.id]}
                aria-controls={`section-${section.id}-subsections`}
                aria-disabled={!isUnlocked}
                role="menuitem"
                aria-haspopup="true"
                sx={{
                  ...formLayoutStyles.menuButton(isUnlocked),
                  width: '100%',
                  justifyContent: 'flex-start',
                  textAlign: 'left',
                }}
              >
                {expandedSections[section.id] ? <ExpandMore /> : <ChevronRight />}
                <Box
                  component="span"
                  sx={{ ml: 1 }}
                >
                  <Typography
                    component="span"
                    role="heading"
                    aria-level={3}
                  >
                    {section.menuTitle}
                  </Typography>
                  {section.description && (
                    <Typography
                      component="span"
                      variant="body2"
                      display="block"
                    >
                      {section.description}
                    </Typography>
                  )}
                </Box>
                {!isUnlocked && <Typography sx={formLayoutStyles.srOnly}>This section is locked</Typography>}
              </Button>
              <Collapse
                in={expandedSections[section.id] && isUnlocked}
                id={`section-${section.id}-subsections`}
              >
                <ul
                  role="menu"
                  aria-label={`Subsections for ${section.menuTitle}`}
                  style={{ listStyle: 'none', padding: 0, margin: 0 }}
                >
                  {visibleSubSections?.map((subSection) => (
                    <li
                      key={`${section.id}-${subSection.id}`}
                      role="none"
                    >
                      <Button
                        onClick={(): void => {
                          if (isUnlocked) {
                            handleSubSectionNavigation(section.id, subSection.id);
                            setIsMobileMenuOpen(false);
                          }
                        }}
                        role="menuitem"
                        aria-current={selectedSubSection === `${section.id}-${subSection.id}`}
                        disabled={!isUnlocked}
                        sx={{
                          ...formLayoutStyles.menuButton(isUnlocked),
                          width: '100%',
                          justifyContent: 'flex-start',
                          textAlign: 'left',
                          pl: 4,
                        }}
                      >
                        {subSection.menuTitle}
                      </Button>
                    </li>
                  ))}
                </ul>
              </Collapse>
            </li>
          );
        })}
      </ul>
    </nav>
  );

  return (
    <Form onClick={(e): void => e.stopPropagation()}>
      <Box sx={formLayoutStyles.container}>
        <MobileHeader
          isMobileMenuOpen={isMobileMenuOpen}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
          formTitle={formConfig.formTitle}
        />

        <Box sx={formLayoutStyles.navigationWrapper}>
          <Box sx={formLayoutStyles.navigationColumn}>
            <NavigationContent />
          </Box>
        </Box>

        <Drawer
          anchor="top"
          open={isMobileMenuOpen}
          onClose={(): void => setIsMobileMenuOpen(false)}
          sx={formLayoutStyles.mobileDrawer}
          variant="temporary"
        >
          <Box sx={formLayoutStyles.menuList}>
            <NavigationContent />
          </Box>
        </Drawer>

        <Box sx={formLayoutStyles.contentColumn}>
          <Box sx={formLayoutStyles.contentSection}>
            <Box sx={formLayoutStyles.formContentWrapper}>
              {currentSubSection && (
                <currentSubSection.subSectionContainer
                  section={selectedSection}
                  subSection={selectedSubSection}
                  formikProps={{
                    ...formik,
                    configData: formConfig,
                    handleSubSectionClick,
                    handleSectionClick,
                    expandedSections,
                    setExpandedSections: setExpandedSections as React.Dispatch<
                      React.SetStateAction<Record<string, boolean>>
                    >,
                  }}
                />
              )}
              <Box sx={formLayoutStyles.buttonContainer}>
                {previousCheck.hasPrevious ? (
                  <Box sx={formLayoutStyles.backButtonWrapper}>
                    <Button
                      variant="outlined"
                      onClick={handleBackClick}
                      disabled={isSubmitting}
                      size="large"
                      sx={formLayoutStyles.backButton}
                    >
                      Back
                    </Button>
                  </Box>
                ) : (
                  <Box sx={formLayoutStyles.buttonPlaceholder} />
                )}
                <Box sx={formLayoutStyles.nextButtonWrapper}>
                  <Button
                    variant="contained"
                    onClick={async (e): Promise<void> => {
                      e.preventDefault();
                      if (isLastSection && isLastSubSection) {
                        await formik.submitForm();
                      } else {
                        await handleNextClick();
                        scrollToTop();
                      }
                    }}
                    disabled={!isValid || isSubmitting || isValidating}
                    size="large"
                    sx={formLayoutStyles.continueButton}
                  >
                    {isLastSection && isLastSubSection ? 'Submit' : 'Next'}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <AiChatAssistant formType={formConfig.formTitle} />
    </Form>
  );
}
