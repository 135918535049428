import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type TheirCountryOfCitizenshipSchemaType = Partial<I131FormValues> & {
  infoAboutThem: {
    countryOfCitizenship: string;
  };
};

const TheirCountryOfCitizenshipSchema = createPartialSchema<TheirCountryOfCitizenshipSchemaType>({
  infoAboutThem: Yup.object({
    countryOfCitizenship: Yup.string().default(''),
  }),
});

export default TheirCountryOfCitizenshipSchema;
