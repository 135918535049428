import { ReactElement, useEffect } from 'react';
import { TextField, MenuItem, Grid, InputLabel } from '@mui/material';
import { Field, FieldProps, useFormikContext } from 'formik';
import { COUNTRY } from '@src/constants/countryConstants';
import { US_STATES } from '@src/constants/stateConstants';
import { get } from 'lodash';
import QuestionWithInstruction from '@src/components/QuestionWithInstruction';

interface AddressProps {
  question?: string;
  inCareOfNamePath: string;
  streetPath: string;
  unitNumberPath: string;
  cityOrTownPath: string;
  statePath: string;
  zipCodePath: string;
  provincePath: string;
  postalCodePath: string;
  countryPath: string;
  // Will display phone number question if this prop is passed in
  daytimePhoneNumberPath?: string;
}

const Address = ({
  question,
  inCareOfNamePath,
  streetPath,
  unitNumberPath,
  cityOrTownPath,
  statePath,
  zipCodePath,
  provincePath,
  postalCodePath,
  countryPath,
  daytimePhoneNumberPath,
}: AddressProps): ReactElement => {
  const { setFieldValue, values } = useFormikContext();

  const isInternationalAddress = get(values, countryPath) !== 'USA';

  // Clears the state/zip if Country changes to international, and province/postalCode if country changes to USA
  useEffect(() => {
    if (isInternationalAddress) {
      setFieldValue(statePath, '');
      setFieldValue(zipCodePath, '');
    } else {
      setFieldValue(provincePath, '');
      setFieldValue(postalCodePath, '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInternationalAddress]);

  return (
    <div>
      <QuestionWithInstruction question={question} />
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <Field name={inCareOfNamePath}>
            {({ field, meta }: FieldProps): ReactElement => (
              <div>
                <InputLabel htmlFor={inCareOfNamePath}>In care of name (if any)</InputLabel>
                <TextField
                  {...field}
                  fullWidth
                  id={inCareOfNamePath}
                  variant="outlined"
                  error={meta.touched && Boolean(meta.error)}
                  helperText={meta.touched && meta.error}
                />
              </div>
            )}
          </Field>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <Field name={countryPath}>
            {({ field, meta }: FieldProps): ReactElement => (
              <div>
                <InputLabel
                  required
                  htmlFor={countryPath}
                >
                  Country
                </InputLabel>
                <TextField
                  {...field}
                  fullWidth
                  select
                  id={countryPath}
                  variant="outlined"
                  error={meta.touched && Boolean(meta.error)}
                  helperText={meta.touched && meta.error}
                >
                  {COUNTRY.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            )}
          </Field>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <Field name={streetPath}>
            {({ field, meta }: FieldProps): ReactElement => (
              <div>
                <InputLabel
                  required
                  htmlFor={streetPath}
                >
                  Street number and name
                </InputLabel>
                <TextField
                  {...field}
                  fullWidth
                  id={streetPath}
                  variant="outlined"
                  error={meta.touched && Boolean(meta.error)}
                  helperText={meta.touched && meta.error}
                />
              </div>
            )}
          </Field>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Field name={unitNumberPath}>
            {({ field, meta }: FieldProps): ReactElement => (
              <div>
                <InputLabel htmlFor={unitNumberPath}>Apartment, suite, unit, or floor</InputLabel>
                <TextField
                  {...field}
                  fullWidth
                  id={unitNumberPath}
                  variant="outlined"
                  error={meta.touched && Boolean(meta.error)}
                  helperText={meta.touched && meta.error}
                />
              </div>
            )}
          </Field>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <Field name={cityOrTownPath}>
            {({ field, meta }: FieldProps): ReactElement => (
              <div>
                <InputLabel
                  required
                  htmlFor={cityOrTownPath}
                >
                  City or town
                </InputLabel>
                <TextField
                  {...field}
                  fullWidth
                  id={cityOrTownPath}
                  variant="outlined"
                  error={meta.touched && Boolean(meta.error)}
                  helperText={meta.touched && meta.error}
                />
              </div>
            )}
          </Field>
        </Grid>

        {isInternationalAddress ? (
          <Grid
            item
            xs={12}
          >
            <Field name={provincePath}>
              {({ field, meta }: FieldProps): ReactElement => (
                <div>
                  <InputLabel htmlFor={provincePath}>Province</InputLabel>
                  <TextField
                    {...field}
                    fullWidth
                    id={provincePath}
                    variant="outlined"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                  />
                </div>
              )}
            </Field>
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
          >
            <Field name={statePath}>
              {({ field, meta }: FieldProps): ReactElement => (
                <div>
                  <InputLabel
                    required
                    htmlFor={statePath}
                  >
                    State
                  </InputLabel>
                  <TextField
                    {...field}
                    fullWidth
                    select
                    id={statePath}
                    variant="outlined"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                  >
                    {US_STATES.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              )}
            </Field>
          </Grid>
        )}
        <Grid
          item
          xs={12}
        >
          <Field name={isInternationalAddress ? postalCodePath : zipCodePath}>
            {({ field, meta }: FieldProps): ReactElement => (
              <div>
                <InputLabel
                  required
                  htmlFor={isInternationalAddress ? postalCodePath : zipCodePath}
                >
                  {isInternationalAddress ? 'Postal code' : 'Zip code'}
                </InputLabel>
                <TextField
                  {...field}
                  fullWidth
                  id={isInternationalAddress ? postalCodePath : zipCodePath}
                  variant="outlined"
                  error={meta.touched && Boolean(meta.error)}
                  helperText={meta.touched && meta.error}
                />
              </div>
            )}
          </Field>
        </Grid>
        {daytimePhoneNumberPath && (
          <Grid
            item
            xs={12}
          >
            <Field name={daytimePhoneNumberPath}>
              {({ field, meta }: FieldProps): ReactElement => (
                <div>
                  <InputLabel
                    required
                    htmlFor={daytimePhoneNumberPath}
                  >
                    Daytime Phone Number
                  </InputLabel>
                  <TextField
                    {...field}
                    fullWidth
                    id={daytimePhoneNumberPath}
                    variant="outlined"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                  />
                </div>
              )}
            </Field>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Address;
