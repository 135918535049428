import { Theme, SxProps } from '@mui/material';

export const radioStyles = {
  questionContainer: {
    width: '100%',
    maxWidth: '100%',
    mb: 3,
  } as const,

  radioContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '100%',
    overflow: 'visible',
    px: {
      xs: 2,
      sm: 2,
    },
  } as const,

  formControl: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '100%', // Ensure form control doesn't overflow
    overflow: 'hidden', // Prevent horizontal scroll
  } as const,

  getQuestionLabelStyles: (theme: Theme): SxProps<Theme> => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: {
      xs: theme.typography.h6.fontSize,
      sm: theme.typography.h5.fontSize,
      md: theme.typography.h4.fontSize,
      lg: theme.typography.h3.fontSize,
    },
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.text.primary,
    textAlign: 'left',
    maxWidth: '100%',
    mb: 3,
    '&.Mui-focused': {
      color: theme.palette.text.primary,
    },
  }),
  getInstructionsLabelStyles: (theme: Theme): SxProps<Theme> => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: {
      xs: theme.typography.body2.fontSize,
      sm: theme.typography.body1.fontSize,
    },
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    textAlign: 'left',
    maxWidth: '100%',
    mb: 3,
    '&.Mui-focused': {
      color: theme.palette.text.primary,
    },
  }),

  getRadioControlStyles: (hasIcon: boolean): SxProps<Theme> => ({
    display: hasIcon ? 'block' : 'none',
    '& svg': {
      fontSize: {
        xs: 20,
        sm: 24,
        md: 28,
        lg: 32,
      },
    },
    '&.Mui-focused': {
      bgcolor: 'primary.100',
      borderRadius: '50%',
    },
    padding: {
      xs: '4px',
      sm: '6px',
      md: '8px',
    },
    '& .MuiRadio-root': {
      // Ensure radio scales with parent
      transform: 'scale(0.8)',
      '@media (min-width:600px)': {
        transform: 'scale(0.9)',
      },
      '@media (min-width:900px)': {
        transform: 'scale(1)',
      },
    },
  }),

  getErrorStyles: (theme: Theme): SxProps<Theme> => ({
    mt: 1,
    textAlign: 'center',
    fontSize: theme.typography.body2.fontSize,
    color: 'error.main',
  }),

  getRadioGroupStyles: (direction: 'row' | 'column'): SxProps<Theme> => ({
    display: 'flex',
    flexDirection: {
      xs: 'column',
      sm: direction,
    },
    gap: 2,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '100%', // Ensure group doesn't overflow
  }),

  getFormControlLabelStyles: (theme: Theme, hasIcon: boolean): SxProps<Theme> => ({
    margin: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '.MuiFormControlLabel-label': {
      fontSize: {
        xs: '0.875rem', // Smaller font size on mobile
        sm: theme.typography.h5.fontSize,
        md: theme.typography.h4.fontSize,
      },
      mt: hasIcon ? 1 : 0,
      textAlign: 'center',
      color: theme.palette.text.primary,
      lineHeight: {
        xs: 1.2,
        sm: 1.3,
        md: 1.4,
      },
      px: {
        xs: 2, // Less padding on mobile
        sm: 4,
        md: 5,
      },
      py: {
        xs: 1.5,
        sm: 2,
      },
    },
  }),

  getRadioBoxStyles: (theme: Theme, isSelected: boolean): SxProps<Theme> => ({
    border: `${isSelected ? 2 : 1}px solid`,
    borderColor: isSelected ? '#1D89BF' : '#E5E7EB',
    borderRadius: 1,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: {
      xs: '100px',
      sm: '140px',
      md: '158px',
    },
    // Remove fixed widths to allow parent control
    width: '100%',
    maxWidth: '100%',
    height: 'auto',
    mx: 0, // Remove margin to allow full width
    p: {
      xs: 1,
      sm: 1.5,
      md: 2,
    },
    bgcolor: isSelected ? '#E6F5FD' : '#FFFFFF',
    '&:hover': {
      borderColor: '#1D89BF',
      bgcolor: isSelected ? '#E6F5FD' : '#FFFFFF',
    },
    transition: theme.transitions.create(['border-color', 'background-color', 'transform'], {
      duration: 200,
    }),
    userSelect: 'none',
    outline: 'none',
    position: 'relative',
    boxSizing: 'border-box',
    // ... rest of the styles remain the same ...
  }),
} as const;
