import * as React from 'react';
import { ExplanationBox } from '@src/components';

const PurposeOfTrip: React.FC = () => {
  return (
    <ExplanationBox
      name={'proposedTravel.purpose'}
      question="What is the purpose of your trip?"
    />
  );
};

export default PurposeOfTrip;
