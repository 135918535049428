import * as React from 'react';
import { ReactElement } from 'react';
import { Field, FieldProps } from 'formik';
import { FormControl, TextField, Typography } from '@mui/material';
import QuestionWithInstruction from '@src/components/QuestionWithInstruction';

const I94ExpirationDate: React.FC = () => {
  return (
    <Field name="applicantInfo.expirationDate">
      {({ field, meta }: FieldProps): ReactElement => (
        <FormControl
          fullWidth
          error={meta.touched && !!meta.error}
        >
          <QuestionWithInstruction
            question={'What is the expiration date of authorized stay shown on your Form I-94? (if any)'}
          />
          <TextField
            {...field}
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#E0E0E0',
                },
                '&:hover fieldset': {
                  borderColor: '#BDBDBD',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'primary.main',
                },
              },
              '& .MuiInputBase-input': {
                padding: '12px 16px',
              },
            }}
          />

          {meta.touched && meta.error && (
            <Typography
              color="error"
              variant="caption"
              sx={{ mt: 1 }}
            >
              {meta.error}
            </Typography>
          )}
        </FormControl>
      )}
    </Field>
  );
};

export default I94ExpirationDate;
