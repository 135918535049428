import * as Yup from 'yup';
import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import { booleanSchema } from '@src/utils/I131.schema';

type previousProceedingsSchemaType = Partial<I131FormValues> & {
  processingInfo: {
    previousProceedings: string;
  };
};

const previousProceedingsSchema = createPartialSchema<previousProceedingsSchemaType>({
  processingInfo: Yup.object({
    previousProceedings: booleanSchema,
  }),
});

export default previousProceedingsSchema;
