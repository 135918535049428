import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type GenderSchemaType = Partial<I131FormValues> & {
  applicantInfo: {
    gender: string;
  };
};

const GenderSchema = createPartialSchema<GenderSchemaType>({
  applicantInfo: Yup.object({
    gender: Yup.string().default('').oneOf(['male', 'female', 'anotherGenderIdentity', '']),
  }),
});

export default GenderSchema;
