import * as Yup from 'yup';
import { booleanSchema } from '@src/utils/I131.schema';
import { createPartialSchema, I131FormValues } from '@src/types/form.types';

type HasPreparerSchemaType = Partial<I131FormValues> & {
  preparerContactAndCertification: {
    hasPreparer: string;
  };
};

const HasPreparerSchema = createPartialSchema<HasPreparerSchemaType>({
  preparerContactAndCertification: Yup.object({
    hasPreparer: booleanSchema,
  }),
});

export default HasPreparerSchema;
