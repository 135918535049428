import { useState, useEffect, useCallback, useRef } from 'react';
import { useFormikContext } from 'formik';
import { BaseFormValues, SubSection } from '@src/types/form.types';

interface ValidationState {
  isValid: boolean;
  isSubmitting: boolean;
  isValidating: boolean;
  validationError?: string | null;
}

interface YupValidationError extends Error {
  inner: Array<{
    path: string;
    message: string;
  }>;
}

const useFormSectionValidation = <T extends BaseFormValues>(
  getCurrentSubSection: () => SubSection<T> | null,
  options: {
    debug?: boolean;
    validationDelay?: number;
  } = {}
): ValidationState => {
  const { debug = false, validationDelay = 100 } = options;

  const { values, isSubmitting, isValidating, setFieldError } = useFormikContext<T>();

  const [validationState, setValidationState] = useState<ValidationState>({
    isValid: true,
    isSubmitting: false,
    isValidating: false,
    validationError: null,
  });

  const timerRef = useRef<NodeJS.Timeout>();

  const validateCurrentSection = useCallback(async () => {
    const currentSubSection = getCurrentSubSection();

    if (!currentSubSection?.validation) {
      if (debug) {
        console.debug('[Validation] No validation schema found for current subsection');
      }
      setValidationState((prev) => ({
        ...prev,
        isValid: true,
        validationError: null,
      }));
      return;
    }

    try {
      await currentSubSection.validation.validate(values, {
        abortEarly: false,
        strict: false,
      });

      if (debug) {
        console.debug('[Validation] Validation successful');
      }

      setValidationState((prev) => ({
        ...prev,
        isValid: true,
        validationError: null,
      }));
    } catch (error) {
      if (debug) {
        console.error('[Validation] Validation failed:', error);
      }

      const errorMessage = error instanceof Error ? error.message : 'Validation failed';

      setValidationState((prev) => ({
        ...prev,
        isValid: false,
        validationError: errorMessage,
      }));

      // Set field-specific errors if available
      if (error instanceof Error && 'inner' in error) {
        const yupError = error as YupValidationError;
        yupError.inner.forEach(({ path, message }) => {
          setFieldError(path, message);
        });
      }
    }
  }, [getCurrentSubSection, values, debug, setFieldError]);

  // Effect to trigger validation
  useEffect(() => {
    // Skip validation if already validating or submitting
    if (isValidating || isSubmitting) {
      if (debug) {
        console.debug('[Validation] Skipping validation - already validating/submitting');
      }
      return;
    }

    // Update submitting/validating state without triggering a validation
    if (validationState.isSubmitting !== isSubmitting || validationState.isValidating !== isValidating) {
      setValidationState((prev) => ({
        ...prev,
        isSubmitting,
        isValidating,
      }));
    }

    // Clear existing timer
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Set new timer for validation
    timerRef.current = setTimeout(validateCurrentSection, validationDelay);

    // Cleanup
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [
    validateCurrentSection,
    isValidating,
    isSubmitting,
    validationDelay,
    debug,
    validationState.isSubmitting,
    validationState.isValidating,
  ]);

  // Effect to clear validation error when subsection changes
  useEffect(() => {
    const currentSubSection = getCurrentSubSection();
    if (currentSubSection) {
      setValidationState((prev) => ({
        ...prev,
        validationError: null,
      }));
    }
  }, [getCurrentSubSection]);

  return validationState;
};

export default useFormSectionValidation;
