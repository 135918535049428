import { UseQueryOptions } from '@tanstack/react-query';
import { FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';

export type ApplicationType = '' | 'reentry' | 'refugee' | 'advance';

export interface BaseFormValues {
  formStatus?: string;
  lastUpdated?: string;
}

export type DraftLoadType = {
  id: number;
  formData: I131FormValues & { formStatus: string; lastUpdated?: string };
  [key: string]: I131FormValues | number | string | null;
};

// Do not be too specific if you want this to be more generic
export interface I131FormValues extends BaseFormValues {
  applicationType: string;
  tpsBeneficiary: {
    tpsReceiptNumber: string;
  };
  inUsAdvanceParoleDocument: {
    program: string;
    receiptNumber: string;
  };
  outsideUsInitParoleDocument: {
    program: string;
    forPerson: string;
    receiptNumber: string;
    govExecBranch: string;
    repEmailAddress: string;
    otherProgram: string;
  };
  selfOrOtherInUsInitParole: {
    program: string;
    forPersonStatus: string;
    receiptNumber: string;
    otherProgram: string;
  };
  selfOrOtherRenewParole: {
    program: string;
    forPerson: string;
    forPersonStatus: string;
    receiptNumber: string;
    otherProgram: string;
  };
  i94AdmittedDate: string;
  refugeeOrLprRefugee: string;
  biographicInfo: {
    ethnicity: string;
    race: string[];
    height: {
      feet: string;
      inches: string;
    };
    weight: string;
    eyeColor: string;
    hairColor: string;
  };
  applicantInfo: {
    fullName: { givenName: string; middleName: string; familyName: string };
    otherNames: { givenName: string; middleName: string; familyName: string }[];
    currentMailingAddress: {
      inCareOfName: string;
      street: string;
      unitType: string;
      unitNumber: string;
      cityOrTown: string;
      state: string;
      zipCode: string;
      province: string;
      postalCode: string;
      country: string;
    };
    isPhysicalSameAsMailing: string;
    currentPhysicalAddress: {
      inCareOfName: string;
      street: string;
      unitType: string;
      unitNumber: string;
      cityOrTown: string;
      state: string;
      zipCode: string;
      province: string;
      postalCode: string;
      country: string;
    };
    alienRegistrationNumber: string;
    countryOfBirth: string;
    countryOfCitizenship: string;
    gender: string;
    dateOfBirth: string;
    socialSecurityNumber: string;
    uscisOnlineAccountNumber: string;
    classOfAdmission: string;
    i94Number: string;
    expirationDate: string;
    eMedicalUSParoleeID: string;
    applyingForSomeoneElse: string;
  };
  infoAboutThem: {
    fullName: { givenName: string; middleName: string; familyName: string };
    otherNames: { givenName: string; middleName: string; familyName: string }[];
    currentMailingAddress: {
      inCareOfName: string;
      street: string;
      unitType: string;
      unitNumber: string;
      cityOrTown: string;
      state: string;
      zipCode: string;
      province: string;
      postalCode: string;
      country: string;
    };
    isPhysicalSameAsMailing: string;
    currentPhysicalAddress: {
      inCareOfName: string;
      street: string;
      unitType: string;
      unitNumber: string;
      cityOrTown: string;
      state: string;
      zipCode: string;
      province: string;
      postalCode: string;
      country: string;
    };
    alienRegistrationNumber: string;
    countryOfBirth: string;
    countryOfCitizenship: string;
    dateOfBirth: string;
    classOfAdmission: string;
    i94Number: string;
    daytimePhone: string;
    emailAddress: string;
  };
  processingInfo: {
    previousProceedings: string;
    previousReentryPermit: {
      issued: string;
      dateIssued: string;
      disposition: string;
    };
    previousAdvanceParole: {
      issued: string;
      dateIssued: string;
      disposition: string;
    };
    requestReplacement: string;
    reasonForReplacement: string;
    infoCorrection: string[];
    infoCorrectionExplanation: string;
    replacementReceiptNumber: string;
    documentSendTo: {
      toUsAddressOrEmbassy: string;
      embassyCityOrTown: string;
      embassyCountry: string;
    };
    noticeSendTo: {
      selectDestination: string;
      address: {
        inCareOfName: string;
        street: string;
        unitType: string;
        unitNumber: string;
        cityOrTown: string;
        state: string;
        zipCode: string;
        province: string;
        postalCode: string;
        country: string;
      };
      daytimePhone: string;
      emailAddress: string;
    };
  };
  refugeeTravelDocumentInfo: {
    originCountry: string;
    planToTravelBack: string;
    planToTravelBackExplanation: string;
    returnToCountry: string;
    returnToCountryExplanation: string;
    appliedForPassport: string;
    appliedForPassportExplanation: string;
    receiveBenefits: string;
    receiveBenefitsExplanation: string;
    reacquiredNationality: string;
    reacquiredNationalityExplanation: string;
    acquiredNewNationality: string;
    acquiredNewNationalityExplanation: string;
    grantedAsyleeForOtherCountry: string;
    grantedAsyleeForOtherCountryExplanation: string;
    fileForRefugeeBeforeLeavingUs: string;
    fileForRefugeeBeforeLeavingUsExplanation: string;
    currentlyOutsideUs: string;
    currentLocation: string;
    otherCountries: string;
  };
  totalTimeOutsideUs: string;
  proposedTravel: {
    departureDate: string;
    purpose: string;
    countriesIntendedToVisit: string;
    numberOfTrips: string;
    expectedLength: string;
  };
  initPipReparole: {
    intendedRecipientOutsideOfUS: string;
    qualification: string;
    lengthOfStayInUs: string;
    dateOfIntendedArrival: string;
    locationToNotify: {
      cityOrTown: string;
      country: string;
    };
  };
  eadForNewOrReparole: string;
  applicantContactAndCertification: {
    daytimePhone: string;
    mobilePhone: string;
    email: string;
    signature: string;
    dateOfSignature: string;
  };
  interpreterContactAndCertification: {
    hasInterpreter: string;
    fullName: {
      familyName: string;
      givenName: string;
    };
    businessName: string;
    daytimePhone: string;
    mobilePhone: string;
    email: string;
    language: string;
    signature: string;
    dateOfSignature: string;
  };
  preparerContactAndCertification: {
    hasPreparer: string;
    fullName: {
      familyName: string;
      givenName: string;
    };
    businessName: string;
    daytimePhone: string;
    mobilePhone: string;
    email: string;
    signature: string;
    dateOfSignature: string;
  };
  hideSection2?: boolean;
}

export interface FormSectionComponentProps<T extends BaseFormValues = BaseFormValues> {
  section: string;
  subSection: string;
  formikProps: FormikProps<T> & {
    configData: FormConfig<T>;
    handleSubSectionClick: (sectionId: string, subSectionId: string) => void;
    handleSectionClick: (sectionId: string) => void;
    expandedSections: Record<string, boolean>;
    setExpandedSections: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  };
}

export interface SubSection<T extends BaseFormValues = BaseFormValues> {
  id: string;
  menuTitle: string;
  subSectionContainer: React.ComponentType<FormSectionComponentProps<T>>;
  validation?: Yup.ObjectSchema<Partial<T>>;
  hidden: boolean | ((values: T) => boolean);
}

export interface Section<T extends BaseFormValues = BaseFormValues> {
  id: string;
  menuTitle: string;
  description: string;
  subSectionContainer?: React.ComponentType<FormSectionComponentProps>;
  subSections?: SubSection<T>[];
  initialValues?: Partial<T>;
  cleanup?: (setFieldValue: FormikHelpers<T>['setFieldValue']) => void;
  hidden?: (values: T) => boolean;
}

export interface FormConfig<T extends BaseFormValues> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  f2j: (value: any) => any;
  formId: string;
  formTitle: string;
  initialValues: T;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  j2f: (value: any) => any;
  sections: Section<T>[];
}

export const DEFAULT_FORM_VALUES: I131FormValues = {
  formStatus: 'draft',
  lastUpdated: new Date().toISOString(),
  applicationType: '',
  tpsBeneficiary: {
    tpsReceiptNumber: '',
  },
  inUsAdvanceParoleDocument: {
    program: '',
    receiptNumber: '',
  },
  outsideUsInitParoleDocument: {
    program: '',
    forPerson: '',
    receiptNumber: '',
    govExecBranch: '',
    repEmailAddress: '',
    otherProgram: '',
  },
  selfOrOtherInUsInitParole: {
    program: '',
    forPersonStatus: '',
    receiptNumber: '',
    otherProgram: '',
  },
  selfOrOtherRenewParole: {
    program: '',
    forPerson: '',
    forPersonStatus: '',
    receiptNumber: '',
    otherProgram: '',
  },
  applicantInfo: {
    fullName: {
      givenName: '',
      middleName: '',
      familyName: '',
    },
    otherNames: [
      {
        givenName: '',
        middleName: '',
        familyName: '',
      },
    ],
    currentMailingAddress: {
      inCareOfName: '',
      street: '',
      unitType: '',
      unitNumber: '',
      cityOrTown: '',
      state: '',
      zipCode: '',
      province: '',
      postalCode: '',
      country: '',
    },
    isPhysicalSameAsMailing: '',
    currentPhysicalAddress: {
      inCareOfName: '',
      street: '',
      unitType: '',
      unitNumber: '',
      cityOrTown: '',
      state: '',
      zipCode: '',
      province: '',
      postalCode: '',
      country: '',
    },
    alienRegistrationNumber: '',
    countryOfBirth: '',
    countryOfCitizenship: '',
    gender: '',
    dateOfBirth: '',
    socialSecurityNumber: '',
    uscisOnlineAccountNumber: '',
    classOfAdmission: '',
    i94Number: '',
    expirationDate: '',
    eMedicalUSParoleeID: '',
    applyingForSomeoneElse: '',
  },
  infoAboutThem: {
    fullName: {
      givenName: '',
      middleName: '',
      familyName: '',
    },
    otherNames: [
      {
        givenName: '',
        middleName: '',
        familyName: '',
      },
    ],
    currentMailingAddress: {
      inCareOfName: '',
      street: '',
      unitType: '',
      unitNumber: '',
      cityOrTown: '',
      state: '',
      zipCode: '',
      province: '',
      postalCode: '',
      country: '',
    },
    isPhysicalSameAsMailing: '',
    currentPhysicalAddress: {
      inCareOfName: '',
      street: '',
      unitType: '',
      unitNumber: '',
      cityOrTown: '',
      state: '',
      zipCode: '',
      province: '',
      postalCode: '',
      country: '',
    },
    alienRegistrationNumber: '',
    countryOfBirth: '',
    countryOfCitizenship: '',
    dateOfBirth: '',
    classOfAdmission: '',
    i94Number: '',
    emailAddress: '',
    daytimePhone: '',
  },
  i94AdmittedDate: '',
  refugeeOrLprRefugee: '',
  biographicInfo: {
    ethnicity: '',
    race: [''],
    height: {
      feet: '',
      inches: '',
    },
    weight: '',
    eyeColor: '',
    hairColor: '',
  },
  processingInfo: {
    previousProceedings: '',
    previousReentryPermit: {
      issued: '',
      dateIssued: '',
      disposition: '',
    },
    previousAdvanceParole: {
      issued: '',
      dateIssued: '',
      disposition: '',
    },
    requestReplacement: '',
    reasonForReplacement: '',
    infoCorrection: [''],
    infoCorrectionExplanation: '',
    replacementReceiptNumber: '',
    documentSendTo: {
      toUsAddressOrEmbassy: '',
      embassyCityOrTown: '',
      embassyCountry: '',
    },
    noticeSendTo: {
      selectDestination: '',
      address: {
        inCareOfName: '',
        street: '',
        unitType: '',
        unitNumber: '',
        cityOrTown: '',
        state: '',
        zipCode: '',
        province: '',
        postalCode: '',
        country: '',
      },
      daytimePhone: '',
      emailAddress: '',
    },
  },
  refugeeTravelDocumentInfo: {
    originCountry: '',
    planToTravelBack: '',
    planToTravelBackExplanation: '',
    returnToCountry: '',
    returnToCountryExplanation: '',
    appliedForPassport: '',
    appliedForPassportExplanation: '',
    receiveBenefits: '',
    receiveBenefitsExplanation: '',
    reacquiredNationality: '',
    reacquiredNationalityExplanation: '',
    acquiredNewNationality: '',
    acquiredNewNationalityExplanation: '',
    grantedAsyleeForOtherCountry: '',
    grantedAsyleeForOtherCountryExplanation: '',
    fileForRefugeeBeforeLeavingUs: '',
    fileForRefugeeBeforeLeavingUsExplanation: '',
    currentlyOutsideUs: '',
    currentLocation: '',
    otherCountries: '',
  },
  totalTimeOutsideUs: '',
  proposedTravel: {
    departureDate: '',
    purpose: '',
    countriesIntendedToVisit: '',
    numberOfTrips: '',
    expectedLength: '',
  },
  initPipReparole: {
    intendedRecipientOutsideOfUS: '',
    qualification: '',
    lengthOfStayInUs: '',
    dateOfIntendedArrival: '',
    locationToNotify: {
      cityOrTown: '',
      country: '',
    },
  },
  eadForNewOrReparole: '',
  applicantContactAndCertification: {
    daytimePhone: '',
    mobilePhone: '',
    email: '',
    signature: '',
    dateOfSignature: '',
  },
  interpreterContactAndCertification: {
    hasInterpreter: '',
    fullName: {
      familyName: '',
      givenName: '',
    },
    daytimePhone: '',
    mobilePhone: '',
    businessName: '',
    email: '',
    language: '',
    signature: '',
    dateOfSignature: '',
  },
  preparerContactAndCertification: {
    hasPreparer: '',
    fullName: {
      familyName: '',
      givenName: '',
    },
    daytimePhone: '',
    mobilePhone: '',
    businessName: '',
    email: '',
    signature: '',
    dateOfSignature: '',
  },
};

export function isI131FormValues(value: unknown): value is I131FormValues {
  // TODO(myles): There's a safer way to do this, but for now, we just need to
  // be able to narrow the type to the only form that we currently have.
  return (value as I131FormValues).applicationType !== undefined;
}

export interface BaseFormPageProps {
  onMenuItemClick?: (section: string) => void;
}

export interface MenuItem {
  id: string;
  label: string;
}

export interface ChatMessage {
  text: string;
  isBot: boolean;
  timestamp: Date;
}

export interface LocationState {
  formType?: string;
}

export type FormCategory = 'Immigration' | 'Citizenship' | 'Travel' | 'Family' | 'Employment';
export type FormStatus = 'ready' | 'inProgress' | 'notAvailable';

export interface FileAFormConfig {
  route: string;
  formValue?: string;
  description: string;
}

export type FormOption = 'I-131v2';

export type ApplicationFormProps = FormSectionComponentProps;

export interface FieldRenderProps {
  field: {
    name: string;
    value: string;
    onChange: (
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLDivElement>
    ) => void;
    onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLDivElement>) => void;
  };
}

export interface FormDataError {
  message: string;
  code?: string;
  details?: unknown;
}

export interface FormDataOptions extends Omit<UseQueryOptions, 'queryKey' | 'queryFn'> {
  cacheTime?: number;
  staleTime?: number;
  refetchOnWindowFocus?: boolean;
  enabled?: boolean;
}

export interface SaveFormDataMutationOptions {
  onError?: (error: FormDataError) => void;
}

export interface NavigationCheck {
  hasNext: boolean;
  nextSection?: string;
}

export interface PreviousCheck {
  hasPrevious: boolean;
  previousSection?: string;
  shouldUpdateApplicationType?: boolean;
  newApplicationType?: string;
}

export interface NavigationHelpers {
  isLastSection: boolean;
  isLastSubSection: boolean;
}

export interface FormContentProps<T extends BaseFormValues> {
  selectedSection: string;
  selectedSubSection: string;
  checkNextSubSection: (currentSectionId: string, currentSubSectionId: string, values: T) => NavigationCheck;
  checkPreviousSubSection: (currentSectionId: string, currentSubSectionId: string, values: T) => PreviousCheck;
  createVisibleSubSectionsMap: (values: T) => Record<string, SubSection<T>[]>;
  handleNext: (formikProps: FormikProps<T>) => Promise<void>;
  handleBack: (formikProps: FormikProps<T>) => Promise<void>;
  formConfig: FormConfig<T>;
  getCurrentSubSection: () => SubSection<T> | null;
  handleSubSectionClick: (sectionId: string, subSectionId: string) => void;
  handleSectionClick: (sectionId: string) => void;
  expandedSections: Record<string, boolean>;
  unlockedSections: Record<string, boolean>;
  setExpandedSections: (
    sections: Record<string, boolean> | ((prev: Record<string, boolean>) => Record<string, boolean>)
  ) => void;
}

export interface FormFactoryProps {
  onMenuItemClick?: (sectionId: string) => void;
}

// Base type for all schemas
export type BaseSchema = {
  formStatus?: string;
  lastUpdated?: string;
  applicationType?: string;
};

// Update the createPartialSchema helper
export const createPartialSchema = <T extends Partial<I131FormValues>>(shape: Yup.ObjectShape): Yup.ObjectSchema<T> => {
  return Yup.object().shape(shape) as Yup.ObjectSchema<T>;
};
