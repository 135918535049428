import * as React from 'react';
import { COUNTRY } from '@src/constants/countryConstants';
import FlexibleDropdown from '@src/components/FlexibleDropdown';

export const COUNTRY_OF_CITIZENSHIP = {
  CITIZENSHIP_COUNTRY: {
    fieldKey: 'applicantInfo.countryOfCitizenship',
    question: 'What is your country of citizenship?',
  },
};

const CountryOfCitizenship: React.FC = () => {
  return (
    <FlexibleDropdown
      leftName={COUNTRY_OF_CITIZENSHIP.CITIZENSHIP_COUNTRY.fieldKey}
      question={COUNTRY_OF_CITIZENSHIP.CITIZENSHIP_COUNTRY.question}
      instructions="Provide the name of the country of your citizenship. Use the current names of the country of your citizenship. If you do not have citizenship in any country, select  “Stateless.”"
      leftOptions={COUNTRY}
    />
  );
};

export default CountryOfCitizenship;
