import { ReactElement } from 'react';
import { Box } from '@mui/material';
import FlexibleDropdown from '@src/components/FlexibleDropdown';
import { TextFieldWithQuestion } from '@src/components';

type HeightOption = {
  value: string;
  label: string;
};

const generateHeightOptions = (min: number, max: number): HeightOption[] => {
  return Array.from({ length: max - min + 1 }, (_, i) => ({
    value: (i + min).toString(),
    label: (i + min).toString(),
  }));
};

const HeightWeight = (): ReactElement => {
  const feetOptions = generateHeightOptions(0, 9);
  const inchesOptions = generateHeightOptions(0, 11);

  return (
    <>
      <Box sx={{ mt: 2, mb: 2 }}>
        <FlexibleDropdown
          question="What is the height of the person who will receive the document?"
          leftName="biographicInfo.height.feet"
          rightName="biographicInfo.height.inches"
          leftLabel="Feet"
          rightLabel="Inches"
          leftOptions={feetOptions}
          rightOptions={inchesOptions}
        />
      </Box>
      <TextFieldWithQuestion
        name="biographicInfo.weight"
        question="What is the weight of the person who will receive the document?"
        label="Pounds"
        maxCharacters={3}
        numericValueOnly
      />
    </>
  );
};

export default HeightWeight;
