import { ReactElement } from 'react';
import { useFormikContext } from 'formik';
import { Box } from '@mui/material';
import { CustomRadio, TextFieldWithQuestion } from '@src/components';
import { I131Schema } from '@src/utils/I131.schema';

function SelfOtherRenewParoleInfo(): ReactElement {
  const { values, setFieldValue } = useFormikContext<I131Schema>();

  const renewParoleOptions = [
    { value: 'A', label: 'Family Reunification Parole Process' },
    { value: 'B', label: 'Certain Afghans Paroled Into the United States After July 31, 2021' },
    { value: 'C', label: 'Certain Ukrainians Paroled Into the United States After February 24, 2022' },
    { value: 'D', label: 'Filipino World War II Veterans Parole (FWVP) Program' },
    { value: 'E', label: 'Immigrant Military Members and Veterans Initiative (IMMVI)' },
    { value: 'F', label: 'Central American Minors (CAM) Program' },
    { value: 'G', label: 'Family Reunification Task Force (FRTF) Process' },
    { value: 'H', label: 'Military Parole in Place (Military PIP)' },
    { value: 'I', label: 'Other: (List specific program or process)' },
  ];

  const immviPersonOptions = [
    { value: '1', label: 'A current or former service member' },
    {
      value: '2',
      label:
        'A current spouse, child, or unmarried son or daughter (or their child under 21 years of age) of a current or former service member',
    },
    { value: '3', label: 'Current legal guardian or surrogate of a current or former service member' },
  ];

  const militaryPipOptions = [
    { value: '1', label: 'A current or former service member' },
    { value: '2', label: 'A spouse, parent, son, or daughter of a current or former service member' },
  ];

  const clearRenewParoleFields = (program: string): void => {
    switch (program) {
      case 'E':
        setFieldValue('selfOrOtherRenewParole.forPersonStatus', '');
        setFieldValue('selfOrOtherRenewParole.otherProgram', '');
        break;
      case 'H':
        setFieldValue('selfOrOtherRenewParole.forPerson', '');
        setFieldValue('selfOrOtherRenewParole.otherProgram', '');
        break;
      case 'I':
        setFieldValue('selfOrOtherRenewParole.forPerson', '');
        setFieldValue('selfOrOtherRenewParole.forPersonStatus', '');
        break;
    }
  };

  const handleProgramChange = (value: string): void => {
    clearRenewParoleFields(value);
  };

  return (
    <Box sx={{ py: 4 }}>
      <CustomRadio
        fieldName="selfOrOtherRenewParole.program"
        question="Arrival/Departure Records for Re-parole for Noncitizens Who Are Requesting a New Period of Parole (from inside the United States)"
        instructions="I was initially paroled into the United States or granted parole in place under INA section 212(d)(5)(A) under a specific program or process and I am requesting a new period of parole, or I am applying for a new period of parole on behalf of someone else who was initially paroled into the United States under a specific parole program or process."
        options={renewParoleOptions}
        direction="column"
        required
        onChange={handleProgramChange}
      />
      {values.selfOrOtherRenewParole?.program === 'E' && (
        <CustomRadio
          fieldName="selfOrOtherRenewParole.forPerson"
          question="Who is the qualifying member?"
          options={immviPersonOptions}
          direction="column"
          required
        />
      )}

      {values.selfOrOtherRenewParole?.program === 'H' && (
        <CustomRadio
          fieldName="selfOrOtherRenewParole.forPersonStatus"
          question="Who is the qualifying member?"
          options={militaryPipOptions}
          direction="column"
          required
        />
      )}

      {values.selfOrOtherRenewParole?.program === 'I' && (
        <TextFieldWithQuestion
          name="selfOrOtherRenewParole.otherProgram"
          question="List specific program or process."
        />
      )}
    </Box>
  );
}

export default SelfOtherRenewParoleInfo;
