import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type CountriesIntendedToVisitSchemaType = Partial<I131FormValues> & {
  proposedTravel: {
    countriesIntendedToVisit: string;
  };
};

const CountriesIntendedToVisitSchema = createPartialSchema<CountriesIntendedToVisitSchemaType>({
  proposedTravel: Yup.object({
    countriesIntendedToVisit: Yup.string().default(''),
  }),
});

export default CountriesIntendedToVisitSchema;
