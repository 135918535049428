import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import { booleanSchema } from '@src/utils/I131.schema';
import * as Yup from 'yup';

// Valid values for info correction
export const validInfoCorrectionValue = [
  'name',
  'aNumber',
  'countryOfBirth',
  'termsAndConditions',
  'dateOfBirth',
  'gender',
  'validityDate',
  'photo',
];

// Type for valid info correction values
export type ValidInfoCorrectionType = (typeof validInfoCorrectionValue)[number];

// Validate info correction values
export function validateInfoCorrection(values: (ValidInfoCorrectionType | undefined)[] | undefined): boolean {
  if (!values || values.length === 0) return true;
  // Type assertion needed because includes() expects ValidInfoCorrection
  return values.every((v) => v && validInfoCorrectionValue.includes(v));
}

// Create the schema
const requestReplacementSchema = createPartialSchema<I131FormValues>({
  processingInfo: Yup.object({
    requestReplacement: booleanSchema,
    reasonForReplacement: Yup.string()
      .default('')
      .oneOf(['', 'notReceive', 'stolenOrDamage', 'informationErrorCausedByMe', 'informationErrorCausedByUSCIS']),
    infoCorrection: Yup.array()
      .of(Yup.string())
      .default([])
      .test('validSelection', 'Invalid value', function (value) {
        return validateInfoCorrection(value as (ValidInfoCorrectionType | undefined)[]);
      })
      .when('requestReplacement', {
        is: 'Yes',
        then: (schema) => schema.min(1),
      }),
    infoCorrectionExplanation: Yup.string().default(''),
  }),
});

// Export the inferred type from the schema
export type RequestReplacementSchema = Yup.InferType<typeof requestReplacementSchema>;

export default requestReplacementSchema;
