import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type HairColorSchemaType = Partial<I131FormValues> & {
  biographicInfo: {
    hairColor: string;
  };
};

const HairColorSchema = createPartialSchema<HairColorSchemaType>({
  biographicInfo: Yup.object({
    hairColor: Yup.string()
      .default('')
      .oneOf(['', 'Bald', 'Black', 'Blond', 'Brown', 'Gray', 'Red', 'Sandy', 'White', 'Unknown']),
  }),
});

export default HairColorSchema;
