import { ReactElement } from 'react';
import AddressPage from '@src/containers/FormSubSections/SharedSubSections/Address/Address';

const MailingAddress = (): ReactElement => {
  const formGroup = 'applicantInfo.currentMailingAddress';
  return (
    <AddressPage
      question={'What is your current mailing address or safe address?'}
      inCareOfNamePath={`${formGroup}.inCareOfName`}
      streetPath={`${formGroup}.street`}
      unitNumberPath={`${formGroup}.unitNumber`}
      cityOrTownPath={`${formGroup}.cityOrTown`}
      statePath={`${formGroup}.state`}
      zipCodePath={`${formGroup}.zipCode`}
      provincePath={`${formGroup}.province`}
      postalCodePath={`${formGroup}.postalCode`}
      countryPath={`${formGroup}.country`}
    />
  );
};

export default MailingAddress;
