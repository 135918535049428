import { ReactElement } from 'react';
import FullName from '../../SharedSubSections/FullName/FullName';

export const YOUR_NAME = {
  YOUR_NAME: {
    fieldKey: 'applicantInfo.fullName',
    question: 'What is your current full legal name?',
  },
  GIVEN_NAME: {
    fieldKey: 'applicantInfo.fullName.givenName',
    summaryKey: 'Given name (First Name)',
  },
  MIDDLE_NAME: {
    fieldKey: 'applicantInfo.fullName.middleName',
    summaryKey: 'Middle name (if applicable)',
  },
  FAMILY_NAME: {
    fieldKey: 'applicantInfo.fullName.familyName',
    summaryKey: 'Family name (Last Name)',
  },
};

const YourName = (): ReactElement => {
  return (
    <FullName
      question={YOUR_NAME.YOUR_NAME.question}
      givenNamePath={YOUR_NAME.GIVEN_NAME.fieldKey}
      middleNamePath={YOUR_NAME.MIDDLE_NAME.fieldKey}
      familyNamePath={YOUR_NAME.FAMILY_NAME.fieldKey}
    />
  );
};

export default YourName;
