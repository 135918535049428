import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import { BooleanSchema, booleanSchema } from '@src/utils/I131.schema';
import * as Yup from 'yup';

type ReceiveBenefitsSchemaType = Partial<I131FormValues> & {
  refugeeTravelDocumentInfo: {
    receiveBenefits: BooleanSchema;
    receiveBenefitsExplanation: string;
  };
};

const receiveBenefitsSchema = createPartialSchema<ReceiveBenefitsSchemaType>({
  refugeeTravelDocumentInfo: Yup.object({
    receiveBenefits: booleanSchema,
    receiveBenefitsExplanation: Yup.string().default(''),
  }),
});

export default receiveBenefitsSchema;
