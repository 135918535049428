import { Button } from '@mui/material';
import { Component } from 'react';

interface FormErrorBoundaryState {
  hasError: boolean;
  error?: Error;
}

interface FormErrorBoundaryProps {
  children: React.ReactNode;
}

class FormErrorBoundary extends Component<FormErrorBoundaryProps, FormErrorBoundaryState> {
  constructor(props: FormErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): FormErrorBoundaryState {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error('Form Error Boundary caught error:', {
      error,
      errorInfo,
      stack: error.stack,
    });
  }

  handleReset = (): void => {
    this.setState({ hasError: false, error: undefined });
    window.location.reload();
  };

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <div className="p-4 space-y-4">
          <h2 className="text-xl font-semibold text-red-600">Something went wrong in the form</h2>
          <pre className="p-2 bg-gray-100 rounded text-sm overflow-auto">{this.state.error?.message}</pre>
          <Button onClick={this.handleReset}>Reset Form</Button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default FormErrorBoundary;
