import * as Yup from 'yup';
import { createPartialSchema, I131FormValues } from '@src/types/form.types';

export type RefugeeQuestionSchemaType = Partial<I131FormValues> & {
  refugeeOrLprRefugee: string;
};

const RefugeeQuestionSchema = createPartialSchema<RefugeeQuestionSchemaType>({
  refugeeOrLprRefugee: Yup.string().required('Please select Yes or No'),
});

export default RefugeeQuestionSchema;
