import * as React from 'react';
import { RequiredFieldsMessage, TextFieldWithQuestion } from '@components';
import { Container } from '@mui/material';

export const LENGTH_OF_STAY = {
  fieldKey: 'initPipReparole.lengthOfStayInUs',
  question: 'Expected Length of Stay in the United States',
  label: 'Length of stay in days',
};

const LengthOfStayInUS: React.FC = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ py: 4 }}
    >
      <RequiredFieldsMessage />
      <TextFieldWithQuestion
        question={LENGTH_OF_STAY.question}
        label={LENGTH_OF_STAY.label}
        name={LENGTH_OF_STAY.fieldKey}
        maxCharacters={3}
        numericValueOnly
      />
    </Container>
  );
};

export default LengthOfStayInUS;
