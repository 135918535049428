import { ReactElement } from 'react';
import { TextFieldWithQuestion } from '@src/components';

function TPSBeneficiaryInfo(): ReactElement {
  return (
    <TextFieldWithQuestion
      name="tpsBeneficiary.tpsReceiptNumber"
      question="What is the receipt number from your last approved Form I-821, Application for Temporary Protected Status?"
    />
  );
}

export default TPSBeneficiaryInfo;
