import { useCallback } from 'react';
import axios from 'axios';
import { prefixUrl } from './draftUrl.constant';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useSaveDraft(formikRef: any, idRef: any, formConfigRef: any): () => void {
  return useCallback(() => {
    if (!!formConfigRef && !!formikRef) {
      const payload = formConfigRef.current.f2j(formikRef.current.values);
      axios
        .put(
          `${prefixUrl}/draft-service/drafts/${idRef.current}`,
          { formData: payload },
          { headers: {}, withCredentials: true }
        )
        .catch((e) => {
          console.log('Failed to save draft: ', e.errors);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
