import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type CountryOfCitizenshipSchemaType = Partial<I131FormValues> & {
  applicantInfo: {
    countryOfCitizenship: string;
  };
};

const CountryOfCitizenshipSchema = createPartialSchema<CountryOfCitizenshipSchemaType>({
  applicantInfo: Yup.object({
    countryOfCitizenship: Yup.string().default(''),
  }),
});

export default CountryOfCitizenshipSchema;
