import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type QualifyForParoleSchemaType = Partial<I131FormValues> & {
  initPipReparole: {
    qualification: '';
  };
};

const QualifyForParoleSchema = createPartialSchema<QualifyForParoleSchemaType>({
  initPipReparole: Yup.object({
    qualification: Yup.string().default(''),
  }),
});

export default QualifyForParoleSchema;
