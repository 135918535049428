import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import { booleanReqSchema } from '@src/utils/I131.schema';

type EmploymentAuthorizationSchemaType = Partial<I131FormValues> & {
  eadForNewOrReparole: string;
};

const EmploymentAuthorizationSchema = createPartialSchema<EmploymentAuthorizationSchemaType>({
  eadForNewOrReparole: booleanReqSchema.required('Please select Yes or No'),
});

export default EmploymentAuthorizationSchema;
