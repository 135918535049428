import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type TheirI94EArrivalDepartureNumberSchemaType = Partial<I131FormValues> & {
  infoAboutThem: {
    i94Number: string;
  };
};

const TheirI94EArrivalDepartureNumberSchema = createPartialSchema<TheirI94EArrivalDepartureNumberSchemaType>({
  infoAboutThem: Yup.object({
    i94Number: Yup.string().default(''),
  }),
});

export default TheirI94EArrivalDepartureNumberSchema;
