import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type CountryOfBirthSchemaType = Partial<I131FormValues> & {
  applicantInfo: {
    countryOfBirth: string;
  };
};

const CountryOfBirthSchema = createPartialSchema<CountryOfBirthSchemaType>({
  applicantInfo: Yup.object({
    countryOfBirth: Yup.string().default(''),
  }),
});

export default CountryOfBirthSchema;
