import * as React from 'react';
import { RadioOption } from '@src/types/pages.types';
import { CustomRadio } from '@components';

const ethnicityOptions: RadioOption[] = [
  {
    value: 'hispanicOrLatino',
    label: 'Hispanic or Latino',
  },
  {
    value: 'notHispanicOrLatino',
    label: 'Not Hispanic or Latino',
  },
];

const Ethnicity: React.FC = () => {
  return (
    <CustomRadio
      fieldName="biographicInfo.ethnicity"
      question="What is the ethnicity of the person who will receive the document?"
      options={ethnicityOptions}
      direction="row"
    />
  );
};

export default Ethnicity;
