import * as React from 'react';
import { TextFieldWithQuestion } from '@components';

const ClassOfAdmission: React.FC = (): JSX.Element => {
  return (
    <TextFieldWithQuestion
      name={'applicantInfo.classOfAdmission'}
      question="What is your class of admission (COA)? (if any)"
    />
  );
};

export default ClassOfAdmission;
