import * as Yup from 'yup';
import { checkDate, emailValidation, validateANumberOrSsn, validatePhoneNumber, validateUOAN } from './pureFunctions';

export const addressSchema = Yup.object({
  inCareOfName: Yup.string().default(''),
  street: Yup.string().default(''),
  unitType: Yup.string().default('').oneOf(['', 'STE', 'APT', 'FLR']),
  unitNumber: Yup.string().default(''),
  cityOrTown: Yup.string().default(''),
  state: Yup.string().default(''),
  zipCode: Yup.string().default(''),
  province: Yup.string().default(''),
  postalCode: Yup.string().default(''),
  country: Yup.string().default(''),
});
export type AddressSchema = Yup.InferType<typeof addressSchema>;

export const addressReqSchema = addressSchema.concat(
  Yup.object({
    street: Yup.string().default('').required('Street address is required'),
    cityOrTown: Yup.string().default('').required('City or town is required'),
    state: Yup.string()
      .default('')
      .when('country', (country) => {
        return country[0] === '' || country[0] === 'USA'
          ? Yup.string().default('').required('State is required')
          : Yup.string().default('');
      }),
    zipCode: Yup.string()
      .default('')
      .when('country', (country) => {
        return country[0] === '' || country[0] === 'USA'
          ? Yup.string().default('').required('State is required')
          : Yup.string().default('');
      }),
  })
);
export type AddressReqSchema = Yup.InferType<typeof addressReqSchema>;

export const additionalInformationSchema = Yup.object({
  question: Yup.string().default(''),
  explanation: Yup.string().default(''),
});
export type AdditionalInformationSchema = Yup.InferType<typeof additionalInformationSchema>;

export const booleanSchema = Yup.string().default('').oneOf(['', 'Yes', 'No']);
export type BooleanSchema = Yup.InferType<typeof booleanSchema>;

export const booleanReqSchema = booleanSchema.required('An answer is required');
export type BooleanReqSchema = Yup.InferType<typeof booleanReqSchema>;

export const evidenceSchema = Yup.object({
  evidenceType: Yup.string().default('').required(),
  fileName: Yup.string().default('').required(),
});
export type EvidenceSchema = Yup.InferType<typeof evidenceSchema>;

export const fullNameSchema = Yup.object({
  givenName: Yup.string().default(''),
  middleName: Yup.string().default(''),
  familyName: Yup.string().default(''),
});
export type FullNameSchema = Yup.InferType<typeof fullNameSchema>;

const i131Schema = Yup.object({
  // Part 1
  applicationType: Yup.string().default('').required(),
  tpsBeneficiary: Yup.object({
    // Question 4
    tpsReceiptNumber: Yup.string().default(''),
  }),
  inUsAdvanceParoleDocument: Yup.object({
    // Question 5
    program: Yup.string().default(''),
    receiptNumber: Yup.string().default(''),
  }),
  outsideUsInitParoleDocument: Yup.object({
    // Question 6
    program: Yup.string().default(''),
    forPerson: Yup.string().default('').oneOf(['', 'self', 'spouseChildOrUnmarried', 'legalGuardianOrSurrogate']),
    receiptNumber: Yup.string().default(''),
    govExecBranch: Yup.string().default(''),
    repEmailAddress: Yup.string().default('').test('emailValidation', 'Invalid email', emailValidation),
    otherProgram: Yup.string().default(''),
  }),
  selfOrOtherInUsInitParole: Yup.object({
    // Question 8
    program: Yup.string().default(''),
    forPersonStatus: Yup.string().default('').oneOf(['', 'self', 'spouseParentChild']),
    receiptNumber: Yup.string().default(''),
    otherProgram: Yup.string().default(''),
  }),
  selfOrOtherRenewParole: Yup.object({
    // Question 10
    program: Yup.string().default(''),
    forPerson: Yup.string().default('').oneOf(['', 'self', 'spouseChildOrUnmarried', 'legalGuardianOrSurrogate']),
    forPersonStatus: Yup.string().default('').oneOf(['', 'self', 'spouseParentChild']),
    otherProgram: Yup.string().default(''),
  }),
  i94AdmittedDate: Yup.string() // Question 12
    .default('')
    .test('validateDate', 'Invalid date format', (value: string) => checkDate(value, false)),
  refugeeOrLprRefugee: booleanSchema, // Question 13
  // Part 2, Questions 1-15
  applicantInfo: Yup.object({
    fullName: fullNameSchema,
    otherNames: Yup.array().of(fullNameSchema).default([]).max(3),
    currentMailingAddress: addressSchema,
    isPhysicalSameAsMailing: booleanSchema,
    currentPhysicalAddress: addressSchema,
    alienRegistrationNumber: Yup.string().default('').test(validateANumberOrSsn),
    countryOfBirth: Yup.string().default(''),
    countryOfCitizenship: Yup.string().default(''),
    gender: Yup.string().default('').oneOf(['male', 'female', 'anotherGenderIdentity', '']),
    dateOfBirth: Yup.string()
      .default('')
      .test('validateDate', 'Invalid date format', (value: string) => checkDate(value, false)),
    socialSecurityNumber: Yup.string().default('').test(validateANumberOrSsn),
    uscisOnlineAccountNumber: Yup.string().default('').test(validateUOAN),
    classOfAdmission: Yup.string().default(''),
    i94Number: Yup.string().default(''),
    expirationDate: Yup.string()
      .default('')
      .test('validateDate', 'Invalid date format', (value: string) => checkDate(value)),
    eMedicalUSParoleeID: Yup.string().default(''),
    applyingForSomeoneElse: booleanReqSchema,
  }),
  // Part 2, Questions 16-27
  infoAboutThem: Yup.object({
    fullName: fullNameSchema,
    otherNames: Yup.array().of(fullNameSchema).default([]).max(3),
    currentMailingAddress: addressSchema,
    isPhysicalSameAsMailing: booleanSchema,
    currentPhysicalAddress: addressSchema,
    alienRegistrationNumber: Yup.string().default('').test(validateANumberOrSsn),
    countryOfBirth: Yup.string().default(''),
    countryOfCitizenship: Yup.string().default(''),
    dateOfBirth: Yup.string()
      .default('')
      .test('validateDate', 'Invalid date format', (value: string) => checkDate(value, false)),
    classOfAdmission: Yup.string().default(''),
    i94Number: Yup.string().default(''),
    daytimePhone: Yup.string().default('').test('phoneNumberValidation', 'Invalid phone format', validatePhoneNumber),
    emailAddress: Yup.string().default('').test('emailValidation', 'Invalid email', emailValidation),
  }),
  // Part 3
  biographicInfo: Yup.object({
    ethnicity: Yup.string().default('').oneOf(['', 'hispanicOrLatino', 'notHispanicOrLatino']),
    race: Yup.array().of(Yup.string()).default([]),
    height: Yup.object({
      feet: Yup.string().default(''),
      inches: Yup.string().default(''),
    }),
    weight: Yup.string().default(''),
    eyeColor: Yup.string()
      .default('')
      .oneOf(['', 'black', 'blue', 'brown', 'gray', 'green', 'hazel', 'maroon', 'pink', 'unknown']),
    hairColor: Yup.string()
      .default('')
      .oneOf(['', 'bald', 'black', 'blond', 'brown', 'gray', 'red', 'sandy', 'white', 'unknown']),
  }),
  // Part 4
  processingInfo: Yup.object({
    previousProceedings: booleanSchema,
    previousReentryPermit: Yup.object({
      issued: booleanSchema,
      dateIssued: Yup.string()
        .default('')
        .test('validateDate', 'Invalid date format', (value: string) => checkDate(value, false)),
      disposition: Yup.string().default(''),
    }),
    previousAdvanceParole: Yup.object({
      issued: booleanSchema,
      dateIssued: Yup.string()
        .default('')
        .test('validateDate', 'Invalid date format', (value: string) => checkDate(value, false)),
      disposition: Yup.string().default(''),
    }),
    requestReplacement: booleanSchema,
    reasonForReplacement: Yup.string()
      .default('')
      .oneOf(['', 'notReceive', 'stolenOrDamage', 'informationErrorCausedByMe', 'informationErrorCausedByUSCIS']),
    infoCorrection: Yup.array().of(Yup.string()).default([]),
    infoCorrectionExplanation: Yup.string().default(''),
    replacementReceiptNumber: Yup.string().default(''),
    documentSendTo: Yup.object({
      toUsAddressOrEmbassy: Yup.string().default('').oneOf(['', 'usAddress', 'usEmbassy']),
      embassyCityOrTown: Yup.string().default(''),
      embassyCountry: Yup.string().default(''),
    }),
    noticeSendTo: Yup.object({
      selectDestination: Yup.string().default('').oneOf(['', 'toP2Q3', 'toP4Q9a']),
      address: addressSchema,
      daytimePhone: Yup.string().default('').test('phoneNumberValidation', 'Invalid phone format', validatePhoneNumber),
      emailAddress: Yup.string().default('').test('emailValidation', 'Invalid email', emailValidation),
    }),
  }),
  // Part 5
  totalTimeOutsideUs: Yup.string()
    .default('')
    .oneOf([
      '',
      'lessThan6Months',
      'sixMonthsToOneYear',
      'oneToTwoYears',
      'twoToThreeYears',
      'threeToFourYears',
      'moreThanFourYears',
    ]),
  // Part 6
  refugeeTravelDocumentInfo: Yup.object({
    originCountry: Yup.string().default(''),
    planToTravelBack: booleanSchema,
    planToTravelBackExplanation: Yup.string().default(''),
    returnToCountry: booleanSchema,
    returnToCountryExplanation: Yup.string().default(''),
    appliedForPassport: booleanSchema,
    appliedForPassportExplanation: Yup.string().default(''),
    receiveBenefits: booleanSchema,
    receiveBenefitsExplanation: Yup.string().default(''),
    reacquiredNationality: booleanSchema,
    reacquiredNationalityExplanation: Yup.string().default(''),
    acquiredNewNationality: booleanSchema,
    acquiredNewNationalityExplanation: Yup.string().default(''),
    grantedAsyleeForOtherCountry: booleanSchema,
    grantedAsyleeForOtherCountryExplanation: Yup.string().default(''),
    fileForRefugeeBeforeLeavingUs: booleanSchema,
    fileForRefugeeBeforeLeavingUsExplanation: Yup.string().default(''),
    currentlyOutsideUs: booleanSchema,
    currentLocation: Yup.string().default(''),
    otherCountries: Yup.string().default(''),
  }),
  // Part 7
  proposedTravel: Yup.object({
    departureDate: Yup.string()
      .default('')
      .test('validateDate', 'Invalid date format', (value: string) => checkDate(value, true, false)),
    purpose: Yup.string().default(''),
    countriesIntendedToVisit: Yup.string().default(''),
    numberOfTrips: Yup.string().default('').oneOf(['', 'oneTrip', 'moreThanOneTrip']),
    expectedLength: Yup.string().default(''),
  }),
  // Part 8
  initPipReparole: Yup.object({
    intendedRecipientOutsideOfUS: booleanSchema,
    qualification: Yup.string().default(''),
    lengthOfStayInUs: Yup.string().default(''),
    dateOfIntendedArrival: Yup.string()
      .default('')
      .test('validateDate', 'Invalid date format', (value: string) => checkDate(value, true, false)),
    locationToNotify: Yup.object({
      cityOrTown: Yup.string().default(''),
      country: Yup.string().default(''),
    }),
  }),
  // Part 9
  eadForNewOrReparole: booleanSchema,
  // Part 10
  applicantContactAndCertification: Yup.object({
    daytimePhone: Yup.string().default('').test('phoneNumberValidation', 'Invalid phone format', validatePhoneNumber),
    mobilePhone: Yup.string().default('').test('phoneNumberValidation', 'Invalid phone format', validatePhoneNumber),
    email: Yup.string().default('').test('emailValidation', 'Invalid email', emailValidation),
    signature: Yup.string().default(''),
    dateOfSignature: Yup.string()
      .default('')
      .test('validateDate', 'Invalid date format', (value: string) => checkDate(value)),
  }),
  // Part 11
  interpreterContactAndCertification: Yup.object({
    hasInterpreter: booleanSchema,
    fullName: Yup.object({
      familyName: Yup.string().default(''),
      givenName: Yup.string().default(''),
    }),
    businessName: Yup.string().default(''),
    daytimePhone: Yup.string().default('').test('phoneNumberValidation', 'Invalid phone format', validatePhoneNumber),
    mobilePhone: Yup.string().default('').test('phoneNumberValidation', 'Invalid phone format', validatePhoneNumber),
    email: Yup.string().default('').test('emailValidation', 'Invalid email', emailValidation),
    language: Yup.string().default(''),
    signature: Yup.string().default(''),
    dateOfSignature: Yup.string()
      .default('')
      .test('validateDate', 'Invalid date format', (value: string) => checkDate(value)),
  }),
  // Part 12
  preparerContactAndCertification: Yup.object({
    hasPreparer: booleanSchema,
    fullName: Yup.object({
      familyName: Yup.string().default(''),
      givenName: Yup.string().default(''),
    }),
    businessName: Yup.string().default(''),
    daytimePhone: Yup.string().default('').test('phoneNumberValidation', 'Invalid phone format', validatePhoneNumber),
    mobilePhone: Yup.string().default('').test('phoneNumberValidation', 'Invalid phone format', validatePhoneNumber),
    email: Yup.string().default('').test('emailValidation', 'Invalid email', emailValidation),
    signature: Yup.string().default(''),
    dateOfSignature: Yup.string()
      .default('')
      .test('validateDate', 'Invalid date format', (value: string) => checkDate(value)),
  }),
  // Part 13
  additionalInformation: Yup.array().of(additionalInformationSchema).default([]),
  // evidence
  evidences: Yup.array().of(evidenceSchema).default([]),
});

export type I131Schema = Yup.InferType<typeof i131Schema>;
export default i131Schema;
