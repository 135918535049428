import { ChangeEvent, FC, KeyboardEvent, useState, useRef, useEffect } from 'react';
import axios from 'axios';
import {
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  Fab,
  Card,
  CardContent,
  Box,
  AppBar,
  Toolbar,
} from '@mui/material';
import { Send, Close } from '@mui/icons-material';
import { LoadingDots } from '@components';
import { API_URL, HELPS_PATH } from '@src/apis/apiConstants';
import { generateSessionId, extractDataObjects } from '@src/utils/streamUtils';
import chatIcon from '@src/assets/USWDS_components.png';
import uscisInsigniaIcon from '@src/assets/USCIS_Insignia_solo.png';
import { AiChatAssistantProps, ChatMessage } from '@src/types/containers.types';
import { ChatContainer, ChatInputContainer, ChatMessages, MessageBubble } from '../../styles/components/chatStyles';

const AiChatAssistant: FC<AiChatAssistantProps> = ({ formType = 'Default' }): JSX.Element => {
  const chatSessionId = generateSessionId();
  const [showChat, setShowChat] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const [messages, setMessages] = useState<ChatMessage[]>([
    {
      text: `Hello! I'm your ${formType} application assistant. How can I help you today?`,
      isBot: true,
      timestamp: new Date(),
    },
  ]);
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const scrollToBottom = (): void => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect((): void => {
    scrollToBottom();
  }, [messages]);

  const handleSend = async (): Promise<void> => {
    if (!inputText.trim() || isLoading) return;

    try {
      setIsLoading(true);

      setMessages((prev) => [
        ...prev,
        {
          text: inputText.trim(),
          isBot: false,
          timestamp: new Date(),
        },
      ]);

      const response = await axios.post(
        `${API_URL}${HELPS_PATH}/chats/question`,
        {
          chatSessionId,
          prompt: inputText.trim(),
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Api-User-Id': 'user-123',
          },
        }
      );

      const chunks = extractDataObjects(response.data);

      chunks.forEach((chunk) => {
        setMessages((prev) => [
          ...prev,
          {
            text: chunk.chunk,
            isBot: true,
            timestamp: new Date(),
          },
        ]);
      });
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages((prev) => [
        ...prev,
        {
          text: 'Sorry, there was an error processing your request. Please try again.',
          isBot: true,
          timestamp: new Date(),
        },
      ]);
    } finally {
      setIsLoading(false);
      setInputText('');
    }
  };

  const handleKeyPress = (event: KeyboardEvent): void => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const handleClose = (): void => setShowChat(false);

  return (
    <Box sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 1000 }}>
      <Fab
        aria-label="chat"
        onClick={(): void => setShowChat(!showChat)}
        sx={{
          position: 'absolute',
          bottom: 0,
          right: 12,
          bgcolor: '#00416A',
          '&:hover': {
            bgcolor: '#003457',
          },
          width: 56,
          height: 56,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {showChat ? (
          <Close sx={{ color: '#fff' }} />
        ) : (
          <Box
            component="img"
            src={chatIcon}
            alt=""
            sx={{
              width: 24,
              height: 24,
              filter: 'brightness(0) invert(1)',
            }}
          />
        )}
      </Fab>

      {showChat && (
        <Card
          sx={{
            position: 'absolute',
            bottom: 80,
            right: 12,
            width: '400px',
            height: '600px',
            display: 'flex',
            flexDirection: 'column',
            maxHeight: 'calc(100vh - 100px)',
            boxShadow: 3,
          }}
        >
          <AppBar
            position="static"
            sx={{ backgroundColor: '#00416A' }}
          >
            <Toolbar sx={{ minHeight: '56px !important' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                <Box sx={{ pr: 1 }}>
                  <img
                    src={uscisInsigniaIcon}
                    alt=""
                    width="32px"
                    height="32px"
                  />
                </Box>
                <Typography variant="subtitle1">USCIS Help</Typography>
              </Box>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <Close />
              </IconButton>
            </Toolbar>
          </AppBar>

          <CardContent
            sx={{
              p: 0,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              '&:last-child': { pb: 0 },
              overflow: 'hidden',
            }}
          >
            <ChatContainer sx={{ height: '100%' }}>
              <ChatMessages
                sx={{
                  flex: 1,
                  overflowY: 'auto',
                  p: 2,
                  '&::-webkit-scrollbar': {
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: '#888',
                    borderRadius: '4px',
                  },
                }}
              >
                {messages.map((msg) => (
                  <Box
                    key={msg.timestamp.toString()}
                    sx={{
                      mb: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: msg.isBot ? 'flex-start' : 'flex-end',
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#444',
                        fontFamily: '"Source Sans Pro", sans-serif',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '21px',
                        mb: 0.5,
                      }}
                    >
                      {msg.isBot ? 'Chatbot' : 'Customer'}
                    </Typography>
                    <MessageBubble
                      isBot={msg.isBot}
                      sx={{
                        backgroundColor: msg.isBot ? '#EBEBEB' : '#00476B',
                        color: msg.isBot ? '#000' : '#fff',
                        maxWidth: '80%',
                        padding: '12px 16px',
                        borderRadius: '4px',
                        wordBreak: 'break-word',
                      }}
                    >
                      {msg.text}
                    </MessageBubble>
                  </Box>
                ))}
                {isLoading && <LoadingDots />}
                <div ref={messagesEndRef} />
              </ChatMessages>

              <ChatInputContainer sx={{ p: 2, borderTop: '1px solid #e0e0e0' }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Type your question here..."
                  value={inputText}
                  onChange={(e: ChangeEvent<HTMLInputElement>): void => setInputText(e.target.value)}
                  onKeyDown={handleKeyPress}
                  disabled={isLoading}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleSend}
                          disabled={isLoading || !inputText.trim()}
                        >
                          <Send />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </ChatInputContainer>
            </ChatContainer>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default AiChatAssistant;
