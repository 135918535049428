import * as Yup from 'yup';
import { createPartialSchema, I131FormValues } from '@src/types/form.types';

// Advance Parole Schema Type
export type AdvanceParoleSchemaType = Partial<I131FormValues> & {
  inUsAdvanceParoleDocument: {
    program: string;
    receiptNumber: string;
  };
};

const advanceParoleSchema = createPartialSchema<AdvanceParoleSchemaType>({
  inUsAdvanceParoleDocument: Yup.object({
    program: Yup.string().required('Please select a program'),
    receiptNumber: Yup.string().when('program', {
      is: (value: string) => value && value !== 'D',
      then: () => Yup.string().required('Please enter a value'),
    }),
  }),
});

export default advanceParoleSchema;
