import { CardItem } from '@src/types/containers.types';

export const cardData: CardItem[] = [
  {
    title: 'Add a case to your account',
    description: 'Enter you Online Access Code (AOC) to add and/or manage a case in your account.',
    button: 'Go to your Cases',
    buttonLink: '/case',
  },
  {
    title: 'Enter a representative code',
    description: 'Review and sign forms prepared for you by your attorney',
    button: 'Review and sign forms',
    buttonLink: '/rep-code',
  },
  {
    title: 'Verify your Identity',
    description: 'Answer questions about your immigration history to verify your personal identity.',
    button: 'Answer questions',
    buttonLink: '/verify-identity',
  },
  {
    title: 'Change your address',
    description: 'Update your address online, in your USCIS account',
    button: 'Go to account settings',
    buttonLink: '/change-your-address',
  },
  {
    title: 'Reschedule a biometrics appointment',
    description: 'Search for your existing biometrics appointment and reschedule online',
    button: 'Reschedule appointment',
    buttonLink: '/reschedule-biometrics-appointment',
  },
  {
    title: 'Find information and resources',
    description: 'Find information in our resource center',
    button: 'Go to resource center',
    buttonLink: '/information-and-resources',
  },
];
