import * as React from 'react';
import { ReactElement } from 'react';
import { useFormikContext } from 'formik';
import { BooleanSchema } from '../../utils/I131.schema';
import ExplanationBox from '../ExplanationBox';
import CustomRadio from '@src/components/CustomRadio';
import { Box } from '@mui/material';

// Generic type for form values that can handle any nested structure
interface GenericFormValues {
  [key: string]: {
    [key: string]: string;
  };
}

interface BooleanQuestionProps {
  question: string;
  fieldName: string; // Format: "section.field" (e.g., "biographicInfo.planToTravelBack")
}

const BooleanRadioWithExplanationBox = ({ question, fieldName }: BooleanQuestionProps): ReactElement => {
  const { values, setFieldValue } = useFormikContext<GenericFormValues>();

  // Split the fieldName into section and field
  const [section, field] = fieldName.split('.');

  // Safely access nested values
  const currentValue = values[section]?.[field] as BooleanSchema;
  const showExplanation = currentValue === 'Yes';

  // Construct explanation field name
  const explanationFieldName = `${section}.${field}Explanation`;

  // Track previous value for cleanup
  const previousValue = React.useRef(currentValue);

  React.useEffect(() => {
    // Only clear explanation when switching from Yes to No or empty
    if (previousValue.current === 'Yes' && currentValue !== 'Yes') {
      setFieldValue(explanationFieldName, '');
    }
    previousValue.current = currentValue;
  }, [currentValue, setFieldValue, explanationFieldName]);

  return (
    <Box sx={{ mt: 6 }}>
      <CustomRadio
        question={question}
        fieldName={fieldName}
      />
      <Box sx={{ mt: 6 }}>{showExplanation && <ExplanationBox name={explanationFieldName} />}</Box>
    </Box>
  );
};

export default BooleanRadioWithExplanationBox;
