import { checkDate } from '@src/utils/pureFunctions';
import { booleanSchema } from '@src/utils/I131.schema';
import * as Yup from 'yup';
import { createPartialSchema, I131FormValues } from '@src/types/form.types';

type previousReentrySchemaType = Partial<I131FormValues> & {
  processingInfo: {
    previousReentryPermit: {
      issued: string;
      dateIssued: string;
      disposition: string;
    };
  };
};

const previousReentrySchema = createPartialSchema<previousReentrySchemaType>({
  processingInfo: Yup.object({
    previousReentryPermit: Yup.object({
      issued: booleanSchema,
      dateIssued: Yup.string()
        .default('')
        .test('validateDate', 'Invalid date format', (value: string) => checkDate(value, false)),
      disposition: Yup.string().default(''),
    }),
  }),
});

export type PreviousReentrySchema = Yup.InferType<typeof previousReentrySchema>;

export default previousReentrySchema;
