import * as React from 'react';
import { CustomRadio } from '@components';

const HasPreparer: React.FC = () => {
  return (
    <CustomRadio
      question="Did you use a preparer for this application?"
      fieldName="preparerContactAndCertification.hasPreparer"
    />
  );
};

export default HasPreparer;
