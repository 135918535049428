import * as React from 'react';
import FlexibleDropdown from '@src/components/FlexibleDropdown';

const timeOutsideOptions = [
  { value: 'lessThan6Months', label: 'Less Than 6 Months' },
  { value: 'sixMonthsToOneYear', label: '6 Months to 1 Year' },
  { value: 'oneToTwoYears', label: '1 to 2 Years' },
  { value: 'twoToThreeYears', label: '2 to 3 Years' },
  { value: 'threeToFourYears', label: '3 to 4 Years' },
  { value: 'moreThanFourYears', label: 'More Than 4 Years' },
];

const ReentryPermitInformation: React.FC = () => {
  return (
    <FlexibleDropdown
      leftName={'totalTimeOutsideUs'}
      leftOptions={timeOutsideOptions}
      question="Since becoming a permanent resident of the United States (or during the past 5 years, whichever is less), how much total time have you spent outside the United States?"
      required
      leftLabel="Time Spent"
    />
  );
};

export default ReentryPermitInformation;
