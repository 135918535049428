import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import { checkDate } from '@src/utils/pureFunctions';
import * as Yup from 'yup';

type I94InfoSchemaType = Partial<I131FormValues> & {
  i94AdmittedDate: string;
};

// I-94 Info Schema
const I94InfoSchema = createPartialSchema<I94InfoSchemaType>({
  i94AdmittedDate: Yup.string().test('validateDate', 'Invalid date format', (value) => {
    if (!value) return true; // Empty values are valid
    return checkDate(value, false);
  }),
});

export default I94InfoSchema;
