import * as React from 'react';
import { COUNTRY } from '@src/constants/countryConstants';
import FlexibleDropdown from '@src/components/FlexibleDropdown';

export const THEIR_COUNTRY_OF_CITIZENSHIP = {
  CITIZENSHIP_COUNTRY: {
    fieldKey: 'infoAboutThem.countryOfCitizenship',
    question: 'What is your country of citizenship?',
  },
};

const TheirCountryOfCitizenship: React.FC = () => {
  return (
    <FlexibleDropdown
      leftName={THEIR_COUNTRY_OF_CITIZENSHIP.CITIZENSHIP_COUNTRY.fieldKey}
      question={THEIR_COUNTRY_OF_CITIZENSHIP.CITIZENSHIP_COUNTRY.question}
      instructions="Provide the name of the country of their citizenship. Use the current names of the country of their citizenship."
      leftOptions={COUNTRY}
    />
  );
};

export default TheirCountryOfCitizenship;
