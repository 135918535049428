import { UseMutationResult } from '@tanstack/react-query';
import { FormikProps } from 'formik';
import { useEffect } from 'react';
import { SaveFormDataPayload } from '@src/services/formDataService';
import { FormConfig, FormDataError, BaseFormValues } from '@src/types/form.types';
import { StoredFormData } from '@src/types/services.types';

export const useBeforeUnloadSave = <T extends BaseFormValues>(
  formikRef: React.RefObject<FormikProps<T>>,
  formConfig: FormConfig<T> | undefined,
  formType: string | undefined,
  saveFormMutation: UseMutationResult<StoredFormData<T>, FormDataError, SaveFormDataPayload<T>>
): void => {
  useEffect(() => {
    if (!formConfig?.formId) return;

    const handleBeforeUnload = async (): Promise<void> => {
      if (formikRef.current?.values) {
        try {
          await saveFormMutation.mutateAsync({
            id: formConfig.formId,
            values: formikRef.current.values,
            formType: formType || '',
            lastUpdated: new Date().toISOString(),
          });
        } catch (error) {
          console.error('Failed to save form data on unload:', error);
        }
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return (): void => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [formConfig?.formId, formType, saveFormMutation, formikRef]);
};
