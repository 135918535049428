import * as Yup from 'yup';
import { createPartialSchema, I131FormValues } from '@src/types/form.types';

// Application Type Schema Type
export type ApplicationTypeSchemaType = Partial<I131FormValues> & {
  applicationType: string;
};

// Application Type Schema
export const applicationTypeSelectSchema = createPartialSchema<ApplicationTypeSchemaType>({
  applicationType: Yup.string().required('Please select an application type'),
});
