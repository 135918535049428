import * as Yup from 'yup';

const addressSchema = Yup.object({
  inCareOfName: Yup.string().default(''),
  street: Yup.string().default(''),
  unitNumber: Yup.string().default(''),
  cityOrTown: Yup.string().default(''),
  state: Yup.string().default(''),
  zipCode: Yup.string().default(''),
  province: Yup.string().default(''),
  postalCode: Yup.string().default(''),
  country: Yup.string().default(''),
});

export type addressSchemaType = Yup.InferType<typeof addressSchema>;

export default addressSchema;
