import { ReactElement } from 'react';
import FullName from '../../SharedSubSections/FullName/FullName';
import { Box } from '@mui/material';
import { QuestionWithInstruction } from '@src/components';

export const THEIR_OTHER_NAMES = {
  OTHER_NAMES: {
    question: 'Have they ever used any other names?',
  },
  GIVEN_NAME_ONE: {
    fieldKey: 'infoAboutThem.otherNames[0].givenName',
    summaryKey: 'First Name',
  },
  MIDDLE_NAME_ONE: {
    fieldKey: 'infoAboutThem.otherNames[0].middleName',
    summaryKey: 'Middle Name',
  },
  FAMILY_NAME_ONE: {
    fieldKey: 'infoAboutThem.otherNames[0].familyName',
    summaryKey: 'Last Name',
  },
  GIVEN_NAME_TWO: {
    fieldKey: 'infoAboutThem.otherNames[1].givenName',
    summaryKey: 'First Name',
  },
  MIDDLE_NAME_TWO: {
    fieldKey: 'infoAboutThem.otherNames[1].middleName',
    summaryKey: 'Middle Name',
  },
  FAMILY_NAME_TWO: {
    fieldKey: 'infoAboutThem.otherNames[1].familyName',
    summaryKey: 'Last Name',
  },
  GIVEN_NAME_THREE: {
    fieldKey: 'infoAboutThem.otherNames[2].givenName',
    summaryKey: 'First Name',
  },
  MIDDLE_NAME_THREE: {
    fieldKey: 'infoAboutThem.otherNames[2].middleName',
    summaryKey: 'Middle Name',
  },
  FAMILY_NAME_THREE: {
    fieldKey: 'infoAboutThem.otherNames[2].familyName',
    summaryKey: 'Last Name',
  },
};

const TheirOtherNames = (): ReactElement => {
  return (
    <Box sx={{ maxWidth: '800px', mx: 'auto', p: 3 }}>
      <QuestionWithInstruction question={THEIR_OTHER_NAMES.OTHER_NAMES.question} />
      <FullName
        givenNamePath={THEIR_OTHER_NAMES.GIVEN_NAME_ONE.fieldKey}
        middleNamePath={THEIR_OTHER_NAMES.MIDDLE_NAME_ONE.fieldKey}
        familyNamePath={THEIR_OTHER_NAMES.FAMILY_NAME_ONE.fieldKey}
      />
      <FullName
        givenNamePath={THEIR_OTHER_NAMES.GIVEN_NAME_TWO.fieldKey}
        middleNamePath={THEIR_OTHER_NAMES.MIDDLE_NAME_TWO.fieldKey}
        familyNamePath={THEIR_OTHER_NAMES.FAMILY_NAME_TWO.fieldKey}
      />
      <FullName
        givenNamePath={THEIR_OTHER_NAMES.GIVEN_NAME_THREE.fieldKey}
        middleNamePath={THEIR_OTHER_NAMES.MIDDLE_NAME_THREE.fieldKey}
        familyNamePath={THEIR_OTHER_NAMES.FAMILY_NAME_THREE.fieldKey}
      />
    </Box>
  );
};

export default TheirOtherNames;
