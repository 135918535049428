import * as React from 'react';
import { CustomRadio } from '@components';

const HasInterpreter: React.FC = () => {
  return (
    <CustomRadio
      question="Did you use a interpreter for this application?"
      fieldName="interpreterContactAndCertification.hasInterpreter"
    />
  );
};

export default HasInterpreter;
