import {
  // Application Type and Basic Info
  I131ApplicationType,
  I94Info,
  InUsAdvanceParoleInfo,
  OutsideInitialParoleInfo,
  SelfOtherInUsInitParoleInfo,
  SelfOtherRenewParoleInfo,
  TPSBeneficiaryInfo,
  advanceParoleSchema,
  applicationTypeSelectSchema,
  I94InfoSchema,
  outsideInitialParoleSchema,
  SelfOtherInUsInitParoleSchema,
  selfOtherRenewParoleSchema,
  tpsBeneficiarySchema,

  // Advanced Parole Document
  AdvancedParoleTrips,
  AdvancedParoleTripsSchema,
  DateOfIntendedDeparture,
  DateOfIntendedDepartureSchema,
  PurposeOfTrip,
  PurposeOfTripSchema,

  // Applicant Information
  ApplicantCertification,
  ApplicantCertificationSchema,
  ApplicantContactInformation,
  ApplicantContactInformationSchema,

  // Biographic Information
  Ethnicity,
  EthnicitySchema,
  EyeColor,
  EyeColorSchema,
  HairColor,
  HairColorSchema,
  HeightWeight,
  HeightWeightSchema,
  Race,
  RaceSchema,

  // Employment Authorization
  EmploymentAuthorization,
  EmploymentAuthorizationSchema,

  // Information About Them
  TheirAnumber,
  TheirAnumberSchema,
  TheirClassOfAdmission,
  TheirClassOfAdmissionSchema,
  TheirCountryOfBirth,
  TheirCountryOfBirthSchema,
  TheirCountryOfCitizenship,
  TheirCountryOfCitizenshipSchema,
  TheirDateOfBirth,
  TheirDateOfBirthSchema,
  TheirI94ArrivalDepartureNumber,
  TheirI94EArrivalDepartureNumberSchema,
  TheirMailingAddress,
  TheirMailingAddressSchema,
  TheirName,
  theirNameSchema,
  TheirOtherNames,
  theirOtherNamesSchema,
  TheirPhysicalAddress,
  TheirPhysicalAddressSchema,

  // Information About You
  Anumber,
  AnumberSchema,
  ApplyingForSomeoneElse,
  ApplyingForSomeoneElseSchema,
  ClassOfAdmission,
  ClassOfAdmissionSchema,
  CountryOfBirth,
  CountryOfBirthSchema,
  CountryOfCitizenship,
  CountryOfCitizenshipSchema,
  DateOfBirth,
  DateOfBirthSchema,
  EMedicalID,
  EMedicalIDSchema,
  Gender,
  GenderSchema,
  I94ArrivalDepartureNumber,
  I94EArrivalDepartureNumberSchema,
  I94ExpirationDate,
  I94ExpirationDateSchema,
  MailingAddress,
  MailingAddressSchema,
  OtherNames,
  otherNamesSchema,
  PhysicalAddress,
  PhysicalAddressSchema,
  SocialSecurityNumber,
  SocialSecurityNumberSchema,
  YourName,
  yourNameSchema,

  // Initial PIP ReParole
  IntendedRecipient,
  IntendedRecipientSchema,
  LengthOfStayInUS,
  LengthOfStayInUSSchema,
  QualifyForParole,
  QualifyForParoleSchema,

  // Interpreter Information
  HasInterpreter,
  HasInterpreterSchema,
  InterpreterCertification,
  InterpreterCertificationSchema,
  InterpreterContactInformation,
  InterpreterContactInformationSchema,
  InterpreterName,
  InterpreterNameSchema,

  // Preparer Information
  HasPreparer,
  HasPreparerSchema,
  PreparerCertification,
  PreparerCertificationSchema,
  PreparerContactInformation,
  PreparerContactInformationSchema,
  PreparerName,
  PreparerNameSchema,

  // Processing Information
  DocumentSendTo,
  documentSendToSchema,
  PreviousProceedings,
  previousProceedingsSchema,
  PreviousReentry,
  previousReentrySchema,
  RequestReplacement,
  requestReplacementSchema,

  // Reentry Permit Information
  ReentryPermitInformation,
  ReentryPermitInformationSchema,

  // Refugee Travel Document
  AcquiredNewNationality,
  acquiredNewNationalitySchema,
  AppliedForPassport,
  appliedForPassportSchema,
  CountryOriginSelect,
  countryOriginSelectSchema,
  GrantedAsyleeForOtherCountry,
  grantedAsyleeForOtherCountrySchema,
  PlanToTravelBack,
  planToTravelBackSchema,
  ReacquiredNationality,
  reacquiredNationalitySchema,
  ReceiveBenefitsQuestion,
  receiveBenefitsSchema,
  ReturnToCountryQuestion,
  returnToCountrySchema,
  RefugeeQuestion,
  RefugeeQuestionSchema,
} from '@src/containers/FormSubSections';

import { FormConfig, DEFAULT_FORM_VALUES, I131FormValues } from '@src/types/form.types';
import I131FormikToJson from '../utils/I131FormikToJson';
import I131JsonToFormik from '../utils/I131JsonToFormik';
import ReviewAndSubmit from '../pages/ReviewAndSubmit/ReviewAndSubmit';

export const i131Config: FormConfig<I131FormValues> = {
  f2j: I131FormikToJson,
  formId: 'I-131',
  formTitle: 'Form I-131 for travel document',
  initialValues: {
    ...DEFAULT_FORM_VALUES,
    formStatus: 'draft',
    lastUpdated: new Date().toISOString(),
  },
  j2f: I131JsonToFormik,
  sections: [
    {
      id: 'application-type',
      menuTitle: 'Application Type',
      description: '',
      cleanup: (): void => {
        // Intentionally empty
      },
      subSections: [
        {
          id: 'select-type-subsection',
          menuTitle: 'Select Application Type',
          subSectionContainer: I131ApplicationType,
          validation: applicationTypeSelectSchema,
          hidden: false,
        },
        {
          id: 'tps-beneficiary-subsection',
          menuTitle: 'TPS Beneficiary Information',
          subSectionContainer: TPSBeneficiaryInfo,
          validation: tpsBeneficiarySchema,
          hidden: (values: I131FormValues) => values.applicationType !== '4',
        },
        {
          id: 'advance-parole-subsection',
          menuTitle: 'Advance Parole Information',
          subSectionContainer: InUsAdvanceParoleInfo,
          validation: advanceParoleSchema,
          hidden: (values: I131FormValues) => values.applicationType !== '5',
        },
        {
          id: 'outside-us-parole-subsection',
          menuTitle: 'Outside US Initial Parole',
          subSectionContainer: OutsideInitialParoleInfo,
          validation: outsideInitialParoleSchema,
          hidden: (values: I131FormValues) => values.applicationType !== '6',
        },
        {
          id: 'self-other-parole-subsection',
          menuTitle: 'Self/Other Initial Parole',
          subSectionContainer: SelfOtherInUsInitParoleInfo,
          validation: SelfOtherInUsInitParoleSchema,
          hidden: (values: I131FormValues) => values.applicationType !== '8',
        },
        {
          id: 'renew-parole-subsection',
          menuTitle: 'Renew Parole Information',
          subSectionContainer: SelfOtherRenewParoleInfo,
          validation: selfOtherRenewParoleSchema,
          hidden: (values: I131FormValues) => values.applicationType !== '10',
        },
        {
          id: 'i94-info',
          menuTitle: 'I-94 Information-subsection',
          subSectionContainer: I94Info,
          validation: I94InfoSchema,
          hidden: (values: I131FormValues) => !['10', '11'].includes(values.applicationType),
        },
        {
          id: 'refugee-question',
          menuTitle: 'Refugee Status',
          subSectionContainer: RefugeeQuestion,
          validation: RefugeeQuestionSchema,
          hidden: false,
        },
      ],
    },
    {
      id: 'information-about-you-section',
      menuTitle: 'Information About You',
      description: '',
      cleanup: (): void => {
        // Intentionally empty
      },
      subSections: [
        {
          id: 'your-name-subsection',
          menuTitle: 'Your Name',
          subSectionContainer: YourName,
          validation: yourNameSchema,
          hidden: false,
        },
        {
          id: 'other-name-subsection',
          menuTitle: 'Your Other Names',
          subSectionContainer: OtherNames,
          validation: otherNamesSchema,
          hidden: false,
        },
        {
          id: 'mailing-address-subsection',
          menuTitle: 'Your Mailing Address',
          subSectionContainer: MailingAddress,
          validation: MailingAddressSchema,
          hidden: false,
        },
        {
          id: 'physical-address-subsection',
          menuTitle: 'Your Physical Address',
          subSectionContainer: PhysicalAddress,
          validation: PhysicalAddressSchema,
          hidden: false,
        },
        {
          id: 'gender-subsection',
          menuTitle: 'Gender',
          subSectionContainer: Gender,
          validation: GenderSchema,
          hidden: false,
        },
        {
          id: 'date-of-birth-subsection',
          menuTitle: 'Date of Birth',
          subSectionContainer: DateOfBirth,
          validation: DateOfBirthSchema,
          hidden: false,
        },
        {
          id: 'alien-number-subsection',
          menuTitle: 'Your A-Number',
          subSectionContainer: Anumber,
          validation: AnumberSchema,
          hidden: false,
        },
        {
          id: 'country-of-birth-subsection',
          menuTitle: 'Country of Birth',
          subSectionContainer: CountryOfBirth,
          validation: CountryOfBirthSchema,
          hidden: false,
        },
        {
          id: 'country-of-citizenship-subsection',
          menuTitle: 'Country of Citizenship',
          subSectionContainer: CountryOfCitizenship,
          validation: CountryOfCitizenshipSchema,
          hidden: false,
        },
        {
          id: 'social-security-number-subsection',
          menuTitle: 'Social Security Number',
          subSectionContainer: SocialSecurityNumber,
          validation: SocialSecurityNumberSchema,
          hidden: false,
        },
        {
          id: 'class-of-admission-subsection',
          menuTitle: 'Class of Admission',
          subSectionContainer: ClassOfAdmission,
          validation: ClassOfAdmissionSchema,
          hidden: false,
        },
        {
          id: 'arrival-departure-number-subsection',
          menuTitle: 'I-94 Arrival/Departure Number',
          subSectionContainer: I94ArrivalDepartureNumber,
          validation: I94EArrivalDepartureNumberSchema,
          hidden: false,
        },
        {
          id: 'authorized-stay-expiration-date-subsection',
          menuTitle: 'I-94 Expiration Date',
          subSectionContainer: I94ExpirationDate,
          validation: I94ExpirationDateSchema,
          hidden: false,
        },
        {
          id: 'e-medical-id-subsection',
          menuTitle: 'eMedical Parolee ID',
          subSectionContainer: EMedicalID,
          validation: EMedicalIDSchema,
          hidden: false,
        },
        {
          id: 'applying-for-someone-else-subsection',
          menuTitle: 'Applying for Someone Else',
          subSectionContainer: ApplyingForSomeoneElse,
          validation: ApplyingForSomeoneElseSchema,
          hidden: false,
        },
        {
          id: 'their-name-subsection',
          menuTitle: 'Their Full Name',
          subSectionContainer: TheirName,
          validation: theirNameSchema,
          hidden: (values: I131FormValues) => values.applicantInfo.applyingForSomeoneElse !== 'Yes',
        },
        {
          id: 'their-other-names-subsection',
          menuTitle: 'Their Other Names',
          subSectionContainer: TheirOtherNames,
          validation: theirOtherNamesSchema,
          hidden: (values: I131FormValues) => values.applicantInfo.applyingForSomeoneElse !== 'Yes',
        },
        {
          id: 'their-mailing-address-subsection',
          menuTitle: 'Their Mailing Address',
          subSectionContainer: TheirMailingAddress,
          validation: TheirMailingAddressSchema,
          hidden: (values: I131FormValues) => values.applicantInfo.applyingForSomeoneElse !== 'Yes',
        },
        {
          id: 'their-physical-address-subsection',
          menuTitle: 'Their Physical Address',
          subSectionContainer: TheirPhysicalAddress,
          validation: TheirPhysicalAddressSchema,
          hidden: (values: I131FormValues) => values.applicantInfo.applyingForSomeoneElse !== 'Yes',
        },
        {
          id: 'their-date-of-birth-subsection',
          menuTitle: 'Their Date of Birth',
          subSectionContainer: TheirDateOfBirth,
          validation: TheirDateOfBirthSchema,
          hidden: (values: I131FormValues) => values.applicantInfo.applyingForSomeoneElse !== 'Yes',
        },
        {
          id: 'their-alien-number-subsection',
          menuTitle: 'Their A-Number',
          subSectionContainer: TheirAnumber,
          validation: TheirAnumberSchema,
          hidden: (values: I131FormValues) => values.applicantInfo.applyingForSomeoneElse !== 'Yes',
        },
        {
          id: 'their-country-of-birth-subsection',
          menuTitle: 'Their Country of Birth',
          subSectionContainer: TheirCountryOfBirth,
          validation: TheirCountryOfBirthSchema,
          hidden: (values: I131FormValues) => values.applicantInfo.applyingForSomeoneElse !== 'Yes',
        },
        {
          id: 'their-country-of-citizenship-subsection',
          menuTitle: 'Their Country of Citizenship',
          subSectionContainer: TheirCountryOfCitizenship,
          validation: TheirCountryOfCitizenshipSchema,
          hidden: (values: I131FormValues) => values.applicantInfo.applyingForSomeoneElse !== 'Yes',
        },
        {
          id: 'their-class-of-admission-subsection',
          menuTitle: 'Their Class of Admission',
          subSectionContainer: TheirClassOfAdmission,
          validation: TheirClassOfAdmissionSchema,
          hidden: (values: I131FormValues) => values.applicantInfo.applyingForSomeoneElse !== 'Yes',
        },
        {
          id: 'their-arrival-departure-number-subsection',
          menuTitle: 'Their I-94 Arrival/Departure Number',
          subSectionContainer: TheirI94ArrivalDepartureNumber,
          validation: TheirI94EArrivalDepartureNumberSchema,
          hidden: (values: I131FormValues) => values.applicantInfo.applyingForSomeoneElse !== 'Yes',
        },
      ],
    },
    {
      id: 'recipient-biographic-information-section',
      menuTitle: 'Recipient Biographic Information',
      description: '',
      cleanup: (): void => {
        // Intentionally empty
      },
      subSections: [
        {
          id: 'ethnicity-subsection',
          menuTitle: 'Ethnicity',
          subSectionContainer: Ethnicity,
          validation: EthnicitySchema,
          hidden: false,
        },
        {
          id: 'race-subsection',
          menuTitle: 'Race',
          subSectionContainer: Race,
          validation: RaceSchema,
          hidden: false,
        },
        {
          id: 'height-weight-subsection',
          menuTitle: 'Height and Weight',
          subSectionContainer: HeightWeight,
          validation: HeightWeightSchema,
          hidden: false,
        },
        {
          id: 'eye-color-subsection',
          menuTitle: 'Eye Color',
          subSectionContainer: EyeColor,
          validation: EyeColorSchema,
          hidden: false,
        },
        {
          id: 'hair-color-subsection',
          menuTitle: 'Hair Color',
          subSectionContainer: HairColor,
          validation: HairColorSchema,
          hidden: false,
        },
      ],
    },
    {
      id: 'processing-information-section',
      menuTitle: 'Processing Information',
      description: '',
      cleanup: (): void => {
        // Intentionally empty
      },
      subSections: [
        {
          id: 'previous-proceedings-subsection',
          menuTitle: 'Previous Proceedings',
          subSectionContainer: PreviousProceedings,
          validation: previousProceedingsSchema,
          hidden: false,
        },
        {
          id: 'previous-reentry-subsection',
          menuTitle: 'Previous Re-entry',
          subSectionContainer: PreviousReentry,
          validation: previousReentrySchema,
          hidden: false,
        },
        {
          id: 'replacement-document-subsection',
          menuTitle: 'Request Replacement Document',
          subSectionContainer: RequestReplacement,
          validation: requestReplacementSchema,
          hidden: (values: I131FormValues) => values.applicationType === '8' || values.applicationType === '9',
        },
        {
          id: 'send-documents-subsection',
          menuTitle: 'Where to Send Documents',
          subSectionContainer: DocumentSendTo,
          validation: documentSendToSchema,
          hidden: (values: I131FormValues) =>
            values.applicationType === '8' || values.applicationType === '9' || values.applicationType === '5',
        },
      ],
    },
    {
      id: 'reentry-permit-information-section',
      menuTitle: 'Re-entry Permit Information',
      description: '',
      hidden: (values: I131FormValues) => !['1'].includes(values.applicationType),
      cleanup: (): void => {
        // Intentionally empty
      },
      subSections: [
        {
          id: 'reentry-permit-information-subsection',
          menuTitle: 'Re-entry Permit Information',
          subSectionContainer: ReentryPermitInformation,
          validation: ReentryPermitInformationSchema,
          hidden: false,
        },
      ],
    },
    {
      id: 'refugee-travel-document-section',
      menuTitle: 'Refugee Travel Document',
      description: '',
      hidden: (values: I131FormValues) => !['2', '3'].includes(values.applicationType),
      cleanup: (): void => {
        // Intentionally empty
      },
      subSections: [
        {
          id: 'refugee-country-subsection',
          menuTitle: 'Refugee Country',
          subSectionContainer: CountryOriginSelect,
          validation: countryOriginSelectSchema,
          hidden: false,
        },
        {
          id: 'plan-to-travel-back-subsection',
          menuTitle: 'Plan to Travel Back to Country',
          subSectionContainer: PlanToTravelBack,
          validation: planToTravelBackSchema,
          hidden: false,
        },
        {
          id: 'returned-to-country-subsection',
          menuTitle: 'Returned to Country',
          subSectionContainer: ReturnToCountryQuestion,
          validation: returnToCountrySchema,
          hidden: false,
        },
        {
          id: 'applied-for-passport-subsection',
          menuTitle: 'Applied for Origin Country Passport',
          subSectionContainer: AppliedForPassport,
          validation: appliedForPassportSchema,
          hidden: false,
        },
        {
          id: 'recieved-benefits-subsection',
          menuTitle: 'Recieved Benefits from Origin Country',
          subSectionContainer: ReceiveBenefitsQuestion,
          validation: receiveBenefitsSchema,
          hidden: false,
        },
        {
          id: 'reacquired-nationality-subsection',
          menuTitle: 'Reacquired Nationality from Origin Country',
          subSectionContainer: ReacquiredNationality,
          validation: reacquiredNationalitySchema,
          hidden: false,
        },
        {
          id: 'new-nationality-subsection',
          menuTitle: 'Acquired New Nationality',
          subSectionContainer: AcquiredNewNationality,
          validation: acquiredNewNationalitySchema,
          hidden: false,
        },
        {
          id: 'granted-asylum-in-other-country-subsection',
          menuTitle: 'Granted Asylum in Another Country',
          subSectionContainer: GrantedAsyleeForOtherCountry,
          validation: grantedAsyleeForOtherCountrySchema,
          hidden: false,
        },
      ],
    },
    {
      id: 'advanced-parole-document-section',
      menuTitle: 'Advanced Parole Document',
      description: '',
      hidden: (values: I131FormValues) => !['5'].includes(values.applicationType),
      cleanup: (): void => {
        // Intentionally empty
      },
      subSections: [
        {
          id: 'date-of-intended-departure-subsection',
          menuTitle: 'Date of Intended Departure',
          subSectionContainer: DateOfIntendedDeparture,
          validation: DateOfIntendedDepartureSchema,
          hidden: false,
        },
        {
          id: 'purpose-of-trip-subsection',
          menuTitle: 'Purpose Of Trip',
          subSectionContainer: PurposeOfTrip,
          validation: PurposeOfTripSchema,
          hidden: false,
        },
        {
          id: 'countries-intended-to-visit-subsection',
          menuTitle: 'Countries Intedned to Visit',
          subSectionContainer: PurposeOfTrip,
          validation: PurposeOfTripSchema,
          hidden: false,
        },
        {
          id: 'amount-of-trips-subsection',
          menuTitle: 'Amount of Trips',
          subSectionContainer: AdvancedParoleTrips,
          validation: AdvancedParoleTripsSchema,
          hidden: false,
        },
      ],
    },
    {
      id: 'initial-pip-reparole-section',
      menuTitle: 'Initial Parole, Parole In-Place, Re-Parole Document',
      description: '',
      hidden: (values: I131FormValues) => !['6', '7', '8', '9', '10', '11'].includes(values.applicationType),
      cleanup: (): void => {
        // Intentionally empty
      },
      subSections: [
        {
          id: 'parole-qualification-subsection',
          menuTitle: 'Parole Qualifications',
          subSectionContainer: QualifyForParole, // Needs to be updated
          validation: QualifyForParoleSchema,
          hidden: false,
        },
        {
          id: 'length-of-stay-subsection',
          menuTitle: 'Expected Length of Stay',
          subSectionContainer: LengthOfStayInUS,
          validation: LengthOfStayInUSSchema,
          hidden: false,
        },
        {
          id: 'intended-recipient-subsection',
          menuTitle: 'Intended Recipient',
          subSectionContainer: IntendedRecipient,
          validation: IntendedRecipientSchema,
          hidden: false,
        },
      ],
    },
    {
      id: 'ead-request-section',
      menuTitle: 'Employment Authorization',
      description: '',
      hidden: (values: I131FormValues) => !['10', '11'].includes(values.applicationType),
      cleanup: (): void => {
        // Intentionally empty
      },
      subSections: [
        {
          id: 'ead-request-subsection',
          menuTitle: 'Employment Authorization',
          subSectionContainer: EmploymentAuthorization,
          validation: EmploymentAuthorizationSchema,
          hidden: false,
        },
      ],
    },
    {
      id: 'applicant-information-section',
      menuTitle: 'Applicant Information',
      description: '',
      cleanup: (): void => {
        // Intentionally empty
      },
      subSections: [
        {
          id: 'applicant-contact-information-subsection',
          menuTitle: "Applicant's Contact Information",
          subSectionContainer: ApplicantContactInformation,
          validation: ApplicantContactInformationSchema,
          hidden: false,
        },
        {
          id: 'applicant-certification-signature-subsection',
          menuTitle: "Applicant's Certification and Signature",
          subSectionContainer: ApplicantCertification,
          validation: ApplicantCertificationSchema,
          hidden: false,
        },
      ],
    },
    {
      id: 'interpreter-information-section',
      menuTitle: 'Interpreter Information',
      description: '',
      cleanup: (): void => {
        // Intentionally empty
      },
      subSections: [
        {
          id: 'has-interpreter-subsection',
          menuTitle: 'Used Interpreter',
          subSectionContainer: HasInterpreter,
          validation: HasInterpreterSchema,
          hidden: false,
        },
        {
          id: 'interpreter-name-subsection',
          menuTitle: "Interpreter's Full Name",
          subSectionContainer: InterpreterName,
          validation: InterpreterNameSchema,
          hidden: (values: I131FormValues) => values.interpreterContactAndCertification.hasInterpreter !== 'Yes',
        },
        {
          id: 'interpreter-contact-information-subsection',
          menuTitle: "Interpreter's Contact Information",
          subSectionContainer: InterpreterContactInformation,
          validation: InterpreterContactInformationSchema,
          hidden: (values: I131FormValues) => values.interpreterContactAndCertification.hasInterpreter !== 'Yes',
        },
        {
          id: 'interpreter-certification-signature-subsection',
          menuTitle: "Interpreter's Certification and Signature",
          subSectionContainer: InterpreterCertification,
          validation: InterpreterCertificationSchema,
          hidden: (values: I131FormValues) => values.interpreterContactAndCertification.hasInterpreter !== 'Yes',
        },
      ],
    },
    {
      id: 'preparer-information-section',
      menuTitle: 'Preparer Information',
      description: '',
      cleanup: (): void => {
        // Intentionally empty
      },
      subSections: [
        {
          id: 'has-preparer-subsection',
          menuTitle: 'Used Preparer',
          subSectionContainer: HasPreparer,
          validation: HasPreparerSchema,
          hidden: false,
        },
        {
          id: 'preparer-name-subsection',
          menuTitle: "Preparer's Full Name",
          subSectionContainer: PreparerName,
          validation: PreparerNameSchema,
          hidden: (values: I131FormValues) => values.preparerContactAndCertification.hasPreparer !== 'Yes',
        },
        {
          id: 'preparer-contact-information-subsection',
          menuTitle: "Preparer's Contact Information",
          subSectionContainer: PreparerContactInformation,
          validation: PreparerContactInformationSchema,
          hidden: (values: I131FormValues) => values.preparerContactAndCertification.hasPreparer !== 'Yes',
        },
        {
          id: 'preparer-certification-signature-subsection',
          menuTitle: "Preparer's Certification and Signature",
          subSectionContainer: PreparerCertification,
          validation: PreparerCertificationSchema,
          hidden: (values: I131FormValues) => values.preparerContactAndCertification.hasPreparer !== 'Yes',
        },
      ],
    },
    {
      id: 'review',
      menuTitle: 'Review & Submit',
      description: 'Review your application before submission',
      cleanup: (): void => {
        // Intentionally empty
      },
      subSections: [
        {
          id: 'review-and-submit-subsection',
          menuTitle: 'Review Application',
          subSectionContainer: ReviewAndSubmit,
          hidden: false,
        },
      ],
    },
  ],
};
