import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import * as Yup from 'yup';

type RaceSchemaType = Partial<I131FormValues> & {
  biographicInfo: {
    race: string[];
  };
};

const RaceSchema = createPartialSchema<RaceSchemaType>({
  biographicInfo: Yup.object({
    race: Yup.array().of(Yup.string()).default([]),
  }),
});

export default RaceSchema;
