import React, { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Link,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
  Container,
  Divider,
  Collapse,
  Theme,
  Button,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import CaseIcon from '@mui/icons-material/Work';
import LockIcon from '@mui/icons-material/Lock';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import usFlag from '@src/assets/USFlag-Icon-2x.png';
import uscisInsignia from '@src/assets/USCIS_Insignia_wm_fc.png';

import { useKeycloakAuth } from '@src/hooks';

export const arrowIcon = (howYouKnowOpen: boolean): ReactElement => {
  return howYouKnowOpen ? (
    <ArrowDropUpIcon sx={{ fontSize: '1.2rem' }} />
  ) : (
    <ArrowDropDownIcon sx={{ fontSize: '1.2rem' }} />
  );
};

export type MobilePropsType = {
  flexDirection: 'column' | 'row';
  descPr: number;
  descMb: number;
  securePl: number;
  insigniaWidth: number;
  insigniaHeight: number;
};

export const mobileProps = (isMobile: boolean): MobilePropsType => {
  return isMobile
    ? {
        flexDirection: 'column',
        descPr: 0,
        descMb: 2,
        securePl: 0,
        insigniaWidth: 150,
        insigniaHeight: 44,
      }
    : {
        flexDirection: 'row',
        descPr: 2,
        descMb: 0,
        securePl: 2,
        insigniaWidth: 208,
        insigniaHeight: 61,
      };
};

const Header: React.FC = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [howYouKnowOpen, setHowYouKnowOpen] = useState<boolean>(false);
  const theme: Theme = useTheme();
  const navigate = useNavigate();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down('md'));
  const styleProps = mobileProps(isMobile);
  const darkBlue = '#00476B';
  const { isAuthenticated, userData, login: goToLogin, logout: handleLogout } = useKeycloakAuth();

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleMenuClick = (callback: () => void): (() => void) => {
    return () => {
      handleClose();
      callback();
    };
  };

  const toggleHowYouKnow = (): void => {
    setHowYouKnowOpen(!howYouKnowOpen);
  };

  const handleNavigateHome = (): void => {
    navigate('/');
  };

  const signLink = isAuthenticated ? (
    <Link
      onClick={(): void => handleLogout()}
      href="#"
      sx={{
        color: darkBlue,
        px: 2,
        borderLeft: `1px solid ${darkBlue}`,
        textDecoration: 'none',
        '&:hover': { textDecoration: 'underline' },
      }}
    >
      Sign Out
    </Link>
  ) : (
    <Link
      onClick={(): void => goToLogin()}
      href="#"
      sx={{
        color: darkBlue,
        px: 2,
        borderLeft: `1px solid ${darkBlue}`,
        textDecoration: 'none',
        '&:hover': { textDecoration: 'underline' },
      }}
    >
      Sign In
    </Link>
  );

  const myAccInfo = isAuthenticated ? (
    <>
      <AccountCircleIcon sx={{ mr: 1 }} />
      <Typography variant="body2">Welcome {userData?.username || 'User'}!</Typography>
    </>
  ) : (
    <></>
  );

  return (
    <AppBar
      position="static"
      sx={{ bgcolor: 'common.white', color: darkBlue }}
    >
      <Box sx={{ bgcolor: '#F0F0F0', color: 'black' }}>
        <Container maxWidth={false}>
          <Toolbar
            disableGutters
            sx={{ minHeight: '32px !important', justifyContent: 'flex-start' }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mr: 2,
                flexWrap: 'wrap',
              }}
            >
              <img
                src={usFlag}
                alt="U.S. flag"
                width={16}
                height={9}
                style={{ marginRight: '8px' }}
              />
              <Typography sx={{ fontSize: '0.75rem' }}>An official website of the United States government</Typography>
            </Box>
            <Box
              sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
              onClick={toggleHowYouKnow}
            >
              <Typography sx={{ fontSize: '0.75rem', mr: 0.5 }}>Here&apos;s how you know</Typography>
              {arrowIcon(howYouKnowOpen)}
            </Box>
          </Toolbar>
        </Container>
      </Box>

      <Collapse in={howYouKnowOpen}>
        <Box sx={{ bgcolor: '#F0F0F0', color: 'black' }}>
          <Container maxWidth="md">
            <Box
              sx={{
                display: 'flex',
                flexDirection: styleProps.flexDirection,
                justifyContent: 'center',
                py: 2,
              }}
            >
              <Box sx={{ flex: 1, pr: styleProps.descPr, mb: styleProps.descMb }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                  <CaseIcon sx={{ mr: 1, mt: 0.5 }} />
                  <Box>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                    >
                      Official websites use .gov
                    </Typography>
                    <Typography variant="body2">
                      A .gov website belongs to an official government organization in the United States.
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ flex: 1, pl: styleProps.securePl }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                  <LockIcon sx={{ mr: 1, mt: 0.5 }} />
                  <Box>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                    >
                      Secure .gov websites use HTTPS
                    </Typography>
                    <Typography variant="body2">
                      A lock ( ) or https:// means you&apos;ve safely connected to the .gov website. Share sensitive
                      information only on official, secure websites.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </Collapse>

      <Container maxWidth={false}>
        <Toolbar
          disableGutters
          sx={{
            justifyContent: 'space-between',
            py: 2,
            color: darkBlue,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flex: '1 1 auto',
              gap: 2,
            }}
          >
            <Button
              onClick={handleNavigateHome}
              sx={{
                p: 0,
                minWidth: 'auto',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              aria-label="Return to home page"
            >
              <img
                src={uscisInsignia}
                alt="USCIS Insignia"
                style={{
                  width: isMobile ? 150 : 208,
                  height: isMobile ? 44 : 61,
                }}
              />
            </Button>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ bgcolor: darkBlue }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>{myAccInfo}</Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
            {isMobile ? (
              <IconButton
                edge="end"
                aria-label="menu"
                onClick={(): ((callback: () => void) => () => void) => handleMenuClick}
                sx={{ color: darkBlue }}
              >
                <MenuIcon />
              </IconButton>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center', pl: 4 }}>
                <Link
                  href="#"
                  sx={{
                    color: darkBlue,
                    px: 2,
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'underline' },
                  }}
                >
                  My Account
                </Link>
                <Link
                  href="#"
                  sx={{
                    color: darkBlue,
                    px: 2,
                    borderLeft: `1px solid ${darkBlue}`,
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'underline' },
                  }}
                >
                  Resources
                </Link>
                {signLink}
              </Box>
            )}

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={(): void => handleClose()}>My Account</MenuItem>
              <MenuItem onClick={(): void => handleClose()}>Resources</MenuItem>
              <MenuItem
                onClick={(): void => {
                  handleClose();
                  handleLogout();
                }}
              >
                Sign Out
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
