import { ReactElement } from 'react';
import { COUNTRY } from '@src/constants/countryConstants';
import FlexibleDropdown from '@src/components/FlexibleDropdown';

export const ORIGIN_COUNTRY = {
  ORIGIN_COUNTRY: {
    fieldKey: 'refugeeTravelDocumentInfo.originCountry',
    question: 'What country are you a refugee or asylee from?',
  },
};

const CountryOriginSelect = (): ReactElement => {
  return (
    <FlexibleDropdown
      leftName={ORIGIN_COUNTRY.ORIGIN_COUNTRY.fieldKey}
      leftOptions={COUNTRY}
      leftLabel="Country"
      question={ORIGIN_COUNTRY.ORIGIN_COUNTRY.question}
    />
  );
};

export default CountryOriginSelect;
