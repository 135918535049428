import { createTheme } from '@mui/material/styles';
import { styleConstants } from '../../styles/constants/styleConstants';
import { themeConstants } from '../../styles/constants/themeConstants';

export const theme = createTheme({
  palette: {
    primary: {
      main: themeConstants.colors.blue[500],
      light: themeConstants.colors.blue[300],
      dark: themeConstants.colors.blue[700],
    },
    error: {
      main: themeConstants.colors.error.main,
    },
    success: {
      main: themeConstants.colors.success.main,
      light: themeConstants.colors.success.light,
    },
    text: {
      primary: themeConstants.colors.grey[500], // #333333 - darker text for better contrast
    },
  },

  typography: {
    ...themeConstants.typography,
    body1: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 400,
      color: themeConstants.colors.grey[500], // #333333
    },
  },

  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: themeConstants.colors.common.white,
          color: themeConstants.colors.blue[500],
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: styleConstants.components.button.borderRadius,
          padding: `${styleConstants.components.button.padding.vertical} ${styleConstants.components.button.padding.horizontal}`,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: styleConstants.components.card.borderRadius,
          padding: styleConstants.components.card.padding,
          boxShadow: styleConstants.components.card.shadow,
        },
      },
    },
    // Fix for form title (h2)
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.css-1wpdsnm-MuiTypography-root': {
            color: themeConstants.colors.grey[500], // #333333
          },
        },
      },
    },
    // Fix for form labels and legends
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: themeConstants.colors.grey[500], // #333333
          '&.Mui-required': {
            color: themeConstants.colors.grey[500], // #333333
          },
          '& .MuiFormLabel-asterisk': {
            color: themeConstants.colors.red[500],
          },
        },
      },
    },
    // Fix for radio button labels
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: themeConstants.colors.grey[500], // #333333
          '&.MuiTypography-body1': {
            color: themeConstants.colors.grey[500], // #333333
          },
        },
      },
    },
    // Additional override for radio button selected state
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked + .MuiFormControlLabel-label': {
            color: themeConstants.colors.grey[500], // #333333
          },
        },
      },
    },
  },
});
