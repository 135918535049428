import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import { BooleanSchema, booleanSchema } from '@src/utils/I131.schema';
import * as Yup from 'yup';

type GrantedAsyleeForOtherCountrySchemaType = Partial<I131FormValues> & {
  refugeeTravelDocumentInfo: {
    grantedAsyleeForOtherCountry: BooleanSchema;
    grantedAsyleeForOtherCountryExplanation: string;
  };
};

const grantedAsyleeForOtherCountrySchema = createPartialSchema<GrantedAsyleeForOtherCountrySchemaType>({
  refugeeTravelDocumentInfo: Yup.object({
    grantedAsyleeForOtherCountry: booleanSchema,
    grantedAsyleeForOtherCountryExplanation: Yup.string().default(''),
  }),
});

export default grantedAsyleeForOtherCountrySchema;
