import { ReactElement } from 'react';
import Address from '../../SharedSubSections/Address/Address';

const TheirMailingAddress = (): ReactElement => {
  const formGroup = 'infoAboutThem.currentMailingAddress';
  return (
    <Address
      question={'What is their current mailing address or safe address?'}
      inCareOfNamePath={`${formGroup}.inCareOfName`}
      streetPath={`${formGroup}.street`}
      unitNumberPath={`${formGroup}.unitNumber`}
      cityOrTownPath={`${formGroup}.cityOrTown`}
      statePath={`${formGroup}.state`}
      zipCodePath={`${formGroup}.zipCode`}
      provincePath={`${formGroup}.province`}
      postalCodePath={`${formGroup}.postalCode`}
      countryPath={`${formGroup}.country`}
      daytimePhoneNumberPath={'infoAboutThem.daytimePhone'}
    />
  );
};

export default TheirMailingAddress;
