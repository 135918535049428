import * as React from 'react';
import ContactInformation from '../../SharedSubSections/ContactInformation/ContactInformation';

const InterpreterContactInformation: React.FC = () => {
  return (
    <ContactInformation
      question="Interpreter's Contact Information"
      daytimePhoneLabel="Interpreter's Daytime Telephone Number"
      mobilePhoneLabel="Interpreter's Mobile Telephone Number (if any)"
      emailLabel="Interpreter's Email Address (if any)"
      daytimePhonePath="interpreterContactAndCertification.daytimePhone"
      mobilePhonePath="interpreterContactAndCertification.mobilePhone"
      emailPath="interpreterContactAndCertification.email"
    />
  );
};

export default InterpreterContactInformation;
