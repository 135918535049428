import {
  UseQueryOptions,
  useQuery,
  useQueryClient,
  UseMutationResult,
  UseQueryResult,
  useMutation,
} from '@tanstack/react-query';
import { FORM_QUERY_KEY } from '@src/containers/FormFactory/FormFactory';
import { BaseFormValues } from '@src/types/form.types';
import { StoredFormData } from '@src/types/services.types';

export interface SaveFormDataPayload<T> {
  id: string;
  values: T;
  formType: string;
  lastUpdated: string;
}

export interface FormDataError {
  message: string;
  code?: string;
  details?: unknown;
}

export interface FormDataOptions<T extends BaseFormValues>
  extends Partial<
    Omit<
      UseQueryOptions<StoredFormData<T> | null, Error, StoredFormData<T> | null, [string, string]>,
      'queryKey' | 'queryFn'
    >
  > {
  cacheTime?: number;
  staleTime?: number;
  refetchOnWindowFocus?: boolean;
  enabled?: boolean;
}

export interface SaveFormDataMutationOptions<T extends BaseFormValues> {
  onError?: (error: FormDataError) => void;
  onSuccess?: (data: StoredFormData<T>) => void;
}

class FormStorageAdapter {
  private static getStorageKey(formId: string): string {
    return `form_${formId}`;
  }

  private static safelyParseJSON<T extends BaseFormValues>(data: string | null): StoredFormData<T> | null {
    if (!data) return null;
    try {
      return JSON.parse(data);
    } catch (error) {
      console.error('Failed to parse form data:', error);
      return null;
    }
  }

  private static isValidFormData<T extends BaseFormValues>(data: unknown): data is StoredFormData<T> {
    return (
      !!data &&
      typeof data === 'object' &&
      'id' in data &&
      'values' in data &&
      'formType' in data &&
      'lastUpdated' in data
    );
  }

  private static async safeStorageOperation<T>(operation: () => T): Promise<T> {
    try {
      // Check if localStorage is available
      if (!window.localStorage) {
        throw new Error('LocalStorage is not available');
      }
      return operation();
    } catch (error) {
      const formattedError: FormDataError = {
        message: error instanceof Error ? error.message : 'Unknown storage error',
        code: 'STORAGE_ERROR',
        details: error,
      };
      throw formattedError;
    }
  }

  static async getAllForms<T extends BaseFormValues>(): Promise<StoredFormData<T>[]> {
    return this.safeStorageOperation(() => {
      const forms: StoredFormData<T>[] = [];
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key?.startsWith('form_')) {
          const data = this.safelyParseJSON<T>(localStorage.getItem(key));
          if (data && this.isValidFormData<T>(data)) {
            forms.push(data);
          }
        }
      }
      return forms;
    });
  }

  static async getForm<T extends BaseFormValues>(formId: string): Promise<StoredFormData<T> | null> {
    return this.safeStorageOperation(() => {
      if (!formId) return null;
      const data = this.safelyParseJSON<T>(localStorage.getItem(this.getStorageKey(formId)));
      return data && this.isValidFormData<T>(data) ? data : null;
    });
  }

  static async saveForm<T extends BaseFormValues>(formData: StoredFormData<T>): Promise<StoredFormData<T>> {
    return this.safeStorageOperation(() => {
      if (!this.isValidFormData(formData)) {
        throw new Error('Invalid form data structure');
      }
      localStorage.setItem(this.getStorageKey(formData.id), JSON.stringify(formData));
      return formData;
    });
  }

  static async deleteForm(formId: string): Promise<string> {
    return this.safeStorageOperation(() => {
      localStorage.removeItem(this.getStorageKey(formId));
      return formId;
    });
  }

  static async getFormData<T extends BaseFormValues>(formId: string): Promise<StoredFormData<T> | null> {
    return this.getForm<T>(formId);
  }

  static async saveFormData<T extends BaseFormValues>(data: SaveFormDataPayload<T>): Promise<StoredFormData<T>> {
    const formData: StoredFormData<T> = {
      id: data.id,
      values: data.values,
      formType: data.formType,
      lastUpdated: data.lastUpdated,
    };
    return this.saveForm(formData);
  }
}

export function useAllFormsData<T extends BaseFormValues>(): UseQueryResult<StoredFormData<T>[]> {
  return useQuery({
    queryKey: [FORM_QUERY_KEY],
    queryFn: () => FormStorageAdapter.getAllForms(),
    retry: false, // Don't retry on storage errors
  });
}

export function useDeleteFormData<T extends BaseFormValues>(): UseMutationResult<
  string,
  FormDataError,
  string,
  unknown
> {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formId: string) => FormStorageAdapter.deleteForm(formId),
    onSuccess: (formId) => {
      // Remove the specific form query
      queryClient.removeQueries({
        queryKey: [FORM_QUERY_KEY, formId],
      });

      // Update the all forms query cache
      queryClient.setQueryData<StoredFormData<T>[]>(
        [FORM_QUERY_KEY],
        (old) => old?.filter((form) => form.id !== formId) ?? []
      );

      // Invalidate the all forms query to ensure consistency
      queryClient.invalidateQueries({ queryKey: [FORM_QUERY_KEY] });
    },
  });
}

type FormDataQueryKey = readonly [typeof FORM_QUERY_KEY, string];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function getFormData<T extends BaseFormValues>({ queryKey }: any): Promise<StoredFormData<T> | null> {
  const [, id] = queryKey;
  return FormStorageAdapter.getFormData<T>(id);
}

export function useFormData<T extends BaseFormValues>(
  formId: string,
  options?: Omit<
    UseQueryOptions<StoredFormData<T> | null, Error, StoredFormData<T> | null, FormDataQueryKey>,
    'queryKey' | 'queryFn'
  >
): UseQueryResult<StoredFormData<T> | null, Error> {
  return useQuery<StoredFormData<T> | null, Error, StoredFormData<T> | null, FormDataQueryKey>({
    queryKey: [FORM_QUERY_KEY, formId] as const,
    queryFn: getFormData,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!formId,
    retry: false, // Don't retry on storage errors
    ...options,
  });
}

export function useSaveFormData<T extends BaseFormValues>(
  options?: SaveFormDataMutationOptions<T>
): UseMutationResult<StoredFormData<T>, FormDataError, SaveFormDataPayload<T>, unknown> {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: SaveFormDataPayload<T>) => FormStorageAdapter.saveFormData(data),
    onSuccess: (savedData) => {
      // Update the specific form query
      queryClient.setQueryData([FORM_QUERY_KEY, savedData.id], savedData);

      // Update the all forms query cache
      queryClient.setQueryData<StoredFormData<T>[]>([FORM_QUERY_KEY], (old) => {
        if (!old) return [savedData];
        const index = old.findIndex((form) => form.id === savedData.id);
        if (index === -1) return [...old, savedData];
        return [...old.slice(0, index), savedData, ...old.slice(index + 1)];
      });

      options?.onSuccess?.(savedData);
    },
    onError: (error: FormDataError) => {
      // Invalidate queries on error to ensure consistent state
      queryClient.invalidateQueries({ queryKey: [FORM_QUERY_KEY] });
      options?.onError?.(error);
    },
  });
}
