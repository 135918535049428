import { createPartialSchema, I131FormValues } from '@src/types/form.types';
import { BooleanSchema, booleanSchema } from '@src/utils/I131.schema';
import * as Yup from 'yup';

type ReacquiredNationalitySchemaType = Partial<I131FormValues> & {
  refugeeTravelDocumentInfo: {
    reacquiredNationality: BooleanSchema;
    reacquiredNationalityExplanation: string;
  };
};

const reacquiredNationalitySchema = createPartialSchema<ReacquiredNationalitySchemaType>({
  refugeeTravelDocumentInfo: Yup.object({
    reacquiredNationality: booleanSchema,
    reacquiredNationalityExplanation: Yup.string().default(''),
  }),
});

export default reacquiredNationalitySchema;
